import React, { useEffect } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../redux/user/useUser';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { isSome, none, Option } from 'fp-ts/Option';

import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { TranslateCognitoError } from '../../utils/CognitoErrorDictionary';
import {
  UserEmailValidation,
  UserPasswordValidation,
  EmailError,
  PasswordError,
} from '../../utils/FormValidation';

import { Button } from '../atoms/Button';
import { Input } from '../atoms/Input';

import IconClose from '../../static/image/icon_login_close.svg';
import IconMail from '../../static/image/icon_login_mail.svg';
import IconPassword from '../../static/image/icon_login_password.svg';
import IconGoogle from '../../static/image/icon_google.png';
import IconFacebook from '../../static/image/icon_facebook.png';
import { Z_INDEX_MODAL } from '../../const/ZIndex';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  clickRegisterLink?: () => void;
  redirectURL?: string;
  loginError?: string;
}

export const LoginModal: React.FC<ModalProps> = (props) => {
  const { login, socialLogin } = useUser();
  const navigate = useNavigate();

  const [error, setError] = React.useState('');
  const [errorSocial, setErrorSocial] = React.useState('');
  const [errorEmail, setErrorEmail] = React.useState<Option<EmailError>>(none);
  const [errorPassword, setErrorPassword] = React.useState<Option<PasswordError>>(none);
  const [email, setEmail] = React.useState<string>('');
  const [password, setPassword] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const buttonDisabled = !password || !email || isSome(errorEmail) || isSome(errorPassword);

  const inputEmail = (value: string) => {
    setEmail(value);
    setErrorEmail(UserEmailValidation(value));
  };
  const inputPassword = (value: string) => {
    setPassword(value);
    setErrorPassword(UserPasswordValidation(value));
  };

  const send = useSafeAsyncCallback(async (): Promise<void> => {
    if (buttonDisabled) return;
    setLoading(true);
    await login(email.trim(), password.trim())
      .then((user) => {
        props.onClose();
        // NOTE: パスワードの更新が必要な場合でもエラーにはしていないが、
        // storeに保存されるlmsUserはnullになる実装になっている
        if (user === null) {
          navigate('/changeTemporaryPassword');
          return;
        }

        // リダイレクト先の指定がある場合はそこに遷移する(従業員、法人アカウント登録からの遷移など)
        if (props.redirectURL) {
          navigate(props.redirectURL);
          return;
        }

        navigate('/home');
      })
      .catch((error) => {
        if (error === 'ユーザーとして登録されていません。') {
          setError('ユーザーとして登録されていません');
        } else {
          setError(TranslateCognitoError(error.message));
        }
        setLoading(false);
      });
  });

  useEffect(() => {
    if (props.loginError) {
      setErrorSocial(TranslateCognitoError(props.loginError));
    }
  }, [props.loginError]);

  return props.isOpen ? (
    <Container>
      <Modal>
        <TitleArea>
          <h2>ログイン</h2>
          <Close onClick={props.onClose} />
        </TitleArea>
        <Content>
          <Suggestion>
            アカウントをお持ちでない方は
            {props.clickRegisterLink ? (
              <a onClick={props.clickRegisterLink}>新規登録へ</a>
            ) : (
              <Link to="/#plan">新規登録へ</Link>
            )}
          </Suggestion>
          <InputArea>
            <MailInput
              name="email"
              type="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputEmail(e.target.value)}
              placeholder="メールアドレス"
              error={isSome(errorEmail)}
            />
            {isSome(errorEmail) && <ErrP color="DARK">{errorEmail.value.message}</ErrP>}
            <PasswordInput
              name="password"
              type="password"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => inputPassword(e.target.value)}
              placeholder="パスワード"
              error={isSome(errorPassword)}
            />
            {isSome(errorPassword) && <ErrP color="DARK">{errorPassword.value.message}</ErrP>}
            {error && <ErrP color="DARK">{error}</ErrP>}
          </InputArea>
          <LoginButton onClick={send} disabled={buttonDisabled} loading={loading}>
            ログイン
          </LoginButton>
          <PasswordSuggestion>
            パスワードを忘れた方は
            <Link to="/changePassword">こちら</Link>
          </PasswordSuggestion>
          <SnsArea>
            <a
              onClick={() => socialLogin(CognitoHostedUIIdentityProvider.Google, props.redirectURL)}
            >
              <GoogleSignupButton>Googleでログイン</GoogleSignupButton>
            </a>
            <a
              onClick={() =>
                socialLogin(CognitoHostedUIIdentityProvider.Facebook, props.redirectURL)
              }
            >
              <FacebookSignupButton>Facebookでログイン</FacebookSignupButton>
            </a>
            {errorSocial && <ErrP color="DARK">{errorSocial}</ErrP>}
            <Notice>
              ※メールアドレスで登録されている方はSNSログインを
              <br />
              ご利用いただけません。
            </Notice>
          </SnsArea>
        </Content>
      </Modal>
      <Bg />
    </Container>
  ) : (
    <></>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: ${Z_INDEX_MODAL};

  ${media.lessThan('medium')`
    padding: 0 1rem;
    box-sizing: border-box;
  `}
`;
const Bg = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.15s ease-out 0s;
  opacity: 1;
`;
const Modal = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 100%;
  max-width: 400px;
  max-height: 85%;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  position: relative;
  z-index: 2;
`;
const TitleArea = styled.div`
  padding: 1.125rem 0 0.875rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;

  h2 {
    color: rgba(0, 0, 0, 0.87);
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
  }

  ${media.lessThan('medium')`
    padding: .75rem 0 .875rem;
    h2 {
      font-size: 1rem;
    }
  `}
`;
const Close = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
  background: url(${IconClose}) center / contain no-repeat;
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1.5rem;

  ${media.lessThan('medium')`
    right: .5rem;
  `}
`;
const Content = styled.div`
  padding: 1.5rem 3.375rem 2.625rem;
  overflow-y: auto;

  ${media.lessThan('medium')`
    padding: 1rem 1rem 2rem;
  `}
`;
const Suggestion = styled.p`
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;

  a {
    cursor: pointer;
    color: #eb0000;
    font-size: 1em;
    font-weight: 700;
    text-decoration: underline;
  }
`;

const InputArea = styled.div`
  margin-top: 1.5rem;

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
const StyledInput = styled(Input)<{ error: boolean }>`
  width: 100%;
  height: 2.75rem;
  margin: 0 auto;
  font-size: 1rem;
  background: #fff;
  border: solid 1px rgba(0, 0, 0, 0.36);
  border-radius: 0;
  box-sizing: border-box;
  outline: none;
  appearance: none;
  line-height: 1.125rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    margin: auto;
    background: transparent center / contain no-repeat;
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0.75rem;
  }

  input {
    margin: 0;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    outline: none;

    &:placeholder {
      color: rgba(0, 0, 0, 0.36);
    }
  }

  &:nth-of-type(n + 2) {
    margin-top: 1rem;
  }

  ${(props) => props.error && 'background-color: #feecf4;'}

  ${media.lessThan('medium')`
    &:nth-of-type(n + 2) {
      margin-top: .5rem;
    }
  `}
`;
const MailInput = styled(StyledInput)`
  &:after {
    background-image: url(${IconMail});
  }
`;
const PasswordInput = styled(StyledInput)`
  &:after {
    background-image: url(${IconPassword});
  }
`;
const ErrP = styled.p`
  margin-top: 0.25rem;
  color: #eb0000;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  text-align: center;
`;

const LoginButton = styled(Button)`
  width: 100%;
  margin-top: 2rem;

  ${media.lessThan('medium')`
    margin-top: .875rem;
  `}
`;
const PasswordSuggestion = styled(Suggestion)`
  margin-top: 1rem;

  ${media.lessThan('medium')`
    margin-top: .75rem;
  `}
`;

const SnsArea = styled.p`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    padding-top: 1.5rem;
  `}
`;
const SnsButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  &:before {
    content: '';
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.75rem;
    background: transparent center / contain no-repeat;
  }
`;
const GoogleSignupButton = styled(SnsButton)`
  background: #efefef;
  border-color: #efefef;
  color: rgba(0, 0, 0, 0.87);

  &:before {
    background-image: url(${IconGoogle});
  }
`;
const FacebookSignupButton = styled(SnsButton)`
  background: #1778f2;
  border-color: #1778f2;
  color: #fff;

  &:before {
    background-image: url(${IconFacebook});
  }
`;
const Notice = styled.p`
  margin-top: 1rem;
  font-size: 0.625rem;
  line-height: 0.875rem;
  text-align: center;
`;
