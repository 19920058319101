import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';

import { useUser } from '../../../redux/user/useUser';

import { PageWrapper } from '../../atoms/PageWrapper';
import { PracticeTitle } from '../../molecules/PracticeTitle';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { BasicLayout } from '../../templates/BasicLayout';
import { ForbiddenPage } from '../public/Forbidden';
import { NotFoundPage } from '../public/NotFound';
import { Markdown } from '../../../common/Markdown';

import { getRecentPracticeSubmitStatusWithoutDraft } from '../../../utils/PracticeSubmit';
import { LOWER_META_TITLE } from '../../../const/Service';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { useGetPracticeQuery, PracticeSubmitDetailReviewStatus } from '../../../gen/graphql';
import { Button } from '../../atoms/Button';
import { Loader } from '../../molecules/Loader';

export const PracticeExplanationContainer = (): JSX.Element => {
  const pathID = useParams<{ practice_id: string }>().practice_id;
  if (!pathID) return NotFoundPage;

  return <PracticeExplanation practiceID={pathID} />;
};

interface PracticeExplanationProps {
  practiceID: string;
}

const PracticeExplanation = ({ practiceID }: PracticeExplanationProps): JSX.Element => {
  const navigate = useNavigate();

  const { permissionCheck } = useUser();
  const permissionProgramForInstructorAndCoachRead = useMemo(
    () => permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read),
    [permissionCheck],
  );
  const permissionPracticeForInstructorAndCoachRead = useMemo(
    () => permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read),
    [permissionCheck],
  );

  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const { data: practiceData, loading: practiceLoading } = useGetPracticeQuery({
    variables: {
      practiceID,
    },
    onCompleted: (data) => {
      const practice = data.practice;

      setMetaTitle(`${practice?.title} | ${LOWER_META_TITLE}`);
      setMetaDescription(`侍テラコヤの課題ページです。この課題では、${practice?.description}`);
    },
  });

  const practice = useMemo(() => practiceData?.practice, [practiceData?.practice]);

  const submitStatusWithoutDraft = useMemo(
    () => getRecentPracticeSubmitStatusWithoutDraft(practice?.mySubmit?.practiceSubmitDetails),
    [practice?.mySubmit?.practiceSubmitDetails],
  );

  // 課題データ取得できない場合、
  // 合格者かインストラクター以外は403へ
  if (
    (!practice && !practiceLoading) ||
    (practice &&
      !(
        submitStatusWithoutDraft === PracticeSubmitDetailReviewStatus.Passed ||
        permissionPracticeForInstructorAndCoachRead
      ))
  ) {
    return ForbiddenPage;
  }

  const program = practice?.program;

  const handleClickBack = () => navigate(`/practices/${practiceID}/body`);

  const breadcrumbs = [
    {
      label: 'ホーム',
      to: '/home',
    },
    {
      label: '教材',
      to: permissionProgramForInstructorAndCoachRead ? '/programs?type=1' : '/programs',
    },
    {
      label: program?.title ? program.title : '章一覧',
      to: program?.id ? `/programs/${program.id}/chapters` : '',
    },
    {
      label: practice?.title ? practice.title : '現在のページ',
    },
  ];

  return (
    <>
      <Loader display={practiceLoading} />
      <BasicLayout pageTitle="課題" metaTitle={metaTitle} metaDescription={metaDescription}>
        <PageWrapper>
          <StyledBreadcrumb links={breadcrumbs} />
          <Content>
            <StyledPracticeTitle
              title={practice?.title ?? ''}
              description={practice?.description ?? ''}
              difficulty={practice?.difficulty ?? 'NORMAL'}
              isExplanationTitle
            />
            <MarkdownWrapper>
              <Markdown content={practice?.answer ?? ''} type="program" />
            </MarkdownWrapper>
          </Content>
          <ButtonArea>
            <Button onClick={handleClickBack} border>
              課題詳細に戻る
            </Button>
          </ButtonArea>
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const Content = styled.div`
  max-width: 892px;
  margin: auto;

  ${media.lessThan('medium')`
    padding-bottom: 3rem;
  `}
`;
const StyledPracticeTitle = styled(PracticeTitle)`
  ${media.lessThan('medium')`
    margin: -2rem -1rem 0;
  `}
`;
const MarkdownWrapper = styled.div`
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;

  ${media.lessThan('medium')`
    padding: 1.5rem 0;
  `}
`;
const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
