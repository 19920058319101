import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { Loaders } from '../../../common/Loader';
import { useUser } from '../../../redux/user/useUser';
import Logo from '../../../static/image/logo.svg';
import ConsultationImagePc from '../../../static/image/registerd/consultation001_pc.png';
import CurriculumBottomPc from '../../../static/image/registerd/curriculum_bottom_pc.png';
import CurriculumBottomSp from '../../../static/image/registerd/curriculum_bottom_sp.png';
import FvBgPc from '../../../static/image/registerd/fv_bg_pc.png';
import FvBgSp from '../../../static/image/registerd/fv_bg_sp.png';
import FvBottomPc from '../../../static/image/registerd/fv_bottom_pc.png';
import FvBottomSp from '../../../static/image/registerd/fv_bottom_sp.png';
import { CvAccessTrade } from '../../atoms/CvAccessTrade';
import { CvAfib } from '../../atoms/CvAfib';
import { CvFelmat } from '../../atoms/CvFelmat';
import { CvGmo } from '../../atoms/CvGmo';
import { CvLinkA } from '../../atoms/CvLinkA';
import { CvMoshimo } from '../../atoms/CvMoshimo';
import { CourseProgramArticle } from '../../organisms/CourseProgramArticle';
import {
  NewRegisteredQuestionnaireInput,
  useGetCourseProgramsQuery,
  useGetTagsQuery,
  useNewRegisteredQuestionnaireMutation,
  useRechargeRegisteredQuestionnaireMutation,
  RechargeRegisteredQuestionnaireInput,
  TagFragment,
  TagType,
} from '../../../gen/graphql';
import { RegisteredQuestionnaireModal } from '../../organisms/RegisteredQuestionnaireModal';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { SCHEDULED_STUDY_TYPE_OTHERS } from '../../../const';
import { NoHeaderLayout } from '../../templates/NoHeaderLayout';
import { LOWER_META_TITLE } from '../../../const/Service';
import { sessionStorageSupport } from '../../../utils/sessionStorageSupport';
import { REDIRECT_LINK } from '../../../const/RegisteredQuestionnaire';

export type RegisteredType = 'new' | 'recharge';

export interface ScheduledStudyType {
  value: number;
  label: string;
}

export const Registered = (): JSX.Element => {
  const metaTitle = `登録完了 | ${LOWER_META_TITLE}`;

  const navigate = useNavigate();
  const { user } = useUser();
  const userId = user.lmsUser?.id ?? 0;

  const { registeredType, questionnaire, isReContract, utmSource } = useStoredState();
  const {
    isOpenQuestionnaireModal,
    loading,
    scheduledStudyTypes,
    closeModal,
    createRegisteredQuestionnaire,
    createRechargeQuestionnaire,
  } = useQuestionnaireModal(registeredType);

  const { data: courseProgramsData, loading: courseProgramsLoading } = useGetCourseProgramsQuery({
    variables: {
      input: {
        registeredCoursePrograms: true,
      },
    },
  });

  // 登録処理からの導線でない場合は /home または /register にリダイレクトさせる
  // useEffectの中で下記のリダイレクト処理を実行すると、CVタグが発火するので早期リターン
  if (!registeredType) {
    const navigatePath = user.loginError ? '/register' : '/home';
    navigate(navigatePath);

    return <React.Fragment />;
  }

  return (
    <NoHeaderLayout metaTitle={metaTitle}>
      <Header>
        <Link to="/">
          <img src={Logo} alt="Samurai" />
        </Link>
      </Header>

      <Main>
        {!loading && (
          <React.Fragment>
            {questionnaire && registeredType === 'new' && (
              <RegisteredQuestionnaireModal
                isOpen={isOpenQuestionnaireModal}
                scheduledStudyTypes={scheduledStudyTypes}
                onNewSubmit={createRegisteredQuestionnaire}
                onClose={closeModal}
              />
            )}
            {questionnaire && registeredType === 'recharge' && isReContract && (
              <RegisteredQuestionnaireModal
                isOpen={isOpenQuestionnaireModal}
                scheduledStudyTypes={scheduledStudyTypes}
                onRechargeSubmit={createRechargeQuestionnaire}
                onClose={closeModal}
              />
            )}
          </React.Fragment>
        )}
        <Fv>
          <Inner>
            <H1>Thank you！</H1>
            <FvText>
              登録おめでとうございます。
              <br />
              {registeredType === 'new'
                ? '“プログラミングで人生を変える” 第一歩を踏み出しましょう。'
                : 'もちろん全教材使い放題、質問し放題です。'}
            </FvText>
            <FvButton
              to="/home"
              state={registeredType === 'new' ? { registered: true } : undefined}
            >
              早速学習をはじめる
            </FvButton>
          </Inner>
        </Fv>
        <CurriculumSection>
          <CurriculumInner>
            <Title>おすすめコース教材</Title>
            <SubTitle>Curriculum</SubTitle>
            <CurriculumContainer>
              {!courseProgramsLoading ? (
                courseProgramsData?.coursePrograms?.map((p) => {
                  return (
                    <CurriculumColumn key={p.id}>
                      <StyledCourseProgramArticle
                        id={p.id}
                        key={p.id}
                        image={p.image ?? ''}
                        title={p.title ?? ''}
                        description={p.description ?? ''}
                        totalChapterCount={p.totalChapterCount}
                        totalRequireTime={p.totalRequireTime}
                        totalCompletedTime={p.totalCompletedTime}
                      />
                    </CurriculumColumn>
                  );
                })
              ) : (
                <Loaders width="312px" height="252px" margin="0 0.75rem 1rem 0" number={10} />
              )}
            </CurriculumContainer>
          </CurriculumInner>
        </CurriculumSection>
        <Consultation>
          <ConsultationInner>
            <Title>より本気で学びたい方へ</Title>
            <SubTitle>Learning consultation</SubTitle>
            <ConsultationFlex>
              <ConsultationText>
                <p>
                  SAMURAI
                  ENGINEERは、2013年の創業以来、専任講師による「マンツーマン」に特化したレッスンを提供しています。
                  <br />
                  <br />
                  挫折の多いと言われるプログラミングの習得においては、ひとりひとりに合ったレッスンで自分のペースで学んでいくことが不可欠であると考えているからです。
                  <br />
                  <br />
                  より本気で学びたい方は、ぜひ一度、無料カウンセリングをお試しください。
                </p>
              </ConsultationText>
              <ConsultationImage>
                <img src={ConsultationImagePc} alt="" />
              </ConsultationImage>
            </ConsultationFlex>
            <ButtonArea>
              <a
                href="https://www.sejuku.net/courses/counseling/?cid=plus_registered"
                target="_blank"
              >
                マンツーマンコースの詳細をみる
              </a>
            </ButtonArea>
          </ConsultationInner>
        </Consultation>
      </Main>
      {/* CVタグ */}
      {utmSource === 'accesstrade' ? <CvAccessTrade userID={userId} /> : ''}
      {utmSource === 'afib' ? <CvAfib userID={userId} /> : ''}
      {utmSource === 'felmat' ? <CvFelmat userID={userId} /> : ''}
      {utmSource === 'gmo' ? <CvGmo userID={userId} /> : ''}
      {utmSource === 'link-a' ? <CvLinkA userID={userId} /> : ''}
      {utmSource === 'moshimo' ? <CvMoshimo userID={userId} /> : ''}
    </NoHeaderLayout>
  );
};

const useStoredState = () => {
  const navigate = useNavigate();
  const { refresh, permissionCheck } = useUser();

  const registeredType = sessionStorageSupport.getItem('REGISTERED_TYPE');
  const questionnaire = sessionStorageSupport.getItem('QUESTIONNAIRE') === 'true';
  const isReContract = sessionStorageSupport.getItem('IS_RE_CONTRACT') === 'true';
  const utmSource = sessionStorageSupport.getItem('UTM_SOURCE');

  useEffect(() => {
    // 受講生またはインストラクター、課金ユーザーは /home にリダイレクトさせる
    if (permissionCheck(FunctionType.RegisterRedirectHome, PermissionType.Read)) {
      navigate('/home');
      return;
    }

    refresh();

    return () => {
      // CVタグ出し分け用の値を削除
      sessionStorageSupport.removeItem('UTM_SOURCE');
      // 新規登録、再課金時に使用したsessionデータを削除
      sessionStorageSupport.removeItem('QUESTIONNAIRE');
      sessionStorageSupport.removeItem('REGISTERED_TYPE');
      sessionStorageSupport.removeItem('IS_RE_CONTRACT');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    registeredType,
    questionnaire,
    isReContract,
    utmSource,
  };
};

const useModalState = (registeredType: string | null) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpenQuestionnaireModal, setIsOpenQuestionnaireModal] = useState(true);

  const closeRegisteredFreemiumQuestionnaireModal = (): void => {
    setIsOpenQuestionnaireModal(false);

    window.location.href = REDIRECT_LINK;
  };

  const closeRechargeQuestionnaireModal = (): void => {
    setIsOpenQuestionnaireModal(false);
    navigate(location.pathname, {
      replace: true,
      state: { registeredType: registeredType },
    });
  };

  const closeModal =
    registeredType === 'new'
      ? closeRegisteredFreemiumQuestionnaireModal
      : closeRechargeQuestionnaireModal;

  return {
    isOpenQuestionnaireModal,
    closeModal,
  };
};

const useScheduleStudyTypes = () => {
  const [scheduledStudyTypes, setScheduledStudyTypes] = useState<ScheduledStudyType[]>([]);

  const createScheduledStudyTypes = (tags: TagFragment[]): ScheduledStudyType[] => {
    const scheduledStudyTypes: ScheduledStudyType[] = tags.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      };
    });
    scheduledStudyTypes.push(SCHEDULED_STUDY_TYPE_OTHERS);
    return scheduledStudyTypes;
  };

  const { loading } = useGetTagsQuery({
    variables: {
      input: {
        type: TagType.RegisteredQuestionnaire,
      },
    },
    onCompleted: (data) => {
      setScheduledStudyTypes(createScheduledStudyTypes(data.getTags.items));
    },
  });

  return {
    loading,
    scheduledStudyTypes,
  };
};

const useQuestionnaireModal = (registeredType: string | null) => {
  const { isOpenQuestionnaireModal, closeModal } = useModalState(registeredType);
  const { loading, scheduledStudyTypes } = useScheduleStudyTypes();

  const [createRegisteredQuestionnaireMutation] = useNewRegisteredQuestionnaireMutation();
  const createRegisteredQuestionnaire = useSafeAsyncCallback(
    useCallback(
      async (questionnaireInput: NewRegisteredQuestionnaireInput): Promise<void> => {
        try {
          await createRegisteredQuestionnaireMutation({ variables: { questionnaireInput } });
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        }
      },
      [createRegisteredQuestionnaireMutation],
    ),
  );

  const [createRechargeQuestionnaireMutation] = useRechargeRegisteredQuestionnaireMutation();
  const createRechargeQuestionnaire = useSafeAsyncCallback(
    useCallback(
      async (questionnaireInput: RechargeRegisteredQuestionnaireInput): Promise<void> => {
        try {
          await createRechargeQuestionnaireMutation({ variables: { questionnaireInput } });
        } catch (e) {
          // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
          return;
        }
      },
      [createRechargeQuestionnaireMutation],
    ),
  );

  return {
    isOpenQuestionnaireModal,
    loading,
    scheduledStudyTypes,
    closeModal,
    createRegisteredQuestionnaire,
    createRechargeQuestionnaire,
  };
};

const Header = styled.header`
  display: flex;
  width: 100%;
  height: 67px;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 24px;
  box-sizing: border-box;

  img {
    display: block;
    width: 140px;
  }

  ${media.lessThan('small')`
    padding-left: 1rem;

    img {
      width: 140px;
    }
  `}
`;

const Main = styled.main`
  box-sizing: border-box;
`;
const Inner = styled.div`
  margin: 0 auto;
  padding: 0 1.875rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;

const Fv = styled.section`
  padding: 6rem 0 5.875rem;
  background: #fee url(${FvBgPc}) center / auto 100% no-repeat;
  text-align: center;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 5.875rem;
    background: url(${FvBottomPc}) center bottom / 100% 100% no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${media.lessThan('large')`
    &:after {
      width: 75rem;
      left: calc(50% - 37.5rem);
    }
  `}

  ${media.lessThan('medium')`
    padding: 3.75rem 0 6.75rem;

    &:after {
      height: 3rem;
    }
  `}

  ${media.lessThan('small')`
    background-image: url(${FvBgSp});

    &:after {
      width: 100%;
      height: 2.5rem;
      background-image: url(${FvBottomSp});
      left: 0;
    }
  `}
`;
const H1 = styled.h1`
  background: linear-gradient(95.07deg, #fd8258 11.86%, #fd3c2f 61.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.25;

  ${media.lessThan('medium')`
    font-size: 1.875rem;
  `}
`;
const FvText = styled.p`
  margin-top: 2.5rem;
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 1.76;

  ${media.lessThan('large')`
    font-size: 1.5rem;
  `}

  ${media.lessThan('medium')`
    margin-top: 2rem;
    font-size: 1rem;
    line-height: 2;
  `}

  ${media.greaterThan('medium')`
    .sp {
      display: none;
    }
  `}
`;
const FvButton = styled(Link)`
  display: block;
  max-width: 300px;
  margin: 3rem auto 0;
  padding: 1.25rem 0;
  background: #e2001b;
  box-shadow: 0 0.25rem 2rem rgba(18, 18, 18, 0.1);
  border-radius: 0.25rem;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.25rem;

  ${media.lessThan('medium')`
    margin-top: 2.5rem;
    padding: .875rem 0;
    font-size: 1rem;
  `}
`;

const Title = styled.h2`
  font-size: 2rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
  `}
`;
const SubTitle = styled.p`
  color: rgba(0, 0, 0, 0.36);
  margin: 0.5rem auto 3rem;
  font-family: 'Lato', sans-serif;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  ${media.lessThan('medium')`
    margin: 0.5rem auto 2rem;
    font-size: 0.8125rem;
  `}
`;

const CurriculumSection = styled.section`
  padding: 4.25rem 0 9.375rem;
  background: #fff7f7;
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 4.625rem;
    background: url(${CurriculumBottomPc}) center bottom / 100% 100% no-repeat;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  ${media.lessThan('large')`
    &:after {
      width: 75rem;
      left: -webkit-calc(50% - 37.5rem);
      left: calc(50% - 37.5rem);
    }
  `}

  ${media.lessThan('medium')`
    padding: 4.25rem 0 4.625rem;

    &:after {
      height: 2rem;
    }
  `}

  ${media.lessThan('small')`
    &:after {
      width: 100%;
      height: 1.25rem;
      background-image: url(${CurriculumBottomSp});
      left: 0;
    }
  `}
`;
const CurriculumInner = styled(Inner)`
  max-width: 1116px;
`;
const CurriculumContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem 1.5rem;

  ${media.lessThan('medium')`
    gap: 1.5rem;
  `}
`;
const CurriculumColumn = styled.div`
  width: calc(50% - 0.75rem);

  > div {
    width: 100%;
    margin: 0;
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
  `}
`;

const Consultation = styled.section`
  margin: 3.375rem auto 5rem;

  ${media.lessThan('medium')`
    margin: 3.625rem auto 3rem;
  `}
`;
const ConsultationInner = styled(Inner)`
  max-width: 1110px;
`;
const ConsultationFlex = styled.div`
  display: flex;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;
const ConsultationText = styled.div`
  flex: 1;

  p {
    max-width: 570px;
    font-size: 1.25rem;
    line-height: 1.6;

    span {
      color: #eb0000;
      font-size: 1em;
    }
  }

  ${media.lessThan('medium')`
    p {
      max-width: none;
      font-size: 1rem;
      line-height: 1.8;
    }
  `}
`;
const ConsultationImage = styled.div`
  width: 42%;
  max-width: 420px;
  margin-left: 2.5rem;

  img {
    width: 100%;
  }

  ${media.lessThan('medium')`
    width: 100%;
    max-width: none;
    margin: 1.5rem auto 0;
  `}
`;
const ButtonArea = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.75rem;

  a {
    display: block;
    width: -webkit-calc(50% - 1.5rem);
    width: calc(50% - 1.5rem);
    max-width: 428px;
    padding: 1.25rem 0;
    background: #fff;
    border: 1px solid #e2001b;
    border-radius: 0.25rem;
    color: #e2001b;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.25rem;
    text-align: center;
  }

  ${media.lessThan('medium')`
    flex-direction: column;
    margin-top: 2.5rem;

    a {
      width: 100%;
      margin: 0 auto;
      padding: 0.75rem 0;
    }
  `}

  ${media.lessThan('small')`
    a {
      font-size: 1rem;
    }
  `}
`;

const StyledCourseProgramArticle = styled(CourseProgramArticle)`
  width: 100%;

  article {
    height: 100%;
  }
`;
