import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import 'react-datepicker/dist/react-datepicker.css';
import { flatten, isSome, Option } from 'fp-ts/Option';

import { Button } from '../../atoms/Button';
import { Spacer } from '../../atoms/Spacer';
import { InputWithRef, InputWithRefHandler } from '../../atoms/InputWithRef';
import { TextArea, TextAreaHandler } from '../../atoms/TextArea';
import { CircularIcon } from '../../atoms/CircularIcon';
import { Link, useNavigate } from 'react-router-dom';
import { H3 } from '../../atoms/Typography';
import { LabeledLayout } from '../../molecules/LabeledLayout';
import { useUser } from '../../../redux/user/useUser';
import { Tag } from '../../../types/Tag';
import UserTagComponent, {
  UserTagAction,
  UserTagActionType,
  UserTagState,
} from '../../organisms/UserTag';
import {
  MultipleInputWithSearch,
  Option as MultiOption,
} from '../../molecules/MultipleInputWithSearch';
import DefaultImage from '../../../static/image/icon_default.svg';
import TrashIcon from '../../../static/image/icon_trash.svg';
import { useToastsContext } from '../../../context/ToastsProvider';
import TagManager from 'react-gtm-module';
import {
  AccountNickNameError,
  AccountObjectiveError,
  AccountProfileError,
  AccountTagsError,
  AccountUserTagsError,
  NameError,
  UserNameValidation,
  validateAccountNickName,
  validateAccountObjective,
  validateAccountProfile,
  validateAccountTags,
  validateAccountUserTags,
} from '../../../utils/FormValidation';

import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';
import {
  ErrorCode,
  CurrentUserFragment as User,
  TagFragment,
  useGetTagsLazyQuery,
  UserInput,
  UserRole,
  useUpdateUserMutation,
} from '../../../gen/graphql';
import { findFirst } from 'fp-ts/lib/Array';
import { useSafeAsyncCallback } from '../../../common/customHooks/SafeAsyncCallback';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import { AccountTabLayout } from '../../templates/AccountTabLayout';
import { ApolloError } from '@apollo/client';
import { getGraphQLErrorCodes } from '../../../utils/graphqlError';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const hasValueOrUnregistered = (value: string | number | null | undefined) => {
  return value ? value : <Unregistered>未登録</Unregistered>;
};

const Information: React.FC<{
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  lmsUser: User | null;
}> = ({ permissionCheck, lmsUser }) => {
  return (
    <>
      <LabeledLayout labelWidth="200px" label={<Label>プロフィール画像</Label>}>
        <FlexRow>
          <CircularIcon src={lmsUser?.image ? lmsUser?.image : DefaultImage} size={60} />
        </FlexRow>
      </LabeledLayout>
      {permissionCheck(FunctionType.AccountProfileLabelForStudent, PermissionType.Read) && (
        <LabeledLayout labelWidth={'200px'} label={<Label>受講生ID</Label>}>
          {hasValueOrUnregistered(lmsUser?.student?.id)}
        </LabeledLayout>
      )}
      {permissionCheck(FunctionType.AccountProfileLabelForInstructor, PermissionType.Read) && (
        <LabeledLayout labelWidth={'200px'} label={<Label>インストID</Label>}>
          {hasValueOrUnregistered(lmsUser?.instructor?.id)}
        </LabeledLayout>
      )}
      {permissionCheck(FunctionType.AccountProfileLabelForCoach, PermissionType.Read) && (
        <LabeledLayout labelWidth={'200px'} label={<Label>コーチID</Label>}>
          {hasValueOrUnregistered(lmsUser?.instructor?.id)}
        </LabeledLayout>
      )}
      <LabeledLayout labelWidth={'200px'} label={<Label>メールアドレス</Label>}>
        {hasValueOrUnregistered(lmsUser?.personalInfo?.email)}
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>名前</Label>}>
        {hasValueOrUnregistered(lmsUser?.personalInfo?.name)}
      </LabeledLayout>
      <LabeledLayout
        labelWidth={'200px'}
        label={
          <Label>
            ニックネーム
            <br />
            (公開用)
          </Label>
        }
      >
        {hasValueOrUnregistered(lmsUser?.nickName)}
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>プログラミング経験</Label>}>
        {lmsUser?.userTags ? (
          lmsUser?.userTags.map((userTag) => (
            <p key={userTag.id}>
              {userTag.tag?.name} ({userTag.term}年)
            </p>
          ))
        ) : (
          <Unregistered>未登録</Unregistered>
        )}
      </LabeledLayout>
      <LabeledLayout
        labelWidth={'200px'}
        label={
          <Label>
            学習予定の言語 /<br />
            フレームワーク
          </Label>
        }
      >
        {lmsUser?.tags ? (
          lmsUser?.tags.map((tag: Tag) => <TagField key={tag.id}>{tag.name}</TagField>)
        ) : (
          <Unregistered>未登録</Unregistered>
        )}
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>自己紹介</Label>}>
        {hasValueOrUnregistered(lmsUser?.profile)}
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>学習目標</Label>}>
        {hasValueOrUnregistered(lmsUser?.objective)}
      </LabeledLayout>
    </>
  );
};

interface EditInformationProps {
  role: UserRole;
  permissionCheck: (functionType: string, permissionType: string) => boolean;
  lmsUser: User | null;
  whenSubmit: () => void;
}

const EditInformation: React.FC<EditInformationProps> = ({
  role,
  permissionCheck,
  lmsUser,
  whenSubmit,
}) => {
  const [tags, setTags] = React.useState<TagFragment[]>([]);
  const [getTags] = useGetTagsLazyQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => setTags(data.getTags.items),
  });

  const fetchTags = React.useCallback(
    async (query: string): Promise<void> => {
      getTags({
        variables: {
          input: {
            name: query,
            limit: 10,
            page: 1,
          },
        },
      });
    },
    [getTags],
  );

  const tagInputRef = React.useRef<HTMLInputElement>(null);
  const { showToast } = useToastsContext();

  const [newImage, setNewImage] = React.useState<File | null>(null);
  const nameRef = React.useRef<InputWithRefHandler>(null);
  const nickNameRef = React.useRef<InputWithRefHandler>(null);
  const profileRef = React.useRef<TextAreaHandler>(null);
  const objectiveRef = React.useRef<TextAreaHandler>(null);
  const currentUserTagNumber = React.useRef(0);
  const [userTags, dispatchUserTags] = React.useReducer(
    (state: UserTagState[], action: UserTagAction): UserTagState[] => {
      switch (action.type) {
        case UserTagActionType.PUSH:
          currentUserTagNumber.current++;
          return [
            ...state,
            {
              id: currentUserTagNumber.current,
              ref: React.createRef(),
              defaultValue: action.defaultValue,
            },
          ];
        case UserTagActionType.SPLICE:
          return state.filter((userTag): boolean => userTag.id !== action.id);
        case UserTagActionType.CLEAR:
          return [];
        default:
          return state;
      }
    },
    [],
  );
  const [currentTags, setCurrentTags] = React.useState<Tag[]>([]);

  const [iconError, setIconError] = React.useState('');
  const [nameError, setNameError] = React.useState('');
  const [nickNameError, setNickNameError] = React.useState('');
  const [userTagsError, setUserTagsError] = React.useState('');
  const [tagsError, setTagsError] = React.useState('');
  const [profileError, setProfileError] = React.useState('');
  const [objectiveError, setObjectiveError] = React.useState('');

  const currentImageURL = useMemo((): string => {
    if (newImage) {
      return URL.createObjectURL(newImage);
    }
    return lmsUser?.image || DefaultImage;
  }, [newImage, lmsUser?.image]);

  const [updateUser] = useUpdateUserMutation({
    onCompleted: () => whenSubmit(),
  });

  const removeTag = (option: MultiOption): void =>
    setCurrentTags(currentTags.filter((t): boolean => t.id !== option.value));

  const choiceTag = (option: MultiOption): void => {
    if (!option.value) return;
    const id = parseInt(option.value as string);
    if (currentTags.filter((t): boolean => t.id === id).length === 0)
      setCurrentTags([...currentTags, { id: id, name: option.name }]);
    if (tagInputRef.current) tagInputRef.current.value = '';
    setTags([]);
  };

  const setDefaultValues = React.useCallback(() => {
    nameRef.current?.setValue(lmsUser?.personalInfo?.name ?? '');
    nickNameRef.current?.setValue(lmsUser?.nickName ?? '');

    dispatchUserTags({ type: UserTagActionType.CLEAR });
    lmsUser?.userTags?.forEach((userTag): void => {
      if (!userTag.tag) {
        // Tagが空（Invalid）な場合は処理をしない
        return;
      }

      dispatchUserTags({ type: UserTagActionType.SPLICE, id: userTag.id });
      dispatchUserTags({
        type: UserTagActionType.PUSH,
        defaultValue: {
          id: userTag.id,
          term: userTag.term ?? 0,
          tag: {
            id: userTag.tag.id,
            name: userTag.tag.name,
          },
        },
      });
    });

    setCurrentTags(
      lmsUser?.tags ? lmsUser?.tags.map((t: Tag) => ({ name: t.name, id: t.id })) : [],
    );

    profileRef.current?.setValue(lmsUser?.profile ?? '');
    objectiveRef.current?.setValue(lmsUser?.objective ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameRef, nickNameRef, profileRef, objectiveRef]);

  const validate = (input: UserInput): Option<Error> => {
    return flatten(
      findFirst((x: Option<Error>) => isSome(x))([
        UserNameValidation(input.name),
        validateAccountNickName(input.nickName),
        validateAccountUserTags(input.userTags),
        validateAccountTags(input.tags.map((t) => Number(t))),
        validateAccountProfile(input.profile ?? undefined),
        validateAccountObjective(input.objective ?? undefined),
      ]),
    );
  };

  const submit = useSafeAsyncCallback(
    useCallback(async () => {
      const uts = userTags.map((ut) => ({
        tagID: parseInt(ut.ref.current.getUserTag().tagId),
        term: parseInt(ut.ref.current.getUserTag().term),
      }));
      const ts = currentTags.map((t) => t.id);

      if (uts.some((ut) => ut.tagID === undefined || ut.tagID === null)) {
        showToast(1, '言語・技術が選択されていません');
        return;
      }

      try {
        const updateUserRequest = {
          image: newImage,
          name: nameRef.current?.getValue() ?? '',
          nickName: nickNameRef.current?.getValue() ?? '',
          objective: objectiveRef.current ? objectiveRef.current.getValue() : undefined,
          profile: profileRef.current ? profileRef.current.getValue() : undefined,
          tags: ts ?? [],
          userTags: uts ?? [],
        };

        const validationErr = validate(updateUserRequest);
        if (isSome(validationErr)) throw validationErr.value;

        await updateUser({
          variables: {
            input: updateUserRequest,
          },
        });
      } catch (e) {
        if (
          e instanceof ApolloError &&
          getGraphQLErrorCodes(e).includes(ErrorCode.CommonInvalidImage)
        ) {
          setIconError(
            'プロフィール画像にはGIF、PNG、JPEG、WebP、SVG形式のファイルを指定して下さい',
          );
        }
        if (e instanceof NameError) setNameError(e.message);
        if (e instanceof AccountNickNameError) setNickNameError(e.message);
        if (e instanceof AccountUserTagsError) setUserTagsError(e.message);
        if (e instanceof AccountTagsError) setTagsError(e.message);
        if (e instanceof AccountProfileError) setProfileError(e.message);
        if (e instanceof AccountObjectiveError) setObjectiveError(e.message);
        showToast(1, '入力内容にエラーがあります');
      }
    }, [userTags, currentTags, showToast, newImage, updateUser]),
  );

  React.useEffect(() => {
    setDefaultValues();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lmsUser, role]);

  return (
    <>
      <LabeledLayout labelWidth="200px" label={<Label>プロフィール画像</Label>}>
        <FlexRow>
          <CircularIcon src={currentImageURL} size={60} />
          <input
            type="file"
            id="upload-img"
            accept="image/*"
            onChange={(e) => setNewImage(e.target.files?.[0] ?? null)}
            style={{ display: 'none' }}
          />
          <StyledButton htmlFor="upload-img">変更する</StyledButton>
        </FlexRow>
        <ErrorText>{iconError}</ErrorText>
      </LabeledLayout>
      {permissionCheck(FunctionType.AccountProfileLabelForStudent, PermissionType.Read) && (
        <LabeledLayout labelWidth={'200px'} label={<Label>受講生ID</Label>}>
          {hasValueOrUnregistered(lmsUser?.student?.id)}
        </LabeledLayout>
      )}
      {permissionCheck(FunctionType.AccountProfileLabelForInstructor, PermissionType.Read) && (
        <LabeledLayout labelWidth={'200px'} label={<Label>インストID</Label>}>
          {hasValueOrUnregistered(lmsUser?.instructor?.id)}
        </LabeledLayout>
      )}
      {permissionCheck(FunctionType.AccountProfileLabelForCoach, PermissionType.Read) && (
        <LabeledLayout labelWidth={'200px'} label={<Label>コーチID</Label>}>
          {hasValueOrUnregistered(lmsUser?.instructor?.id)}
        </LabeledLayout>
      )}
      <LabeledLayout labelWidth={'200px'} label={<Label>メールアドレス</Label>}>
        {hasValueOrUnregistered(lmsUser?.personalInfo?.email)}
      </LabeledLayout>
      <LabeledLayout
        labelWidth={'200px'}
        label={
          <React.Fragment>
            <Label>名前</Label>
            <Required>必須</Required>
          </React.Fragment>
        }
      >
        <InputWithRef ref={nameRef} name="name" type="text" />
        <ErrorText>{nameError}</ErrorText>
      </LabeledLayout>

      <LabeledLayout
        labelWidth={'200px'}
        label={
          <React.Fragment>
            <Label>
              ニックネーム
              <br className="pc" />
              (公開用)
            </Label>
            <Required>必須</Required>
          </React.Fragment>
        }
      >
        <InputWithRef ref={nickNameRef} name="nickName" type="text" />
        <ErrorText>{nickNameError}</ErrorText>
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>プログラミング経験</Label>}>
        <UserTagsContainer>
          {userTags.map((userTag): JSX.Element => {
            return (
              <UserTagRow key={userTag.id}>
                <UserTagComponent
                  ref={userTag.ref}
                  defaultValues={userTag.defaultValue}
                  minTerm={0}
                />
                <UserTagTrash
                  src={TrashIcon}
                  alt="ゴミ箱"
                  onClick={(): void =>
                    dispatchUserTags({ type: UserTagActionType.SPLICE, id: userTag.id })
                  }
                />
              </UserTagRow>
            );
          })}
          <UserTagAdd onClick={(): void => dispatchUserTags({ type: UserTagActionType.PUSH })}>
            + 内容を追加する
          </UserTagAdd>
        </UserTagsContainer>
        <ErrorText>{userTagsError}</ErrorText>
      </LabeledLayout>
      <LabeledLayout
        labelWidth={'200px'}
        label={
          <Label>
            学習予定の言語 /<br />
            フレームワーク
          </Label>
        }
      >
        <MultipleInputWithSearch
          values={currentTags.map((t) => ({ name: t.name, value: t.id }))}
          options={tags.map((t) => ({ name: t.name, value: t.id }))}
          onRemove={removeTag}
          onSelect={choiceTag}
          fetchOptions={() => {
            fetchTags(tagInputRef.current ? tagInputRef.current.value : '');
          }}
          ref={tagInputRef}
        />
        <ErrorText>{tagsError}</ErrorText>
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>自己紹介</Label>}>
        <TextArea ref={profileRef} name="profile" />
        <ErrorText>{profileError}</ErrorText>
      </LabeledLayout>
      <LabeledLayout labelWidth={'200px'} label={<Label>学習目標</Label>}>
        <TextArea ref={objectiveRef} name="objective" />
        <ErrorText>{objectiveError}</ErrorText>
      </LabeledLayout>
      <Submit onClick={submit}>更新する</Submit>
    </>
  );
};

export const AccountProfile: React.FC = () => {
  const metaTitle = `プロフィール | ${LOWER_META_TITLE}`;

  const { user, fetchCurrentUserWithPermissions, permissionCheck, isSocialLoginUser } = useUser();
  const location = useLocation();
  const query = queryString.parse(location.search);

  const navigate = useNavigate();

  const [editing, setEditing] = React.useState(query.mode === 'editing');

  const handleWithdrawClick = (): void => {
    TagManager.dataLayer({
      dataLayer: {
        userId: user.lmsUser?.id,
        user: user.lmsUser,
        event: 'gtm-churn-withdraw-click',
        eventData: {},
      },
      dataLayerName: 'LMSDataLayer',
    });

    navigate('/account/cancel');
  };

  const whenEdit = React.useCallback(() => {
    setEditing(false);
    fetchCurrentUserWithPermissions();
  }, [fetchCurrentUserWithPermissions]);

  const isTeamAdmin = user.role === UserRole.TeamAdmin;
  const cannotDeleteAccount =
    isTeamAdmin ||
    user.role === UserRole.SubscriptionLight ||
    user.role === UserRole.SubscriptionBasic ||
    user.role === UserRole.SubscriptionPremium;

  const cancelSubscriptionLink = isTeamAdmin ? '/team/subscription' : '/account/subscription';
  return (
    <AccountTabLayout
      activeTab="profile"
      permissionCheck={permissionCheck}
      isSocialLoginUser={isSocialLoginUser()}
      hasIntercom
      metaTitle={metaTitle}
    >
      <Container>
        <Content>
          <Edit onClick={(): void => setEditing(!editing)} border>
            {editing ? '保存せず戻る' : '編集'}
          </Edit>
          <Header>
            <H3 color="Dark">ユーザー情報</H3>
          </Header>
          {editing ? (
            <EditInformation
              role={user.role}
              permissionCheck={permissionCheck}
              lmsUser={user.lmsUser}
              whenSubmit={whenEdit}
            />
          ) : (
            <Information permissionCheck={permissionCheck} lmsUser={user.lmsUser} />
          )}
        </Content>
        <Spacer height={'2rem'} />
        {permissionCheck(FunctionType.AccountWithDraw, PermissionType.Delete) && (
          <WithDrawButtonContainer>
            <DeleteButton
              onClick={handleWithdrawClick}
              gray={cannotDeleteAccount}
              disabled={cannotDeleteAccount}
            >
              {SERVICE_NAME}のアカウントを完全に削除する
            </DeleteButton>

            {cannotDeleteAccount && (
              <Info>
                ※アカウント削除を希望の場合は、まず
                <Link to={cancelSubscriptionLink}>こちら</Link>
                からプランを解約いただく必要があります。
              </Info>
            )}
          </WithDrawButtonContainer>
        )}
      </Container>
    </AccountTabLayout>
  );
};

export default AccountProfile;

const Container = styled.div`
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${media.lessThan('medium')`
    padding: 1.5rem 1rem;
  `}
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  max-width: 820px;
  padding: 2rem;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0.1);

  & > * > *:nth-child(2) {
    min-height: 42px;
    width: 100%;
  }

  ${media.lessThan('small')`
    padding: 3rem 1rem 1rem;
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled.span`
  font-weight: bold;

  ${media.lessThan('medium')`
    .pc {
      display: none;
    }
  `}
`;

const Edit = styled(Button)`
  position: absolute;
  top: 2rem;
  right: 2rem;

  ${media.lessThan('small')`
    top: 1rem;
    right: 1rem;
  `}
`;

const TagField = styled.span`
  padding: 0.5rem;
  color: #ffffff;
  background-color: #e2001b;
  border-radius: 3px;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
`;

const Unregistered = styled.span`
  color: rgba(0, 0, 0, 0.36);
`;

const Required = styled.span`
  font-size: 10px;
  color: #ffffff;
  padding: 2px 8px;
  margin-top: -2px;
  margin-left: 0.5rem;
  background-color: #fd6258;
`;

const StyledButton = styled.label<{ editing?: boolean }>`
  padding: 0.5rem 1rem;
  color: #ffffff;
  background-color: #e2001b;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 0.5rem;
  cursor: pointer;
  ${(params): string => (params.editing !== false ? '' : 'display: none;')}
`;

const ErrorText = styled.p`
  color: #fd6258;
  font-weight: bold;
  margin-top: 0.5rem;
  margin-left: 1rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.5rem;
  }
`;

const UserTagsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const UserTagRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 0.6rem;
`;

const UserTagAdd = styled.p`
  display: block;
  color: #e2001b;
  font-weight: 600;
  margin-top: 0.6rem;
  cursor: pointer;
`;

const UserTagTrash = styled.img`
  margin-left: 1rem;
  width: 24px;
  height: 24px;
  cursor: pointer;

  ${media.greaterThan('medium')`
    margin-top: 1rem;
  `}
`;

const Submit = styled(Button)`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 2rem auto 0;
`;

const WithDrawButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  max-width: 820px;

  ${media.lessThan('small')`
    align-items: center;
  `}
`;

const DeleteButton = styled(Button)`
  font-weight: 400;
`;

const Info = styled.p`
  width: 18.75rem;
  margin-top: 0.6rem;
  font-size: 0.8rem;
  line-height: 1.3;
  color: rgba(0, 0, 0, 0.36);

  a {
    font-size: 0.8rem;
    color: #e2001b;
  }
`;
