import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { isEmpty } from 'fp-ts/Array';

import { TagFragment } from '../../gen/graphql';

import { Button } from '../atoms/Button';
import { SpotLessonReserveStep1CheckBox } from './SpotLessonReserveStep1CheckBox';
import { SpotLessonReserveStep1Table } from './SpotLessonReserveStep1Table';

import InformationIcon from '../../static/image/icon_yellow_information_mark.png';

interface Props {
  setLoading: (isLoading: boolean) => void;
  onNext: (inputTags: TagFragment[], targetDate: Date) => void;
}

export const SpotLessonReserveStep1Group: React.FC<Props> = ({
  setLoading,
  onNext,
}): JSX.Element => {
  const [tagInput, setTagInput] = useState<{
    selectedTags: TagFragment[];
    activeNoChoose: boolean;
  }>({
    selectedTags: [],
    activeNoChoose: false,
  });
  const [targetDate, setTargetDate] = useState<Date | null>(null);

  const handleChangeTags = useCallback(
    (selectedTags: TagFragment[], activeNoChoose: boolean): void => {
      setTagInput({ selectedTags, activeNoChoose });
      // 言語を変えたらカレンダーの選択はクリア
      setTargetDate(null);
    },
    [],
  );

  const disabledCondition = useMemo(
    (): boolean =>
      (isEmpty(tagInput.selectedTags) && !tagInput.activeNoChoose) || targetDate === null,
    [tagInput.selectedTags, tagInput.activeNoChoose, targetDate],
  );

  return (
    <>
      <TableSubtitleContainer>
        <StepSubTitle>
          相談内容<RequiredLabel>必須 ※ 上限2つまで</RequiredLabel>
        </StepSubTitle>
        <Notice>
          <img src={InformationIcon} />
          <NoticeMessage>
            対応している言語やフレームワーク等の詳細は
            <a
              href="https://intercom.help/plus---samurai/ja/articles/5882187-q-%E5%AF%BE%E5%BF%9C%E3%81%97%E3%81%A6%E3%81%84%E3%82%8B%E8%A8%80%E8%AA%9E%E3%82%84%E3%83%95%E3%83%AC%E3%83%BC%E3%83%A0%E3%83%AF%E3%83%BC%E3%82%AF%E7%AD%89%E3%82%92%E6%95%99%E3%81%88%E3%81%A6%E3%81%8F%E3%81%A0%E3%81%95%E3%81%84"
              target="_blank"
            >
              ヘルプセンター
            </a>
            をご確認ください。
          </NoticeMessage>
        </Notice>
      </TableSubtitleContainer>
      <SpotLessonReserveStep1CheckBox setLoading={setLoading} onChange={handleChangeTags} />
      <Note>
        <NoteList>
          レッスンの対応範囲は原則教材内の内容となります。詳細はヘルプセンターをご確認ください。
        </NoteList>
        <NoteList>
          選択された内容が相談内容の詳細と異なる場合は弊社にてキャンセルさせていただく場合があります。
        </NoteList>
        <NoteList>
          案件獲得もしくは、転職を選択の場合、担当インストラクターが専門外で十分な回答を得られない場合があります。ご了承ください。
          <br />
          詳しくはヘルプセンターの
          <NoteAnchor
            href="https://intercom.help/plus---samurai/ja/articles/8339547-%E3%83%AC%E3%83%83%E3%82%B9%E3%83%B3%E3%81%94%E4%BA%88%E7%B4%84%E6%99%82%E3%81%AE%E3%81%8A%E9%A1%98%E3%81%84"
            target="_blank"
          >
            レッスンご予約時のお願い
          </NoteAnchor>
          をご覧ください。
        </NoteList>
      </Note>
      <TableWrapper>
        <TableSubtitleContainer>
          <StepSubTitle>希望日時</StepSubTitle>
          <Notice>
            <img src={InformationIcon} />
            <NoticeMessage>空き時間が少ない場合は、言語選択数を減らしてください。</NoticeMessage>
          </Notice>
        </TableSubtitleContainer>
        <SpotLessonReserveStep1Table
          selectedTags={tagInput.selectedTags}
          activeNoChooseTag={tagInput.activeNoChoose}
          targetDate={targetDate}
          onChange={setTargetDate}
          setLoading={setLoading}
        />
      </TableWrapper>
      <StepButton
        onClick={() => {
          if (targetDate) {
            onNext(tagInput.selectedTags, targetDate);
          }
        }}
        disabled={disabledCondition}
      >
        次の入力へ進む
      </StepButton>
    </>
  );
};

const StepSubTitle = styled.h4`
  font-weight: 700;
  font-size: 1.125rem;
`;

const StepButton = styled(Button)`
  display: block;
  width: 17.5rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 360px;
    margin: 2rem auto 0;
  `}
`;

const Notice = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  max-width: 624px;
  margin: 0 0 0 1.375rem;
  padding: 0.8135rem 1.25rem;
  border: 2px solid #e7bb23;
  box-sizing: border-box;
  color: #e7bb23;

  ${media.lessThan('medium')`
    margin: 1rem 0 0;
    width: 100%;
  `}
`;

const NoticeMessage = styled.p`
  font-size: 0.875rem;
  margin-left: 0.5rem;

  a {
    font-size: 0.875rem;
    color: #eb0000;
  }
  a:hover {
    text-decoration: underline;
  }
`;

const TableSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.875rem;

  ${media.lessThan('medium')`
     flex-direction: column;
     align-items: flex-start;
     margin-bottom: 2.75rem;
  `}
`;

const TableWrapper = styled.div`
  margin-top: 1rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const RequiredLabel = styled.span`
  margin-left: 0.5rem;
  padding: 0.0625rem 0.5rem;
  vertical-align: top;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.5;
  background: #fd6258;
  color: #ffffff;
`;

const Note = styled.ul`
  margin-top: 1rem;
  margin-left: 1rem;
`;

const NoteList = styled.li`
  padding-left: 0.2rem;
  list-style: initial;
  list-style-type: '\\203B';
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1rem;
`;

const NoteAnchor = styled.a`
  font-size: 0.8rem;
  color: #eb0000;

  &:hover {
    text-decoration: underline;
  }
`;
