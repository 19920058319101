import { useEffect, useState, useCallback, useMemo } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import media, { generateMedia } from 'styled-media-query';

import { useUser } from '../../../redux/user/useUser';

import { Button } from '../../atoms/Button';
import { Fab } from '../../atoms/Fab';
import { PageWrapper } from '../../atoms/PageWrapper';
import { PracticeTitle } from '../../molecules/PracticeTitle';
import { ProgramElementBackArchive } from '../../molecules/ProgramElementBackArchive';
import { ProgramElementPagination } from '../../molecules/ProgramElementPagination';
import { Breadcrumb } from '../../organisms/Breadcrumb';
import { ProgramElementsList } from '../../organisms/ProgramElementsList';
import { ProgramElementsNav } from '../../organisms/ProgramElementsNav';
import { QuestionCreateModal } from '../../organisms/QuestionCreateModal';
import { QuestionTabContent } from '../../organisms/QuestionTabContent';
import TabNavigationLayout from '../../templates/TabNavigationLayout';
import { NotFoundPage } from '../public/NotFound';
import { Markdown } from '../../../common/Markdown';

import {
  getRecentPracticeSubmitStatus,
  getRecentPracticeSubmitStatusWithoutDraft,
} from '../../../utils/PracticeSubmit';
import { getChaptersAndPractices } from '../../../utils/Program';
import { scrollToID } from '../../../utils/ui/Anchor';
import { FIRST_PAGE, MIDDLE_LIMIT, MIN_LIMIT } from '../../../const/Page';
import { LOWER_META_TITLE } from '../../../const/Service';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import {
  QuestionSearchInput,
  QuestionSearchType,
  QuestionSortKey,
  useGetPracticeQuery,
  useGetQuestionsQuery,
  useGetPracticeSubmitsQuery,
  PracticeSubmitSearchType,
  PracticeSubmitSortKey,
  InstructorPracticeSubmitSearchType,
  useGetPracticeSubmitsForInstructorQuery,
  PracticeSubmitDetailReviewStatus,
} from '../../../gen/graphql';
import {
  PracticeSubmitSearchInputParams,
  PracticeSubmitTabContent,
} from '../../organisms/PracticeSubmitTabContent';
import { Loader } from '../../molecules/Loader';
import ForbiddenPage from '../public/Forbidden';
import { useCommonModal } from '../../../redux/common_modal/useCommonModal';

export const PracticeDetailContainer = (): JSX.Element => {
  const pathID = useParams<{ practice_id: string }>().practice_id;
  if (!pathID) return NotFoundPage;

  return <PracticeDetail practiceID={pathID} />;
};

interface PracticeDetailProps {
  practiceID: string;
}

const PracticeDetail = ({ practiceID }: PracticeDetailProps): JSX.Element => {
  const { openModal } = useCommonModal();
  const [isQuestionCreateModalOpen, setIsQuestionCreateModalOpen] = useState(false);

  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');

  const navigate = useNavigate();
  const location = useLocation();

  const { user, permissionCheck } = useUser();
  const permissionPracticeForInstructorAndCoachRead = useMemo(
    () => permissionCheck(FunctionType.PracticeForInstructorAndCoach, PermissionType.Read),
    [permissionCheck],
  );
  const permissionProgramForInstructorAndCoachRead = useMemo(
    () => permissionCheck(FunctionType.ProgramForInstructorAndCoach, PermissionType.Read),
    [permissionCheck],
  );
  const permissionQuestionCreate = useMemo(
    () => permissionCheck(FunctionType.Question, PermissionType.Create),
    [permissionCheck],
  );
  const permissionRecommendLightRead = useMemo(
    () => permissionCheck(FunctionType.RecommendLight, PermissionType.Read),
    [permissionCheck],
  );
  const permissionRecommendTeamSubscriptionRead = useMemo(
    () => permissionCheck(FunctionType.RecommendTeamSubscription, PermissionType.Read),
    [permissionCheck],
  );
  const permissionRecommendTeamSubscriptionToAdminRead = useMemo(
    () => permissionCheck(FunctionType.RecommendTeamSubscriptionToAdmin, PermissionType.Read),
    [permissionCheck],
  );

  const { data: practiceData, loading: practiceLoading } = useGetPracticeQuery({
    variables: {
      practiceID,
    },
    onCompleted: (data) => {
      const practice = data.practice;

      setMetaTitle(`${practice?.title} | ${LOWER_META_TITLE}`);
      setMetaDescription(`侍テラコヤの課題ページです。この課題では、${practice?.description}`);
    },
  });

  const practice = useMemo(() => practiceData?.practice, [practiceData?.practice]);

  const myRecentPracticeSubmitStatusWithoutDraft = useMemo(
    () => getRecentPracticeSubmitStatusWithoutDraft(practice?.mySubmit?.practiceSubmitDetails),
    [practice?.mySubmit?.practiceSubmitDetails],
  );
  const myRecentPracticeSubmitStatus = useMemo(
    () => getRecentPracticeSubmitStatus(practice?.mySubmit?.practiceSubmitDetails),
    [practice?.mySubmit?.practiceSubmitDetails],
  );
  const hasMyPracticeSubmit = useMemo(
    () => !!myRecentPracticeSubmitStatusWithoutDraft,
    [myRecentPracticeSubmitStatusWithoutDraft],
  );
  const hasMyPracticeSubmitDraft = useMemo(
    () => myRecentPracticeSubmitStatus === PracticeSubmitDetailReviewStatus.Draft,
    [myRecentPracticeSubmitStatus],
  );

  const program = useMemo(() => practice?.program, [practice?.program]);
  const programElements = useMemo(() => getChaptersAndPractices(program) ?? [], [program]);
  const currentPracticeIndex = useMemo(
    () =>
      programElements.findIndex(
        ({ __typename: typename, id }) => typename === 'Practice' && id === practiceID,
      ),
    [practiceID, programElements],
  );

  const {
    data: questionData,
    loading: questionLoading,
    refetch: refetchQuestions,
  } = useGetQuestionsQuery({
    variables: {
      input: {
        type: QuestionSearchType.All,
        page: FIRST_PAGE,
        limit: MIDDLE_LIMIT,
        practiceID: practiceID,
        sortKey: QuestionSortKey.CreatedAt,
      },
    },
  });
  const questionsTotal = useMemo(
    () => questionData?.getQuestions.total ?? 0,
    [questionData?.getQuestions.total],
  );
  const questionsItems = useMemo(
    () => questionData?.getQuestions.items ?? [],
    [questionData?.getQuestions.items],
  );
  const fetchQuestions = useCallback(
    (params: QuestionSearchInput) => {
      refetchQuestions({ input: params });
    },
    [refetchQuestions],
  );

  const {
    data: practiceSubmitsData,
    loading: practiceSubmitsLoading,
    refetch: refetchPracticeSubmits,
  } = useGetPracticeSubmitsQuery({
    variables: {
      input: {
        type: PracticeSubmitSearchType.All,
        practiceID: practiceID,
        sortKey: PracticeSubmitSortKey.CreatedAt,
        page: FIRST_PAGE,
        limit: MIN_LIMIT,
      },
    },
    skip: permissionPracticeForInstructorAndCoachRead,
    notifyOnNetworkStatusChange: true,
  });
  const {
    data: practiceSubmitsForInstructorData,
    loading: practiceSubmitsForInstructorLoading,
    refetch: refetchPracticeSubmitsForInstructor,
  } = useGetPracticeSubmitsForInstructorQuery({
    variables: {
      input: {
        type: InstructorPracticeSubmitSearchType.All,
        practiceID: practiceID,
        sortKey: PracticeSubmitSortKey.CreatedAt,
        page: FIRST_PAGE,
        limit: MIN_LIMIT,
      },
    },
    skip: !permissionPracticeForInstructorAndCoachRead,
    notifyOnNetworkStatusChange: true,
  });
  const fetchPracticeSubmits = useCallback(
    (params: PracticeSubmitSearchInputParams) => {
      if (permissionPracticeForInstructorAndCoachRead) {
        refetchPracticeSubmitsForInstructor({
          input: {
            type: InstructorPracticeSubmitSearchType.All,
            practiceID: practiceID,
            ...params,
          },
        });
      } else {
        refetchPracticeSubmits({
          input: {
            type: PracticeSubmitSearchType.All,
            practiceID: practiceID,
            ...params,
          },
        });
      }
    },
    [
      permissionPracticeForInstructorAndCoachRead,
      refetchPracticeSubmitsForInstructor,
      refetchPracticeSubmits,
      practiceID,
    ],
  );
  const practiceSubmits = useMemo(
    () =>
      practiceSubmitsData?.getPracticeSubmits.items ??
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.items ??
      [],
    [
      practiceSubmitsData?.getPracticeSubmits.items,
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.items,
    ],
  );
  const practiceSubmitsTotal = useMemo(
    () =>
      practiceSubmitsData?.getPracticeSubmits.total ??
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.total ??
      0,
    [
      practiceSubmitsData?.getPracticeSubmits.total,
      practiceSubmitsForInstructorData?.getPracticeSubmitsForInstructor.total,
    ],
  );
  const isExplanation = useMemo(
    () =>
      myRecentPracticeSubmitStatusWithoutDraft === PracticeSubmitDetailReviewStatus.Passed ||
      permissionPracticeForInstructorAndCoachRead,
    [myRecentPracticeSubmitStatusWithoutDraft, permissionPracticeForInstructorAndCoachRead],
  );

  const goToPracticeExplanationPage = useCallback(
    () => navigate(`/practices/${practiceID}/explanation`),
    [navigate, practiceID],
  );

  const goToPracticeSubmitNewPage = useCallback(() => {
    if (openModal(FunctionType.PracticeSubmit, PermissionType.Create)) return;
    navigate(`/practices/${practiceID}/submits/new`);
  }, [openModal, navigate, practiceID]);

  const goToPracticeSubmitPage = useCallback(() => {
    if (practice?.mySubmit?.id) {
      navigate(`/practices/submits/${practice.mySubmit.id}`);
    }
  }, [navigate, practice?.mySubmit?.id]);

  const goToPracticeSubmitEditPage = useCallback(() => {
    if (openModal(FunctionType.PracticeSubmit, PermissionType.Update)) return;

    const mySubmitID = practice?.mySubmit?.id;
    const practiceSubmitDetails = practice?.mySubmit?.practiceSubmitDetails;
    if (mySubmitID && practiceSubmitDetails && practiceSubmitDetails[0].id) {
      navigate(`/practices/submits/${mySubmitID}/details/${practiceSubmitDetails[0].id}/edit`);
    }
  }, [navigate, openModal, practice?.mySubmit?.id, practice?.mySubmit?.practiceSubmitDetails]);

  const handleNewQuestionClick = useCallback(() => {
    if (openModal(FunctionType.Question, PermissionType.Create)) return;
    setIsQuestionCreateModalOpen(true);
  }, [openModal]);

  const handleQuestionCreateModalLink = useCallback(() => {
    window.open(
      `/question/new?category_id=${program?.category}&program_id=${program?.id}&practice_id=${practiceID}`,
    );
    setIsQuestionCreateModalOpen(false);
  }, [practiceID, program?.category, program?.id]);

  const scrollPage = useCallback((): void => {
    const anchor = location.hash;
    if (anchor) {
      const hash = decodeURI(anchor.replace(/^#/, ''));
      if (hash) {
        scrollToID(hash);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [location.hash]);

  const tabs = useMemo(
    () => [
      {
        label: '本文',
        to: `/practices/${practiceID}/body`,
        active: location.pathname === `/practices/${practiceID}/body`,
      },
      {
        label: '目次',
        to: `/practices/${practiceID}/list`,
        active: location.pathname === `/practices/${practiceID}/list`,
      },
      {
        label: `質問一覧 ${questionsTotal}件`,
        to: `/practices/${practiceID}/questions`,
        active: location.pathname === `/practices/${practiceID}/questions`,
      },
      {
        label: `提出物一覧 ${practiceSubmitsTotal}件`,
        to: `/practices/${practiceID}/submits`,
        active: location.pathname === `/practices/${practiceID}/submits`,
      },
    ],
    [location.pathname, practiceID, practiceSubmitsTotal, questionsTotal],
  );

  const breadcrumbs = useMemo(
    () => [
      {
        label: 'ホーム',
        to: '/home',
      },
      {
        label: '教材',
        to: permissionProgramForInstructorAndCoachRead ? '/programs?type=1' : '/programs',
      },
      {
        label: program?.title ? program.title : '章一覧',
        to: program?.id ? `/programs/${program?.id}/chapters` : '',
      },
      {
        label: practice?.title ? practice.title : '現在のページ',
      },
    ],
    [permissionProgramForInstructorAndCoachRead, practice?.title, program?.id, program?.title],
  );

  const showLoader = useMemo(
    () =>
      practiceLoading ||
      questionLoading ||
      practiceSubmitsLoading ||
      practiceSubmitsForInstructorLoading,
    [practiceLoading, practiceSubmitsForInstructorLoading, practiceSubmitsLoading, questionLoading],
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      scrollPage();
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [scrollPage]);

  // 課題データ取得できない場合は403へ
  if (!practice && !practiceLoading) return ForbiddenPage;

  return (
    <>
      <Loader display={showLoader} />
      <QuestionCreateModal
        isOpen={isQuestionCreateModalOpen}
        onClose={() => setIsQuestionCreateModalOpen(false)}
        handleLink={handleQuestionCreateModalLink}
      />
      <TabNavigationLayout
        pageTitle="課題"
        tabs={tabs}
        unfixed
        metaTitle={metaTitle}
        metaDescription={metaDescription}
      >
        <PageWrapper>
          <Main>
            <StyledBreadcrumb links={breadcrumbs} />
            {location.pathname === `/practices/${practiceID}/body` ? (
              <PracticeWrapper>
                <div className="practice">
                  <Content>
                    <StyledPracticeTitle
                      title={practice?.title ?? ''}
                      description={practice?.description ?? ''}
                      requireTime={practice?.requireTime ?? 0}
                      difficulty={practice?.difficulty ?? 'NORMAL'}
                      submitPassedCount={practice?.submitPassedCount ?? 0}
                      submitReviewStatus={myRecentPracticeSubmitStatusWithoutDraft}
                    />
                    <MarkdownWrapper>
                      <Markdown content={practice?.content ?? ''} type="program" />
                    </MarkdownWrapper>
                  </Content>
                  <PageTransitionButtons>
                    <div>
                      <PageTransitionButton
                        border
                        disabled={!isExplanation}
                        onClick={goToPracticeExplanationPage}
                      >
                        課題の解説を見る
                      </PageTransitionButton>
                      {!isExplanation && (
                        <ButtonNote>※解説は課題に合格すると見ることができます</ButtonNote>
                      )}
                    </div>
                    {!permissionPracticeForInstructorAndCoachRead && (
                      <>
                        {(!hasMyPracticeSubmit || hasMyPracticeSubmitDraft) && (
                          <PageTransitionButton
                            onClick={
                              hasMyPracticeSubmitDraft
                                ? goToPracticeSubmitEditPage
                                : goToPracticeSubmitNewPage
                            }
                          >
                            課題を提出する
                          </PageTransitionButton>
                        )}
                        {hasMyPracticeSubmit && !hasMyPracticeSubmitDraft && (
                          <PageTransitionButton border onClick={goToPracticeSubmitPage}>
                            課題のレビューを見る
                          </PageTransitionButton>
                        )}
                      </>
                    )}
                  </PageTransitionButtons>
                  {program && (
                    <ProgramElementPagination
                      program={program}
                      currentChapterIndex={currentPracticeIndex}
                      disabledNext={false}
                    />
                  )}
                  <ProgramElementBackArchive
                    programID={program?.id}
                    practiceID={practiceID}
                    isLogin={user.isLogged}
                  />
                </div>
                <ProgramElementsNav
                  programElements={programElements}
                  currentID={practiceID}
                  programID={program?.id}
                />
              </PracticeWrapper>
            ) : location.pathname === `/practices/${practiceID}/list` ? (
              <ProgramElementsList
                programElements={programElements}
                currentID={practiceID}
                programID={program?.id}
              />
            ) : location.pathname === `/practices/${practiceID}/submits` ? (
              <>
                {program && (
                  <PracticeSubmitTabContent
                    practiceSubmits={practiceSubmits}
                    total={practiceSubmitsTotal}
                    fetch={fetchPracticeSubmits}
                  />
                )}
              </>
            ) : (
              <>
                {program && (
                  <QuestionTabContent
                    permissionCheck={permissionCheck}
                    questionsTotal={questionsTotal}
                    questionsItems={questionsItems}
                    fetchQuestions={fetchQuestions}
                    program={program}
                    practiceID={practiceID}
                  />
                )}
              </>
            )}
          </Main>
          {(permissionQuestionCreate ||
            permissionRecommendLightRead ||
            permissionRecommendTeamSubscriptionRead ||
            permissionRecommendTeamSubscriptionToAdminRead) && (
            <Fab onClick={handleNewQuestionClick}>+ 質問する</Fab>
          )}
        </PageWrapper>
      </TabNavigationLayout>
    </>
  );
};

const customMedia = generateMedia({
  tablet: '1024px',
});
const Main = styled.div`
  transition: all 0.2s;

  .practice {
    flex: 1;
    min-width: 0;

    .markdown-body {
      scroll-snap-type: y mandatory;

      h2 {
        scroll-snap-align: start;
        scroll-margin-top: 1em;

        &:first-child {
          scroll-snap-align: start;
          scroll-margin-bottom: -1em;
        }
      }
    }
  }

  ${media.lessThan('large')`
    .practice {
      width: calc(100% - 15rem);
    }
  `}

  ${customMedia.lessThan('tablet')`
    .practice {
      width: 100%;
    }
  `}
`;
const StyledBreadcrumb = styled(Breadcrumb)`
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    display: none;
  `}
`;
const PracticeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;

  ${media.lessThan('medium')`
    padding-bottom: 3rem;
  `}
`;
const Content = styled.div`
  position: relative;

  svg {
    z-index: 1;
    position: absolute;
    left: 0;
    top: 0;
  }
`;
const StyledPracticeTitle = styled(PracticeTitle)`
  ${media.lessThan('medium')`
    margin: -2rem -1rem 0;
  `}
`;
const MarkdownWrapper = styled.div`
  margin: 0 auto;
  padding: 2rem;
  overflow: hidden;

  ${media.lessThan('medium')`
    padding: 1.5rem 0;
  `}
`;
const PageTransitionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  margin: 1.25rem 0 2rem;

  > :first-child {
    margin: 0 3rem 0 0;

    ${media.lessThan('medium')`
      margin-right: 0;
      margin-bottom: 1rem;
    `}
  }

  ${media.lessThan('medium')`
    display: block;
    margin-top: 0.75rem;
  `}
`;
const PageTransitionButton = styled(Button)`
  width: 280px;

  ${media.lessThan('medium')`
    width: 100%;
  `}
`;
const ButtonNote = styled.p`
  margin: 0.5rem 0 0;
  font-size: 0.75rem;
  text-align: center;
`;
