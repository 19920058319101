import React, { useCallback, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { isEmpty } from 'fp-ts/Array';
import { format, startOfMonth, addMonths, endOfMonth } from 'date-fns';

import { Button } from '../atoms/Button';
import { Modal } from '../molecules/Modal';

import { ClaimCategory as ClaimCategoryConst } from '../../common/Const';
import { InputWithSearch, Option } from '../molecules/InputWithSearch';
import styled from 'styled-components';
import { filterCourseByClaimCategory, formatCourses } from '../../const/Course';
import {
  useAddClaimDetailForInstructorMutation,
  ClaimDetailInput,
  useGetClaimCategoriesForInstructorQuery,
  useGetHistoricalCoursesForInstructorLazyQuery,
  useGetSpotLessonsForInstructorQuery,
  HistoricalCourseFragment as Course,
  SpotLessonFragment as SpotLesson,
  SpotLessonPhase,
} from '../../gen/graphql';
import { useToastsContext } from '../../context/ToastsProvider';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { insertCommaDelimiter, removeCommaDelimiter } from '../../utils/common';
import { getApiErrorMessage } from '../../utils/graphqlError';

const zeroPadding = (num: number) => `0${num}`.substr(-2);
const formatDate = (date: Date) =>
  `${date.getFullYear()}/${zeroPadding(date.getMonth() + 1)}/${zeroPadding(
    date.getDate(),
  )} ${zeroPadding(date.getHours())}:${zeroPadding(date.getMinutes())}`;

interface AddModalProps {
  isOpen: boolean;
  toggle: (nextState: boolean) => void;
  loading?: boolean;
  refetch: (date: Date, claimCategoryId?: number, studentId?: number) => void;
  month: Date;
  utcMonth: Date;
  claimCategoryId?: number;
  studentId?: number;
  width?: number;
}

interface InputWithSearchHandler {
  getValue: () => Option | null;
  setValue: (option: Option) => void;
  clear: () => void;
}

/**
 * AddModal
 */
export const AddModal: React.FC<AddModalProps> = ({
  isOpen,
  toggle,
  refetch,
  month,
  utcMonth,
  claimCategoryId,
  studentId,
  width,
  ...props
}) => {
  const categoryRef = useRef(document.createElement('select'));
  const studentRef = useRef<InputWithSearchHandler>(null);
  const lessonRef = useRef(document.createElement('select'));
  const spotLessonRef = useRef(document.createElement('select'));
  const amountRef = useRef(document.createElement('input'));
  const unitPriceRef = useRef(document.createElement('input'));
  const charaAmountRef = useRef(document.createElement('input'));
  const charaUnitPriceRef = useRef(document.createElement('input'));
  const additionalPaymentRef = useRef(document.createElement('input'));
  const remarkRef = useRef(document.createElement('textarea'));
  const perItemUnitPriceRef = useRef(document.createElement('input'));
  const perItemAmountRef = useRef(document.createElement('input'));
  const { showToast } = useToastsContext();

  const [categoryErr, setCategoryErr] = useState('');
  const [studentErr, setStudentErr] = useState('');
  const [lessonErr, setLessonErr] = useState('');
  const [spotLessonErr, setSpotLessonErr] = useState('');

  const [amountErr, setAmountErr] = useState('');
  const [unitPriceErr, setUnitPriceErr] = useState('');
  const [charaAmountErr, setCharaAmountErr] = useState('');
  const [charaUnitPriceErr, setCharaUnitPriceErr] = useState('');
  const [additionalPaymentErr, setAdditionalPaymentErr] = useState('');
  const [remarkErr, setRemarkErr] = useState('');
  const [perItemUnitPriceErr, setPerItemUnitPriceErr] = useState('');
  const [perItemAmountErr, setPerItemAmountErr] = useState('');

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentCourse, setCurrentCourse] = useState<Course>();
  const [hasTime, setHasTime] = useState(false);
  const [hasStudent, setHasStudent] = useState(false);
  const [hasLesson, setHasLesson] = useState(false);
  const [hasSpotLesson, setHasSpotLesson] = useState(false);
  const [hasAdditionalPayment, setHasAdditionalPayment] = useState(false);
  const [hasHourlyPayment, setHasHourlyPayment] = useState(false);
  const [hasTimelinePayment, setHasTimelinePayment] = useState(false);
  const [hasPerItemPayment, setHasPerItemPayment] = useState(false);
  const [hasPerItem, setHasPerItem] = useState(false);
  const [remarkPlaceholder, setRemarkPlaceholder] = useState('');
  const [remarkIsRequired, setRemarkIsRequired] = useState(false);
  const [dateReadOnly, setDateReadOnly] = useState(false);
  const [timeReadOnly, setTimeReadOnly] = useState(false);
  const [currentCategory, setCurrentCategory] = useState('');

  const clear = useCallback(() => {
    categoryRef.current.value = 'カテゴリーを選択してください ▼';
    studentRef?.current?.clear();
    lessonRef.current.value = '専属レッスンを選択してください ▼';
    spotLessonRef.current.value = '単発レッスンを選択してください ▼';
    amountRef.current.value = '';
    unitPriceRef.current.value = '';
    additionalPaymentRef.current.value = '';
    remarkRef.current.value = '';
    setRemarkPlaceholder('');
    setRemarkIsRequired(false);
    setHasTime(false);
    setHasStudent(false);
    setHasLesson(false);
    setHasAdditionalPayment(false);
    setHasHourlyPayment(true);
    setHasPerItemPayment(false);
    setHasPerItem(false);
    setHasTimelinePayment(false);
    setRemarkPlaceholder('');
    setRemarkIsRequired(false);
    setCurrentCourse(undefined);
    setCategoryErr('');
    setStudentErr('');
    setLessonErr('');
    setSpotLessonErr('');
    setAmountErr('');
    setUnitPriceErr('');
    setAdditionalPaymentErr('');
    setRemarkErr('');
    setCurrentCategory('');
    setDateReadOnly(false);
    setTimeReadOnly(false);
  }, []);

  const handleChangeUnitPrice = () =>
    (unitPriceRef.current.value = insertCommaDelimiter(
      unitPriceRef.current.value.replace(/[^0-9]+/g, ''),
    ));
  const handleChangeCharaUnitPrice = () =>
    (charaUnitPriceRef.current.value = insertCommaDelimiter(
      charaUnitPriceRef.current.value.replace(/[^0-9]+/g, ''),
    ));
  const handleChangeAdditionalPayment = () =>
    (additionalPaymentRef.current.value = insertCommaDelimiter(
      additionalPaymentRef.current.value.replace(/[^0-9]+/g, ''),
    ));
  const handleChangePerItemUnitPrice = () =>
    (unitPriceRef.current.value = insertCommaDelimiter(
      unitPriceRef.current.value.replace(/[^0-9]+/g, ''),
    ));

  /**
   * カテゴリー変更ハンドラ
   */
  const changeCategory: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    setCurrentCategory(e.target.value);

    studentRef?.current?.clear();
    lessonRef.current.value = '専属レッスンを選択してください ▼';
    spotLessonRef.current.value = '単発レッスンを選択してください ▼';
    amountRef.current.value = '';
    unitPriceRef.current.value = '';
    additionalPaymentRef.current.value = '';
    remarkRef.current.value = '';

    setCategoryErr('');
    setStudentErr('');
    setLessonErr('');
    setSpotLessonErr('');
    setAmountErr('');
    setUnitPriceErr('');
    setAdditionalPaymentErr('');
    setRemarkErr('');

    setDateReadOnly(false);
    setTimeReadOnly(false);

    switch (parseInt(e.target.value)) {
      case ClaimCategoryConst.LESSON:
        setHasTime(true);
        setHasStudent(true);
        setHasLesson(true);
        setHasSpotLesson(false);
        setHasAdditionalPayment(true);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(false);
        setRemarkPlaceholder('');
        break;
      case ClaimCategoryConst.LESSON_PREPARATION:
        setHasTime(true);
        setHasStudent(true);
        setHasLesson(true);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(false);
        setRemarkPlaceholder('');
        break;
      case ClaimCategoryConst.QUESTION:
        setHasTime(true);
        setHasStudent(true);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('対応した質問の概要を記載ください。');
        break;
      case ClaimCategoryConst.CURRICULUM:
        setHasTime(true);
        setHasStudent(true);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(false);
        setRemarkPlaceholder('');
        break;
      case ClaimCategoryConst.LESSON_CANCEL:
        setHasTime(true);
        setHasStudent(true);
        setHasLesson(true);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('専属レッスンキャンセルの詳細を記載ください。');
        break;
      case ClaimCategoryConst.CATCHUP:
        setHasTime(true);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('キャッチアップした内容について記載ください。');
        break;
      case ClaimCategoryConst.QUESTION_GENERAL:
        setHasTime(true);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('対応したURLの一覧を記載ください。');
        break;
      case ClaimCategoryConst.TEACHING_MATERIAL:
        setHasTime(false);
        setHasStudent(true);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('購入した教材を記載ください。');
        break;
      case ClaimCategoryConst.REFERRAL_INCENTIVE:
        setHasTime(false);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('紹介いただいた方の名前を記載ください。');
        break;
      case ClaimCategoryConst.POSTED_TIMELINE:
        setHasTime(false);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(false);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(true);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('タイムラインURLを貼り付けてください。');
        break;
      case ClaimCategoryConst.SPOT_LESSON:
        setHasTime(true);
        setDateReadOnly(true);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(true);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(false);
        setRemarkPlaceholder('');
        amountRef.current.value = '60';
        break;
      case ClaimCategoryConst.TERAKOYA_PRACTICE_REVIEWER:
        setHasTime(true);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('レビューした課題提出URLを記載ください。');
        break;
      case ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW:
        setHasTime(false);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(false);
        setHasPerItemPayment(true);
        setHasPerItem(true);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('対応した内容について記載ください。');
        break;
      case ClaimCategoryConst.CONSULTING:
      case ClaimCategoryConst.MENTORING:
      case ClaimCategoryConst.REQUEST:
      case ClaimCategoryConst.TEACHING_MATERIAL_DEVELOPING:
      case ClaimCategoryConst.RESKILLING_COLLEGE:
      case ClaimCategoryConst.SHIGOTORU:
      case ClaimCategoryConst.REQUEST_BY_CORPORATE_DIVISION:
      case ClaimCategoryConst.DOJO_LESSON:
      case ClaimCategoryConst.DOJO_CONTENT_DEVELOPING:
      case ClaimCategoryConst.OnSiteLesson:
        setHasTime(true);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('対応した内容について記載ください。');
        break;
      default:
        setHasTime(false);
        setHasStudent(false);
        setHasLesson(false);
        setHasSpotLesson(false);
        setHasAdditionalPayment(false);
        setHasHourlyPayment(true);
        setHasPerItemPayment(false);
        setHasPerItem(false);
        setHasTimelinePayment(false);
        setRemarkIsRequired(true);
        setRemarkPlaceholder('対応した内容について記載ください。');
        break;
    }
  };

  /**
   * スポットレッスン取得
   */
  const { data: spotLessonData } = useGetSpotLessonsForInstructorQuery({
    variables: {
      input: {
        from: startOfMonth(selectedDate).toISOString(),
        to: startOfMonth(addMonths(selectedDate, 1)).toISOString(),
        // 完了と不参加のみ指定。講師都合のキャンセルなども含めた方が良くなったら別途対応。
        // 不参加の場合は時間を20分で固定
        phases: [SpotLessonPhase.Complete, SpotLessonPhase.NonAttendance],
      },
    },
  });
  const spotLessons = spotLessonData?.spotLessonsForInstructor?.items ?? [];

  /**
   * コース変更ハンドラ
   */
  const changeCourse = (id: number) => {
    const course = courses.find((course) => course.id === id);
    setCurrentCourse(course ? course : undefined);
    setDateReadOnly(false);
  };

  /**
   * レッスン変更ハンドラ
   */
  const changeLesson: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const lesson = currentCourse?.lessons?.find(
      (lesson) => lesson.id === parseInt(e.target.value, 10),
    );

    if (!lesson || !lesson.startAt || !lesson.endAt) return;

    if (parseInt(currentCategory) === ClaimCategoryConst.LESSON) {
      setSelectedDate(lesson ? new Date(lesson.startAt) : selectedDate);
      setDateReadOnly(true);
      amountRef.current.value = `${Math.floor(
        (new Date(lesson.endAt).getTime() - new Date(lesson.startAt).getTime()) / 1000 / 60,
      )}`;
      setTimeReadOnly(true);
    } else if (parseInt(currentCategory) === ClaimCategoryConst.LESSON_CANCEL) {
      setSelectedDate(lesson ? new Date(lesson.startAt) : selectedDate);
      setDateReadOnly(true);
      amountRef.current.value = '60';
      setTimeReadOnly(true);
    }
  };

  /**
   * 単発レッスン変更ハンドラ
   */
  const changeSpotLesson: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const spotLesson = spotLessons?.find(
      (spotLesson) => spotLesson.id === parseInt(e.target.value, 10),
    );
    if (!spotLesson || !spotLesson.startAt || !spotLesson.endAt) return;

    if (spotLesson.phase === SpotLessonPhase.NonAttendance) {
      setTimeReadOnly(true);
      amountRef.current.value = '20';
    } else {
      setTimeReadOnly(false);
    }

    setSelectedDate(spotLesson ? new Date(spotLesson.startAt) : selectedDate);
    setDateReadOnly(true);
  };

  /**
   * バリデーション
   */
  const validate = useCallback((): boolean => {
    let err = 0;

    if (hasTime && Number(amountRef.current.value) < 1) {
      err += 1;
      setAmountErr('時間を1以上で入力してください');
    } else if (hasTime && Number(amountRef.current.value) > 1440) {
      err += 1;
      setAmountErr('時間を1440以下で入力してください');
    } else if (
      parseInt(currentCategory) === ClaimCategoryConst.CURRICULUM &&
      Number(amountRef.current.value) > 60
    ) {
      err += 1;
      setAmountErr('カリキュラム作成は60分以内で入力してください');
    } else {
      setAmountErr('');
    }

    if (hasHourlyPayment && Number(removeCommaDelimiter(unitPriceRef.current.value)) < 1) {
      err += 1;
      setUnitPriceErr('単価を1以上で入力してください');
    } else {
      setUnitPriceErr('');
    }

    if (hasTimelinePayment && Number(removeCommaDelimiter(charaAmountRef.current.value)) < 1) {
      err += 1;
      setCharaAmountErr('文字数を1以上で入力してください');
    } else {
      setCharaAmountErr('');
    }

    if (hasTimelinePayment && Number(removeCommaDelimiter(charaUnitPriceRef.current.value)) < 1) {
      err += 1;
      setCharaUnitPriceErr('単価（1文字）を1以上で入力してください');
    } else {
      setCharaUnitPriceErr('');
    }

    if (
      additionalPaymentRef.current.value !== '' &&
      Number(removeCommaDelimiter(additionalPaymentRef.current.value)) < 0
    ) {
      err += 1;
      setAdditionalPaymentErr('対面費用を0以上で入力してください');
    } else {
      setAdditionalPaymentErr('');
    }

    if (hasPerItemPayment && Number(removeCommaDelimiter(perItemUnitPriceRef.current.value)) < 1) {
      err += 1;
      setPerItemUnitPriceErr('単価（1件あたり）を1以上で入力してください');
    } else {
      setPerItemUnitPriceErr('');
    }

    if (hasPerItem && Number(removeCommaDelimiter(perItemAmountRef.current.value)) < 1) {
      err += 1;
      setPerItemAmountErr('件数を1以上で入力してください');
    } else {
      setPerItemAmountErr('');
    }

    if (categoryRef.current.value === 'カテゴリーを選択してください ▼') {
      err += 1;
      setCategoryErr('カテゴリーを選択してください');
    } else {
      setCategoryErr('');
    }

    if (hasLesson && lessonRef.current.value === '専属レッスンを選択してください ▼') {
      err += 1;
      setLessonErr('専属レッスンを選択してください');
    } else {
      setLessonErr('');
    }

    if (hasSpotLesson && spotLessonRef.current.value === '単発レッスンを選択してください ▼') {
      err += 1;
      setSpotLessonErr('単発レッスンを選択してください');
    } else {
      setSpotLessonErr('');
    }

    if (hasStudent && studentRef?.current?.getValue() === null) {
      err += 1;
      setStudentErr('受講生を選択してください');
    } else {
      setStudentErr('');
    }

    if (remarkRef.current.value === '' && remarkIsRequired) {
      err += 1;
      setRemarkErr('備考を入力してください');
    } else {
      setRemarkErr('');
    }

    return err === 0;
  }, [
    currentCategory,
    hasHourlyPayment,
    hasLesson,
    hasSpotLesson,
    hasStudent,
    hasTime,
    hasTimelinePayment,
    hasPerItemPayment,
    hasPerItem,
    remarkIsRequired,
  ]);

  /**
   * カテゴリー取得
   */
  const { data: claimCategoryData } = useGetClaimCategoriesForInstructorQuery();
  const categories = claimCategoryData?.claimCategoriesForInstructor ?? [];

  /**
   * コース取得
   */
  const [getCourse, courseDate] = useGetHistoricalCoursesForInstructorLazyQuery();
  const fetchCourses = async (query: string) => {
    getCourse({
      variables: {
        userName: query,
      },
    });
  };
  const courses = courseDate.data?.historicalCoursesForInstructor ?? [];

  /**
   * 登録パラメーター設定
   */
  const setClaimDetailInput = useCallback((): ClaimDetailInput => {
    return {
      claimCategoryID:
        categoryRef.current.value !== 'カテゴリーを選択してください ▼'
          ? parseInt(categoryRef.current.value)
          : 0,
      date: selectedDate.toISOString(),
      courseID: studentRef?.current?.getValue() ? studentRef?.current?.getValue()?.id : null,
      lessonID:
        lessonRef.current.value !== '専属レッスンを選択してください ▼'
          ? parseInt(lessonRef.current.value)
          : null,
      spotLessonID:
        spotLessonRef.current.value !== '単発レッスンを選択してください ▼'
          ? parseInt(spotLessonRef.current.value)
          : null,
      unitPrice:
        parseInt(categoryRef.current.value) === ClaimCategoryConst.POSTED_TIMELINE
          ? parseInt(removeCommaDelimiter(charaUnitPriceRef.current.value))
          : parseInt(categoryRef.current.value) ===
              ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW
            ? parseInt(removeCommaDelimiter(perItemUnitPriceRef.current.value))
            : parseInt(removeCommaDelimiter(unitPriceRef.current.value)),
      amount:
        parseInt(categoryRef.current.value) === ClaimCategoryConst.POSTED_TIMELINE
          ? parseInt(charaAmountRef.current.value)
          : parseInt(categoryRef.current.value) === ClaimCategoryConst.TEACHING_MATERIAL ||
              parseInt(categoryRef.current.value) === ClaimCategoryConst.REFERRAL_INCENTIVE ||
              parseInt(categoryRef.current.value) === ClaimCategoryConst.OTHER
            ? 1
            : parseInt(categoryRef.current.value) ===
                ClaimCategoryConst.UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW
              ? parseInt(perItemAmountRef.current.value)
              : parseInt(amountRef.current.value),
      additionalPayment: parseInt(removeCommaDelimiter(additionalPaymentRef.current.value)),
      remark: remarkRef.current.value,
    };
  }, [selectedDate]);

  /**
   * 登録処理
   */
  const [addClaimDetail] = useAddClaimDetailForInstructorMutation();
  const submitAddClaim = useSafeAsyncCallback(
    useCallback(async (): Promise<void> => {
      if (!validate()) return;

      const input = setClaimDetailInput();
      try {
        await addClaimDetail({
          variables: {
            yearMonth: utcMonth.toISOString(),
            input: input,
          },
        });
        await refetch(utcMonth, claimCategoryId, studentId);
      } catch (e) {
        showToast(1, getApiErrorMessage(e));
        return;
      }
      clear();
      toggle(false);
      setHasTime(false);
      setHasStudent(false);
      setHasLesson(false);
      setHasSpotLesson(false);
      showToast(0, '請求申請詳細が登録されました。');
    }, [
      validate,
      addClaimDetail,
      refetch,
      utcMonth,
      claimCategoryId,
      studentId,
      setClaimDetailInput,
      showToast,
      clear,
      toggle,
    ]),
  );

  return (
    <Modal
      underlayer
      isOpen={isOpen}
      onClose={() => toggle(false)}
      loading={props.loading}
      header={'請求申請 追加'}
      footer={
        <Buttons>
          <Button
            onClick={() => {
              clear();
              toggle(false);
            }}
            gray
          >
            キャンセル
          </Button>
          <Button onClick={submitAddClaim}>追加</Button>
        </Buttons>
      }
      width={'550px'}
    >
      <FormContainer>
        <AddFormDiv able={true}>
          <label>日付</label>
          <DatePicker
            selected={selectedDate}
            onChange={(d: Date) => setSelectedDate(d)}
            minDate={startOfMonth(month)}
            maxDate={endOfMonth(month)}
            dateFormat="yyyy年MM月dd日"
            disabledKeyboardNavigation
            placeholderText="日程を選択"
            className="input datepicker-date"
            readOnly={dateReadOnly}
          />
        </AddFormDiv>

        <AddFormDiv able={true}>
          <label htmlFor="category">カテゴリー</label>
          <Select
            name="category"
            id="category"
            onChange={changeCategory}
            ref={categoryRef}
            className={categoryErr ? 'err' : ''}
          >
            <option value="カテゴリーを選択してください ▼">カテゴリーを選択してください ▼</option>
            {categories.map((category, i) => {
              return (
                <option value={category.id} key={i}>
                  {category.name}
                </option>
              );
            })}
          </Select>
          <ErrText>{categoryErr}</ErrText>
        </AddFormDiv>

        <AddFormDiv able={hasStudent}>
          <InputWithSearch
            label="受講生"
            ref={studentRef}
            handleInput={fetchCourses}
            shownId={true}
            onSelect={(id) => {
              if (!id) return;
              changeCourse(id);
            }}
            options={formatCourses(filterCourseByClaimCategory(courses, currentCategory))}
          />
          <ErrText>{studentErr}</ErrText>
        </AddFormDiv>

        <AddFormDiv able={hasLesson}>
          <label htmlFor="lesson">
            専属レッスン
            <Small>※受講履歴登録済みのレッスンのみ選択可能です。</Small>
          </label>
          <Select
            name="lesson"
            id="lesson"
            ref={lessonRef}
            className={lessonErr ? 'err' : ''}
            onChange={changeLesson}
          >
            <option value="専属レッスンを選択してください ▼">
              専属レッスンを選択してください ▼
            </option>
            {currentCourse?.lessons
              .filter((lesson) => {
                const lessonDate = lesson.startAt
                  ? format(new Date(lesson.startAt), 'yyyy/MM')
                  : null;
                const currentMonth = lessonDate === format(month, 'yyyy/MM');
                const nextMonth = lessonDate === format(addMonths(month, 1), 'yyyy/MM');
                const prevMonth = lessonDate === format(addMonths(month, -1), 'yyyy/MM');
                switch (parseInt(categoryRef.current.value)) {
                  case ClaimCategoryConst.LESSON:
                    return currentMonth && lesson.completed;
                  case ClaimCategoryConst.LESSON_PREPARATION:
                    return prevMonth || currentMonth || nextMonth;
                  case ClaimCategoryConst.LESSON_CANCEL:
                    return currentMonth && lesson.canceled;
                  default:
                    return currentMonth && lesson.completed;
                }
              })
              .map((lesson, i: number) => {
                return (
                  <option value={lesson.id} key={i}>
                    {lesson.title}({lesson.startAt ? formatDate(new Date(lesson.startAt)) : ''})
                  </option>
                );
              })}
          </Select>
          <ErrText>{lessonErr}</ErrText>
        </AddFormDiv>
        <AddFormDiv able={hasSpotLesson}>
          <label htmlFor="spotLesson">
            単発レッスン
            <Small>※完了ステータスのレッスンのみ選択可能です。</Small>
          </label>
          <Select
            name="spotLesson"
            id="spotLesson"
            ref={spotLessonRef}
            className={spotLessonErr ? 'err' : ''}
            onChange={changeSpotLesson}
          >
            <option value="単発レッスンを選択してください ▼">
              単発レッスンを選択してください ▼
            </option>
            {(!isEmpty(spotLessons) ? spotLessons : [])
              .filter((lesson: SpotLesson) => {
                const lessonDate = format(new Date(lesson.startAt), 'yyyy/MM');
                const currentMonth = lessonDate === format(month, 'yyyy/MM');
                return currentMonth;
              })
              .map((lesson: SpotLesson, i: number) => {
                return (
                  <option value={lesson.id} key={i}>
                    ({formatDate(new Date(lesson.startAt))})
                  </option>
                );
              })}
          </Select>
          <ErrText>{spotLessonErr}</ErrText>
        </AddFormDiv>

        <Col2>
          <Column able={hasTime}>
            <label htmlFor="amount">時間（分）</label>
            <input
              type="number"
              name="amount"
              id="amount"
              min="0"
              max="1440"
              ref={amountRef}
              className={amountErr ? 'err' : ''}
              disabled={timeReadOnly}
            ></input>
            <ErrText>{amountErr}</ErrText>
          </Column>

          <Column able={hasHourlyPayment}>
            <label htmlFor="unit_price">
              単価（円/時）
              <Small>※税込</Small>
            </label>
            <input
              type="text"
              pattern="\d*"
              name="unit_price"
              id="unit_price"
              maxLength={6}
              onChange={handleChangeUnitPrice}
              ref={unitPriceRef}
              className={unitPriceErr ? 'err' : ''}
            ></input>
            <ErrText>{unitPriceErr}</ErrText>
          </Column>

          <Column able={hasPerItem}>
            <label htmlFor="per_item_amount">件数</label>
            <input
              type="number"
              name="per_item_amount"
              id="per_item_amount"
              min="0"
              max="10000"
              ref={perItemAmountRef}
              className={perItemAmountErr ? 'err' : ''}
            ></input>
            <ErrText>{perItemAmountErr}</ErrText>
          </Column>

          <Column able={hasPerItemPayment}>
            <label htmlFor="per_item_unit_price">
              単価（1件あたり）
              <Small>※税込</Small>
            </label>
            <input
              type="text"
              pattern="\d*"
              name="per_item_unit_price"
              id="per_item_unit_price"
              maxLength={6}
              onChange={handleChangePerItemUnitPrice}
              ref={perItemUnitPriceRef}
              className={perItemUnitPriceErr ? 'err' : ''}
            ></input>
            <ErrText>{perItemUnitPriceErr}</ErrText>
          </Column>

          <Column able={hasAdditionalPayment}>
            <label htmlFor="additional_payment">
              対面費用（円）
              <Small>※対面レッスンの場合のみ計上ください</Small>
            </label>
            <input
              type="text"
              pattern="\d*"
              name="additional_payment"
              id="additional_payment"
              maxLength={5}
              onChange={handleChangeAdditionalPayment}
              ref={additionalPaymentRef}
              className={additionalPaymentErr ? 'err' : ''}
            ></input>
            <ErrText>{additionalPaymentErr}</ErrText>
          </Column>

          <Column able={hasTimelinePayment}>
            <label htmlFor="chara_num">文字数</label>
            <input
              type="number"
              name="chara_num"
              id="chara_num"
              min="1"
              max="10000"
              ref={charaAmountRef}
              className={charaAmountErr ? 'err' : ''}
            ></input>
            <ErrText>{charaAmountErr}</ErrText>
          </Column>

          <Column able={hasTimelinePayment}>
            <label htmlFor="chara_unit_price">
              単価（1文字）
              <Small>※税込</Small>
            </label>
            <input
              type="text"
              pattern="\d*"
              name="chara_unit_price"
              id="chara_unit_price"
              maxLength={6}
              onChange={handleChangeCharaUnitPrice}
              ref={charaUnitPriceRef}
              className={charaUnitPriceErr ? 'err' : ''}
            ></input>
            <ErrText>{charaUnitPriceErr}</ErrText>
          </Column>
        </Col2>

        <AddFormDivNote width={width ? width : 100}>
          <label htmlFor="remark">
            備考
            <ErrText as="span">{remarkIsRequired ? '※必須' : ''}</ErrText>
          </label>
          <textarea
            id="remark"
            name="remark"
            ref={remarkRef}
            placeholder={remarkPlaceholder}
            className={remarkErr ? 'err' : ''}
          ></textarea>
          <ErrText>{remarkErr}</ErrText>
        </AddFormDivNote>
      </FormContainer>
    </Modal>
  );
};

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const FormContainer = styled.div`
  padding: 1.25rem;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  input,
  textarea {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input.err,
  textarea.err {
    border: solid 1px #e2001b;
  }

  input {
    font-size: 0.8rem;
    line-height: 1.8em;
    border-radius: 0px;
    padding: 0.6rem 1rem;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }

  textarea {
    font-size: 0.8rem;
    width: 95%;
    height: 8rem;
    padding: 0.6rem 1rem;
    border-radius: 0px;
    border: solid 1px rgba(0, 0, 0, 0.2);
  }
`;
const AddFormDiv = styled.div<{ able: boolean }>`
  display: ${(props) => (props.able ? 'block' : 'none')};
  margin-bottom: 1rem;

  .react-datepicker__navigation {
    background: none;
  }
`;
const Col2 = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-wrap: wrap;
`;
const Column = styled.div<{ able: boolean }>`
  display: ${(props) => (props.able ? 'block' : 'none')};
  width: 50%;
`;
const AddFormDivNote = styled.div<{ width: number }>`
  margin-bottom: 1rem;
  ${(props) => {
    if (!props.width || props.width > 750) {
      return '';
    }
    return 'width: 250px;';
  }};
`;
const Select = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0.6rem 1rem;
  font-size: 0.8rem;
  line-height: 1.8em;
  border-radius: 0px;
  border: solid 1px rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-color: #fff;

  &.err {
    border: solid 1px #e2001b;
  }
`;
const Small = styled.span`
  font-size: 0.8rem;
  font-weight: normal;
  padding-left: 0.5rem;
`;

const ErrText = styled.p`
  color: #e2001b;
  font-size: 0.8rem;
  margin-top: 0.4rem;
  white-space: pre-wrap;
`;
