import React, { useState, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { SubscriptionLayout } from '../../templates/SubscriptionLayout';
import { PageWrapper } from '../../atoms/PageWrapper';
import { AccountSubscriptionPlanCard } from '../../molecules/AccountSubscriptionPlanCard';
import { AccountSubscriptionSchedule } from '../../organisms/AccountSubscriptionSchedule';

import {
  useCheckUpgradeTypesQuery,
  SubscriptionUpgradeType,
  SubscriptionType,
} from '../../../gen/graphql';
import { SUBSCRIPTION_PLANS } from '../../../const/Prices';
import { CANCELLATION_FEE_LINK } from '../../../const/Link';
import { useToastsContext } from '../../../context/ToastsProvider';
import { defaultErrorMessage } from '../../../const/ErrorMessage';
import { Loader } from '../../molecules/Loader';
import { LOWER_META_TITLE } from '../../../const/Service';

export const AccountSubscriptionSelect: React.FC = () => {
  const metaTitle = `加入プラン | ${LOWER_META_TITLE}`;

  const { showToast } = useToastsContext();

  const { loading: checkUpgradeTypeLoading, data: checkUpgradeTypeData } =
    useCheckUpgradeTypesQuery({
      onError: () => {
        showToast(1, defaultErrorMessage);
      },
    });
  const upgradeTypes = useMemo(() => {
    return checkUpgradeTypeData?.checkUpgradeTypes ?? [];
  }, [checkUpgradeTypeData?.checkUpgradeTypes]);
  // SubscriptionTypeでまとめているが、基本SubscriptionTypeで一緒になるはず
  const upgradeTypeMapBySubscriptionType = useMemo(() => {
    return new Map<SubscriptionType, SubscriptionUpgradeType>(
      upgradeTypes.map((u) => {
        return [u.subscriptionType, u.subscriptionUpgradeType];
      }),
    );
  }, [upgradeTypes]);
  const [currentUpgradeType, setCurrentUpgradeType] = useState<SubscriptionUpgradeType>(
    upgradeTypeMapBySubscriptionType.get(SubscriptionType.Premium) ??
      SubscriptionUpgradeType.Schedule,
  );

  const [activeTab, setActiveTab] = useState<SubscriptionType>(SubscriptionType.Premium);

  const handleClickTab = useCallback(
    (subscriptionType: SubscriptionType): void => {
      setActiveTab(subscriptionType);

      const currentUpgradeType = upgradeTypeMapBySubscriptionType.get(subscriptionType);
      if (!currentUpgradeType) {
        return;
      }
      setCurrentUpgradeType(currentUpgradeType);
    },
    [upgradeTypeMapBySubscriptionType],
  );

  const loading = checkUpgradeTypeLoading;

  return (
    <React.Fragment>
      <Loader display={loading}></Loader>
      <SubscriptionLayout metaTitle={metaTitle}>
        <PageWrapper>
          <Title>プランを選択する</Title>
          <Description>
            侍テラコヤでは契約期間に応じてお得になる価格設定となっています。
            <br />
            予定している期間に合ったプランを選択しましょう。
          </Description>
          <Content>
            <TabList>
              <Tab
                onClick={() => handleClickTab(SubscriptionType.Premium)}
                active={activeTab === SubscriptionType.Premium}
              >
                プレミアム
              </Tab>
              <Tab
                onClick={() => handleClickTab(SubscriptionType.Basic)}
                active={activeTab === SubscriptionType.Basic}
              >
                ベーシック
                <Recommend>おすすめ</Recommend>
              </Tab>
              <Tab
                onClick={() => handleClickTab(SubscriptionType.Light)}
                active={activeTab === SubscriptionType.Light}
              >
                ライト
              </Tab>
            </TabList>
            {SUBSCRIPTION_PLANS[activeTab].map((plan) => {
              return (
                <AccountSubscriptionPlanCard
                  key={plan.id}
                  nextPriceID={plan.id}
                  period={plan.period}
                  price={plan.taxIncludedPrice}
                  recommend={plan.recommend}
                  disable={
                    // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                    // eslint-disable-next-line eqeqeq
                    upgradeTypes.find((p) => p.priceID == plan.id)?.subscriptionUpgradeType ===
                    SubscriptionUpgradeType.NotAvailable
                  }
                />
              );
            })}
            <PlanNote>
              ※12ヶ月、もしくは6ヶ月プランで途中解約をした場合、解約手数料が発生いたします。詳しくは
              <a href={CANCELLATION_FEE_LINK} target="_blank">
                こちら
              </a>
              。
            </PlanNote>
          </Content>
          <AccountSubscriptionSchedule
            confirm={false}
            subscriptionUpgradeType={currentUpgradeType}
          />
        </PageWrapper>
      </SubscriptionLayout>
    </React.Fragment>
  );
};

const Title = styled.h2`
  color: rgba(0, 0, 0, 0.87);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
  `}
`;
const Description = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.8;
  `}
`;
const Content = styled.div`
  margin: 4rem auto 5rem;

  ${media.lessThan('medium')`
    margin: 3.25rem auto 4rem;
  `}
`;

const TabList = styled.ul`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 0.625rem;
  margin-bottom: 5rem;
  border-bottom: 1px solid #b6b6b6;

  ${media.lessThan('medium')`
    gap: .125rem;
    margin: 0 -1rem 2rem;
    padding: 0 .625rem;
  `}
`;
const Recommend = styled.p`
  padding: 0.25rem 0.75rem;
  background: #eb0000;
  border-radius: 1px 1px 1px 0;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  position: absolute;
  bottom: 3.1875rem;
  left: -0.5rem;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.125rem 0.25rem;
    border-color: #be000a #be000a transparent transparent;
    position: absolute;
    top: 100%;
    left: 0;
  }

  ${media.lessThan('medium')`
    bottom: 2.25rem;
    left: -.25rem;

    &:after {
      border-width: .125rem;
    }
  `}
`;
const Tab = styled.li<{ active: boolean }>`
  width: calc((100% - 1.25rem) / 3);
  max-width: 240px;
  padding: 1rem;
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 0.375rem 0.375rem 0 0;
  box-sizing: border-box;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.5em;
  text-align: center;
  position: relative;

  &:after {
    content: 'プラン';
  }

  ${(props) =>
    props.active &&
    `
    margin-bottom: -1px;
    padding-top: 1.5rem;
    padding-bottom: calc(1rem + 1px);
    background: #fff;
    border-color: #b6b6b6;
    border-bottom-color: #fff;
    font-weight: 700;

    ${Recommend} {
      bottom: 3.25rem;
    }
  `}

  ${media.lessThan('medium')`
    width: calc((100% - .25rem) / 3);
    padding: .875rem .5rem .5rem;
    font-size: .875rem;
    line-height: 1.25rem;

    &:after {
      content: none;
    }
  `}

  ${(props) =>
    props.active &&
    media.lessThan('medium')`
    padding-top: 1.25rem;
    padding-bottom: calc(.5rem + 1px);

    ${Recommend} {
      bottom: calc(2.25rem + 1px);
    }
  `}
`;
const PlanNote = styled.p`
  margin-top: 2rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;

  a {
    color: #eb0000;
    font-size: 1em;
    font-weight: 700;
    text-decoration: underline;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;
