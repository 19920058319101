import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useToastsContext } from '../../context/ToastsProvider';
import { Loader } from './Loader';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import {
  StudyLogFragment,
  StudyLogUnderstandingLevel,
  useSaveStudyLogMutation,
} from '../../gen/graphql';
import imgStudyLog from '../../static/image/img_study_log.svg';
import iconRangeLow from '../../static/image/icon_range_low.svg';
import iconRangeMiddle from '../../static/image/icon_range_middle.svg';
import iconRangeHigh from '../../static/image/icon_range_high.svg';
import iconChecked from '../../static/image/icon_checked_red.svg';

const UnderStandingLevelItemList = [
  {
    value: StudyLogUnderstandingLevel.Low,
    text: '〜50%',
    balloonText: '理解できていない箇所がある',
    image: iconRangeLow,
  },
  {
    value: StudyLogUnderstandingLevel.Middle,
    text: '50〜80%',
    balloonText: '内容自体は理解できている',
    image: iconRangeMiddle,
  },
  {
    value: StudyLogUnderstandingLevel.High,
    text: '80〜100%',
    balloonText: '友人に教えられるレベルで理解できている',
    image: iconRangeHigh,
  },
] as const;

interface ChapterStudyLogProps {
  chapterID: number;
  studyLog: StudyLogFragment | null;
  onCheckStudyLogStatusChanged: () => Promise<void>;
  modalOpen: () => void;
}

export const ChapterStudyLog: React.FC<ChapterStudyLogProps> = ({
  chapterID,
  studyLog,
  onCheckStudyLogStatusChanged,
  modalOpen,
}): JSX.Element => {
  const { showToast } = useToastsContext();
  const [showLoader, setShowLoader] = useState(false);
  const [hoverItem, setHoverItem] = useState<StudyLogUnderstandingLevel | null>(null);
  const [isIntersectingBgImage, setIsIntersectingBgImage] = useState(false);

  const bgImageRef = useRef<HTMLDivElement | null>(null);

  const [saveStudyLogMutation] = useSaveStudyLogMutation();

  const saveStudyLog = useSafeAsyncCallback(
    useCallback(
      async (understandingLevel: StudyLogUnderstandingLevel): Promise<void> => {
        // 変更がない場合はAPI叩かない
        if (understandingLevel === studyLog?.understandingLevel) {
          return;
        }

        setShowLoader(true);

        const variables = { chapterID, understandingLevel };
        try {
          // 保存
          try {
            await saveStudyLogMutation({ variables });
          } catch (e) {
            // GraphQLのエラーは共通のエラーハンドラでSentryに送信しているためここでは握りつぶす
            showToast(1, '更新に失敗しました。');
            return;
          }
          // 成功時のコールバック(refetch)
          await onCheckStudyLogStatusChanged();
        } finally {
          setShowLoader(false);
          modalOpen();
        }
      },
      [
        studyLog,
        chapterID,
        onCheckStudyLogStatusChanged,
        saveStudyLogMutation,
        showToast,
        modalOpen,
      ],
    ),
  );

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
      // eslint-disable-next-line eqeqeq
      if (isIntersectingBgImage != entry.isIntersecting) {
        setIsIntersectingBgImage(entry.isIntersecting);
      }
    });
    if (bgImageRef.current) {
      observer.observe(bgImageRef.current);
    }
    return () => {
      if (bgImageRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(bgImageRef.current);
      }
    };
  }, [isIntersectingBgImage]);

  return (
    <StudyLogBlock>
      <Loader display={showLoader} />
      <BgImage ref={bgImageRef} startAnimation={isIntersectingBgImage} />
      <Title>理解度を選択して次に進みましょう</Title>
      <Description>ボタンを押していただくと次の章に進むことができます</Description>
      <UnderstandingLevelCardContainer>
        {UnderStandingLevelItemList.map((item) => (
          <UnderstandingLevelCard
            key={item.value}
            isActive={studyLog?.understandingLevel === item.value || hoverItem === item.value}
            onClick={() => saveStudyLog(item.value)}
            onMouseOver={() => setHoverItem(item.value)}
            onMouseLeave={() => setHoverItem(null)}
          >
            {studyLog?.understandingLevel === item.value && (
              <UnderstandingLevelCheckedImg src={iconChecked} alt="選択中" />
            )}
            <UnderstandingLevelImg src={item.image} alt={item.text} data-e2e="understandingLevel" />
            <UnderstandingLevelText>{item.text}</UnderstandingLevelText>
            <p className="balloonText">{item.balloonText}</p>
          </UnderstandingLevelCard>
        ))}
      </UnderstandingLevelCardContainer>
    </StudyLogBlock>
  );
};

export default ChapterStudyLog;

const StudyLogBlock = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0 2.25rem 2.25rem;
  background: linear-gradient(0deg, #ffffff, #ffffff), #fce5e8;
  border: 1px solid #eb0000;
  box-sizing: border-box;
  border-radius: 0.0625rem;

  ${media.lessThan('small')`
    padding: 2rem;
  `}
`;

const BgImage = styled.div<{ startAnimation: boolean }>`
  width: 21.1875rem;
  height: 11.375rem;
  background: url(${imgStudyLog}) center / contain no-repeat;

  @keyframes bg-animation {
    0% {
      transform: scale(0.6);
      opacity: 0.3;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  ${({ startAnimation }) => startAnimation && `animation: bg-animation 0.6s ease-in-out;`}

  ${media.lessThan('small')`
    width: 16rem;
    height: 8.625rem;
  `}
`;

const Title = styled.h2`
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  text-align: center;

  ${media.lessThan('small')`
    font-size: 1.375rem;
  `}
`;

const Description = styled.p`
  margin-top: 1rem;
  line-height: 1.875;
  text-align: center;

  ${media.lessThan('small')`
    margin-top: 0.5rem;
  `}
`;

const UnderstandingLevelCardContainer = styled.div`
  width: 100%;
  margin-top: 1.25rem;
  display: flex;
  justify-content: center;

  & > :not(:first-child) {
    margin-left: 0.875rem;
  }

  ${media.lessThan('small')`
    width: 100%;
    
    & > :not(:first-child) {
      margin-left: 0;
      margin-top: 0.75rem;
    }
    flex-direction: column;
    align-items: center;
  `}
`;

const UnderstandingLevelCard = styled.div<{ isActive: boolean }>`
  position: relative;
  padding: 0.75rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: calc(100% / ${UnderStandingLevelItemList.length});
  height: 5.75rem;
  max-width: 10rem;
  box-sizing: border-box;
  border-radius: 0.0625rem;
  cursor: pointer;
  transition: 0.1s;
  border: ${({ isActive }) => (isActive ? `2px solid #eb0000` : `1px solid rgba(0, 0, 0, 0.1)`)};
  opacity: ${({ isActive }) => (isActive ? `1` : `0.35`)};

  ${media.lessThan('small')`
    flex-flow: row;
    width: 100%;
    max-width: 100%;
    height: 2.5rem;
  `}

  .balloonText {
    display: none;
    position: absolute;
    top: 6.25rem;
    right: 0;
    padding: 0.375rem 0.5625rem;
    width: 100%;
    background: #ffffff;
    font-size: 0.75rem;
    line-height: 1.5;
    border: 1px solid #f76969;
    box-sizing: border-box;
    border-radius: 0.0625rem;
    pointer-events: none;
    z-index: 1;

    &:before {
      content: '';
      position: absolute;
      display: block;
      right: 1.875rem;
      top: -0.5rem;
      border-right: 5px solid transparent;
      border-bottom: 7px solid #eb0000;
      border-left: 5px solid transparent;
    }

    &:after {
      content: '';
      position: absolute;
      display: block;
      right: 1.875rem;
      top: -0.4375rem;
      border-right: 5px solid transparent;
      border-bottom: 7px solid #fff;
      border-left: 5px solid transparent;
    }

    ${media.lessThan('small')`
      top: 3rem;
      right: 1rem;
      width: auto;
    `}
  }

  :hover {
    .balloonText {
      display: block;
    }
  }
`;

const UnderstandingLevelImg = styled.img`
  width: 2.75rem;
  height: 2.75rem;

  ${media.lessThan('small')`
    width: 1.5rem;
    height: 1.5rem;
  `}
`;

const UnderstandingLevelCheckedImg = styled.img`
  position: absolute;
  top: 0.25rem;
  right: 0.375rem;
`;

const UnderstandingLevelText = styled.p`
  margin-top: 0.625rem;
  font-size: 0.9375rem;

  ${media.lessThan('small')`
    margin-top: 0;
    margin-left: 0.375rem;
    width: 4.625rem;
  `}
`;
