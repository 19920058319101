import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FunctionType, PermissionType } from '../../../const/UserPermission';
import {
  PracticeFragment,
  PracticeSubmitDetailFragment,
  useGetPracticeSubmitQuery,
  useNewPracticeSubmitMutation,
  useRePracticeSubmitMutation,
  PracticeSubmitDetailReviewStatus,
} from '../../../gen/graphql';
import { useUser } from '../../../redux/user/useUser';
import { PageWrapper } from '../../atoms/PageWrapper';
import { EmptyBlock } from '../../molecules/EmptyBlock';
import { Loader } from '../../molecules/Loader';
import { PracticeSubmitBreadcrumb } from '../../organisms/PracticeSubmitBreadcrumb';
import {
  PracticeSubmitInputArea,
  PracticeSubmitResponseProps,
} from '../../organisms/PracticeSubmitInputArea';
import { BasicLayout } from '../../templates/BasicLayout';

import { NotFoundPage } from '../public/NotFound';
import { LOWER_META_TITLE, SERVICE_NAME } from '../../../const/Service';

export const EditPracticeSubmitContainer = (): JSX.Element => {
  const pathParams = useParams<{ practice_submit_id: string; practice_submit_detail_id: string }>();
  const pathPracticeSubmitID = pathParams.practice_submit_id;
  const pathPracticeSubmitDetailID = pathParams.practice_submit_detail_id;

  if (!pathPracticeSubmitID || !pathPracticeSubmitDetailID) return NotFoundPage;

  return (
    <EditPracticeSubmit
      practiceSubmitID={pathPracticeSubmitID}
      practiceSubmitDetailID={pathPracticeSubmitDetailID}
    />
  );
};

interface EditPracticeSubmitProps {
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
}

const EditPracticeSubmit = (props: EditPracticeSubmitProps) => {
  const { permissionCheck } = useUser();

  const {
    data: practiceSubmitData,
    loading: practiceSubmitLoading,
    refetch: refetchPracticeSubmit,
  } = useGetPracticeSubmitQuery({
    variables: {
      id: props.practiceSubmitID,
    },
    notifyOnNetworkStatusChange: true,
  });
  const fetchPracticeSubmit = useCallback(async (): Promise<void> => {
    refetchPracticeSubmit();
  }, [refetchPracticeSubmit]);
  const practiceSubmit = useMemo(
    () => practiceSubmitData?.getPracticeSubmit,
    [practiceSubmitData?.getPracticeSubmit],
  );
  const practice = useMemo(() => practiceSubmit?.practice, [practiceSubmit?.practice]);
  const practiceSubmitDetails = useMemo(
    () => practiceSubmit?.practiceSubmitDetails,
    [practiceSubmit?.practiceSubmitDetails],
  );
  const practiceSubmitDetailsCount = useMemo(
    () => (practiceSubmitDetails ? practiceSubmitDetails.length : 0),
    [practiceSubmitDetails],
  );
  const practiceSubmitDetail = useMemo(
    () => practiceSubmitDetails?.find((detail) => detail.id === props.practiceSubmitDetailID),
    [practiceSubmitDetails, props.practiceSubmitDetailID],
  );

  const pageTitle = useMemo(
    () => (practiceSubmitDetailsCount > 1 ? '課題再提出フォーム' : '課題提出フォーム'),
    [practiceSubmitDetailsCount],
  );

  const metaTitle = useMemo(() => `${pageTitle} | ${LOWER_META_TITLE}`, [pageTitle]);
  const metaDescription = `${SERVICE_NAME}の課題ページです。課題にチャレンジすることでアウトプット力が鍛えられスキルを定着させることができます。課題を単純に解くだけではなく、提出&レビューを受けることでより知識が磨かれます。`;

  return (
    <>
      <Loader display={practiceSubmitLoading} />
      <BasicLayout pageTitle={pageTitle} metaTitle={metaTitle} metaDescription={metaDescription}>
        <PageWrapper>
          <PracticeSubmitBreadcrumb
            permission={permissionCheck(
              FunctionType.ProgramForInstructorAndCoach,
              PermissionType.Read,
            )}
            programTitle={practice?.program.title}
            programID={practice?.programID}
            practiceTitle={practice?.title}
            practiceID={practice?.id}
          />
          {!practice ? (
            <EmptyBlock title="提出できる課題がありません" />
          ) : practiceSubmitDetailsCount === 0 || !practiceSubmitDetail ? (
            <EmptyBlock title="提出物がありません" />
          ) : practiceSubmitDetail.reviewStatus === PracticeSubmitDetailReviewStatus.Draft ? (
            // 初回提出
            practiceSubmitDetailsCount === 1 ? (
              <EditCreatePracticeSubmit
                practiceSubmitID={props.practiceSubmitID}
                practiceSubmitDetailID={props.practiceSubmitDetailID}
                practice={practice}
                practiceSubmitDetail={practiceSubmitDetail}
                fetch={fetchPracticeSubmit}
              />
            ) : (
              // 2回目以降の提出
              <EditReCreatePracticeSubmit
                practiceSubmitID={props.practiceSubmitID}
                practiceSubmitDetailID={props.practiceSubmitDetailID}
                practice={practice}
                practiceSubmitDetail={practiceSubmitDetail}
                fetch={fetchPracticeSubmit}
                isInstructorReviewRequested={
                  practiceSubmitData?.getPracticeSubmit?.isInstructorReviewRequested
                }
              />
            )
          ) : (
            <EmptyBlock title="下書きした提出物がありません" />
          )}
        </PageWrapper>
      </BasicLayout>
    </>
  );
};

interface EditCreatePracticeSubmitProps {
  practiceSubmitID: string;
  practiceSubmitDetailID: string;
  practice: PracticeFragment;
  practiceSubmitDetail: PracticeSubmitDetailFragment;
  fetch: () => Promise<void>;
  isInstructorReviewRequested?: boolean;
}

const EditCreatePracticeSubmit = (props: EditCreatePracticeSubmitProps) => {
  const [newPracticeSubmit] = useNewPracticeSubmitMutation();
  const draft = useCallback(
    async (url: string, comment?: string): Promise<PracticeSubmitResponseProps> => {
      const { data } = await newPracticeSubmit({
        variables: {
          input: {
            practiceSubmitID: props.practiceSubmitID,
            practiceSubmitDetailID: props.practiceSubmitDetailID,
            url: url,
            content: comment,
            isDraft: true,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (
        !data ||
        !data.newPracticeSubmit ||
        !data.newPracticeSubmit.practiceSubmitDetails ||
        data.newPracticeSubmit.practiceSubmitDetails.length === 0
      ) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.newPracticeSubmit.id,
        practiceSubmitDetailID: data.newPracticeSubmit.practiceSubmitDetails[0].id,
      };
    },
    [newPracticeSubmit, props.practiceSubmitID, props.practiceSubmitDetailID],
  );
  const submit = useCallback(
    async (url: string, comment?: string): Promise<PracticeSubmitResponseProps> => {
      const { data } = await newPracticeSubmit({
        variables: {
          input: {
            practiceSubmitID: props.practiceSubmitID,
            practiceSubmitDetailID: props.practiceSubmitDetailID,
            url: url,
            content: comment,
            isDraft: false,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (!data || !data.newPracticeSubmit) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.newPracticeSubmit.id,
        practiceSubmitDetailID: '',
      };
    },
    [newPracticeSubmit, props.practiceSubmitID, props.practiceSubmitDetailID],
  );

  const inputProps = useMemo(
    () => ({
      practice: props.practice,
      draft: draft,
      submit: submit,
      edit: {
        practiceSubmitID: props.practiceSubmitID,
        initialUrl: props.practiceSubmitDetail.url ?? undefined,
        initialComment: props.practiceSubmitDetail.content ?? undefined,
        fetch: props.fetch,
      },
    }),
    [
      draft,
      props.fetch,
      props.practice,
      props.practiceSubmitDetail.content,
      props.practiceSubmitDetail.url,
      props.practiceSubmitID,
      submit,
    ],
  );

  return <PracticeSubmitInputArea {...inputProps} />;
};

const EditReCreatePracticeSubmit = (props: EditCreatePracticeSubmitProps) => {
  const [rePracticeSubmit] = useRePracticeSubmitMutation();
  const draft = useCallback(
    async (url: string, comment?: string): Promise<PracticeSubmitResponseProps> => {
      const { data } = await rePracticeSubmit({
        variables: {
          input: {
            practiceSubmitID: props.practiceSubmitID,
            practiceSubmitDetailID: props.practiceSubmitDetailID,
            url: url,
            content: comment,
            isDraft: true,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (
        !data ||
        !data.rePracticeSubmit ||
        !data.rePracticeSubmit.practiceSubmitDetails ||
        data.rePracticeSubmit.practiceSubmitDetails.length === 0
      ) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.rePracticeSubmit.id,
        practiceSubmitDetailID: data.rePracticeSubmit.practiceSubmitDetails[0].id,
      };
    },
    [rePracticeSubmit, props.practiceSubmitID, props.practiceSubmitDetailID],
  );
  const submit = useCallback(
    async (
      url: string,
      comment?: string,
      requestsInstructorReview?: boolean,
    ): Promise<PracticeSubmitResponseProps> => {
      const { data } = await rePracticeSubmit({
        variables: {
          input: {
            practiceSubmitID: props.practiceSubmitID,
            practiceSubmitDetailID: props.practiceSubmitDetailID,
            url: url,
            content: comment,
            isDraft: false,
            requestsInstructorReview: requestsInstructorReview,
          },
        },
      });

      // 成功しているのにデータがないことはありえないが、型的に null | undefined ありなのでチェック
      if (!data || !data.rePracticeSubmit) {
        return { isError: true, practiceSubmitID: '', practiceSubmitDetailID: '' };
      }

      return {
        isError: false,
        practiceSubmitID: data.rePracticeSubmit.id,
        practiceSubmitDetailID: '',
      };
    },
    [rePracticeSubmit, props.practiceSubmitID, props.practiceSubmitDetailID],
  );

  const inputProps = useMemo(
    () => ({
      practice: props.practice,
      draft: draft,
      submit: submit,
      edit: {
        practiceSubmitID: props.practiceSubmitID,
        initialUrl: props.practiceSubmitDetail.url ?? undefined,
        initialComment: props.practiceSubmitDetail.content ?? undefined,
        fetch: props.fetch,
      },
      isInstructorReviewRequested: props.isInstructorReviewRequested,
    }),
    [
      draft,
      props.fetch,
      props.isInstructorReviewRequested,
      props.practice,
      props.practiceSubmitDetail.content,
      props.practiceSubmitDetail.url,
      props.practiceSubmitID,
      submit,
    ],
  );

  return <PracticeSubmitInputArea {...inputProps} />;
};
