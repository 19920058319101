import { SERVICE_NAME } from '../const/Service';

/* インストラクター用 請求カテゴリー */
export class ClaimCategory {
  // 受講生あり/時間あり/コースあり/レッスンあり
  public static readonly LESSON = 1; // レッスン
  public static readonly LESSON_PREPARATION = 2; // レッスン前後対応
  public static readonly LESSON_CANCEL = 5; // レッスンキャンセル

  // 受講生あり/時間あり/コースなし/レッスンあり
  public static readonly SPOT_LESSON = 15; // 単発レッスン

  // 受講生あり/時間あり/コースあり/レッスンなし
  public static readonly QUESTION = 3; // 質問対応(担当受講生)
  public static readonly CURRICULUM = 4; // カリキュラム作成

  // 受講生なし/時間あり/コースなし/レッスンなし
  public static readonly CATCHUP = 6; // キャッチアップ
  public static readonly CONSULTING = 7; // コンサル相談
  public static readonly MENTORING = 8; // メンタリング
  public static readonly QUESTION_GENERAL = 9; // 質問対応(Q&A)
  public static readonly REQUEST = 10; // 運営側依頼対応
  public static readonly TEACHING_MATERIAL_DEVELOPING = 14; // 教材開発
  public static readonly TERAKOYA_PRACTICE_REVIEWER = 17; // 課題レビュー時給制
  public static readonly RESKILLING_COLLEGE = 18; // リスキルカレッジ対応
  public static readonly SHIGOTORU = 19; // シゴトル対応
  public static readonly REQUEST_BY_CORPORATE_DIVISION = 21; // 法人側依頼対応
  public static readonly DOJO_LESSON = 22; // 道場レッスン
  public static readonly OnSiteLesson = 23; // 常駐レッスン対応
  public static readonly DOJO_CONTENT_DEVELOPING = 24; // 道場コンテンツ開発

  // 受講生なし/時間あり(件に置き換え)/コースなし/レッスンなし
  public static readonly UNIT_PRICE_SYSTEM_OF_PRACTICE_REVIEW = 20; // 課題レビュー単価制

  // 受講生なし/時間なし/コースなし/レッスンなし
  public static readonly TEACHING_MATERIAL = 11; // 書籍(教材)費用
  public static readonly REFERRAL_INCENTIVE = 12; // リファラルインセンティブ
  public static readonly POSTED_TIMELINE = 16; // タイムライン投稿
  public static readonly OTHER = 13; // その他
}
/* 通知タイプ */
export class Notification {
  public static readonly LESSON_SCHEDULE = 1; // レッスン日程直近
  public static readonly LESSON_SCHEDULE_UNCREATED = 2; // レッスン未作成
  public static readonly MESSAGE = 3; // メッセージ
  public static readonly QUESTION_CREATED = 4; // 質問作成
  public static readonly QUESTION_ANSWER_CREATED = 5; // 質問回答作成
  public static readonly QUESTION_COMMENT_CREATED = 6; // 質問コメント作成
  public static readonly QUESTION_UNSOLVED = 7; // 質問未解決
  public static readonly LESSON_CREATED = 8; // レッスン作成
  public static readonly INQUIRY_CREATED = 9; // お問い合わせ作成
  public static readonly CLAIM_APPLIED = 10; // 請求申請承認
  public static readonly ATTENDANCE_STATUS_CHANGED = 11; // 受講生ステータス変更
  public static readonly BACK_SCHOOL = 12; // 復学前
  public static readonly STUDY_REPORT_COMMENT_CREATED = 13; // 学習ログコメント作成
  public static readonly SPOT_LESSON_REGISTERED = 14; // スポットレッスン予約
  public static readonly SPOT_LESSON_REMIND = 15; // スポットレッスンリマインド
  public static readonly SPOT_LESSON_CANCEL = 16; // スポットレッスンキャンセル
  public static readonly SPOT_LESSON_LOG_UNREGISTERED = 17; // スポットレッスンログ未登録
  public static readonly GIVEN_FREE_SPOT_LESSON_TICKET = 18; // スポットレッスンの無料チケット付与
}

export class StudyReport {
  public static readonly MAX_TIME = 24 * 60;
  public static readonly LEARNED_CONTENT_MAX_NUMBER_OF_ITEM = 5;
  public static readonly DEFAULT_VALUE = `#今日の積み上げ メモ

✅Laravel入門の第3章
✅侍教材のHTML/CSSの復習
✅XDでLPのワイヤー作成
✅ホリエモンの「多動力」読了

HTML/CSSの2周目をやる中で、
最初はただの写経でほとんど意味わかっていなかったけど、
理解が深まってきた感じがした。成長を感じる！

#駆け出しエンジニア
#駆け出しエンジニアと繋がりたい
#ウェブデザイン
#PHP
#Laravel
#侍エンジニア
#${SERVICE_NAME}`;
}
