import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { ReviewStatusLabels } from '../../const/PracticeSubmit';
import { useUser } from '../../redux/user/useUser';
import { PracticeSubmitDetailReviewStatus } from '../../gen/graphql';
import crossIcon from '../../static/image/cross.svg';

import circleIcon from '../../static/image/icon_circle_white.svg';

interface PracticeSubmitReviewStatusIconProps {
  reviewStatus: PracticeSubmitDetailReviewStatus | undefined;
}

export const PracticeSubmitReviewStatusIcon: React.FC<PracticeSubmitReviewStatusIconProps> = ({
  reviewStatus,
}) => {
  const { className, label, icon } = useStatus(reviewStatus);

  return (
    <Icon className={className}>
      {icon}
      <span>{label}</span>
    </Icon>
  );
};

const useStatus = (reviewStatus: PracticeSubmitDetailReviewStatus | undefined) => {
  const { user } = useUser();
  const isInstructor = user.lmsUser?.isInstructor;

  const getClassName = () => {
    if (!reviewStatus) {
      return 'not-submitted';
    }
    if (!isInstructor && reviewStatus === PracticeSubmitDetailReviewStatus.WaitingForAiReview) {
      return 'waiting-for-instructor-review';
    }
    return {
      [PracticeSubmitDetailReviewStatus.Passed]: 'passed',
      [PracticeSubmitDetailReviewStatus.Failed]: 'failed',
      [PracticeSubmitDetailReviewStatus.WaitingForInstructorReview]:
        'waiting-for-instructor-review',
      [PracticeSubmitDetailReviewStatus.WaitingForAiReview]: 'waiting-for-ai-review',
      [PracticeSubmitDetailReviewStatus.Draft]: 'draft',
    }[reviewStatus];
  };

  const getLabel = () => {
    if (!reviewStatus) {
      return '未提出';
    }
    if (!isInstructor && reviewStatus === PracticeSubmitDetailReviewStatus.WaitingForAiReview) {
      return ReviewStatusLabels[PracticeSubmitDetailReviewStatus.WaitingForInstructorReview];
    }
    return ReviewStatusLabels[reviewStatus];
  };

  const getIcon = () => {
    switch (reviewStatus) {
      case PracticeSubmitDetailReviewStatus.Passed:
        return <img src={circleIcon} alt="icon" width="10" height="10" />;
      case PracticeSubmitDetailReviewStatus.Failed:
        return <img src={crossIcon} alt="icon" width="10" height="8" />;
      default:
        return undefined;
    }
  };

  return { className: getClassName(), label: getLabel(), icon: getIcon() };
};

const Icon = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  border-radius: 0.1875rem;
  min-width: 74px;

  ${media.lessThan('medium')`
    min-width: 84px;
  `}

  span {
    font-size: 0.6875rem;
  }

  &.not-submitted {
    background: #575e71;
    color: #fff;
  }

  &.passed {
    background: #4bb89e;
    color: #fff;

    span {
      margin: 0 0 1px;
    }

    img {
      margin: 0 6px 0 0;
    }
  }

  &.failed {
    background: #e95252;
    color: #fff;

    span {
      margin: 0 0 1px;
    }

    img {
      margin: 0 6px 0 0;
    }
  }

  &.waiting-for-instructor-review {
    border: 1px solid #f09122;
    background: #fff4e7;
    color: #da8017;
  }

  &.waiting-for-ai-review {
    font-size: 0.5rem;
    border: 1px solid #1d6ec5;
    background: #e7f7ff;
    color: #1d6ec5;
  }

  &.draft {
    background: #575e71;
    color: #fff;
  }
`;
