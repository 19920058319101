import React, { useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { RankingCard } from '../molecules/RankingCard';

import {
  useGetStudySummaryListQuery,
  useGetStudyFavoriteSummaryListQuery,
  useGetStudyTagSummaryListQuery,
  StudySummaryListFragment,
  StudyFavoriteSummaryFragment,
  StudyTagSummaryListFragment,
} from '../../gen/graphql';

export const StudyLogRank: React.FC = () => {
  // 学習時間
  const [timeUser, setTimeUser] = useState<StudySummaryListFragment[]>([]);

  // いいね数
  const [likeUser, setLikeUser] = useState<StudyFavoriteSummaryFragment[]>([]);

  // 人気言語
  const [famousLanguage, setFamousLanguage] = useState<StudyTagSummaryListFragment[]>([]);

  const studySummaryListData = useGetStudySummaryListQuery({
    onCompleted: (data) => {
      setTimeUser(data.getStudySummaryList.studyReportTimeWeek);
    },
  });
  const studyFavoriteSummaryListData = useGetStudyFavoriteSummaryListQuery({
    onCompleted: (data) => {
      setLikeUser(data.getStudyFavoriteSummaryList.studyFavoriteCountWeek);
    },
  });
  const studyTagSummaryListData = useGetStudyTagSummaryListQuery({
    onCompleted: (data) => {
      setFamousLanguage(data.getStudyTagSummaryList.studyReportTimeWeek);
    },
  });

  /**
   * 時間軸変更
   * @param range
   */
  const switchTimeRange = (range: string) => {
    if (!studySummaryListData.data) return;

    if (range === 'week') {
      setTimeUser(
        [...studySummaryListData.data.getStudySummaryList.studyReportTimeWeek].sort((a, b) =>
          a.studyReportTimeWeek > b.studyReportTimeWeek ? -1 : 1,
        ),
      );
    } else if (range === 'month') {
      setTimeUser(
        [...studySummaryListData.data.getStudySummaryList.studyReportTimeMonth].sort((a, b) =>
          a.studyReportTimeMonth > b.studyReportTimeMonth ? -1 : 1,
        ),
      );
    } else {
      setTimeUser(
        [...studySummaryListData.data.getStudySummaryList.studyReportTime].sort((a, b) => {
          if (!a.studyReportTime || !b.studyReportTime) return 0;
          return a.studyReportTime > b.studyReportTime ? -1 : 1;
        }),
      );
    }
  };

  /**
   * いいね数軸変更
   * @param range
   */
  const switchLikeRange = (range: string) => {
    if (!studyFavoriteSummaryListData.data) return;

    if (range === 'week') {
      setLikeUser(
        [
          ...studyFavoriteSummaryListData.data.getStudyFavoriteSummaryList.studyFavoriteCountWeek,
        ].sort((a, b) => (a.studyFavoriteCountWeek > b.studyFavoriteCountWeek ? -1 : 1)),
      );
    } else if (range === 'month') {
      setLikeUser(
        [
          ...studyFavoriteSummaryListData.data.getStudyFavoriteSummaryList.studyFavoriteCountMonth,
        ].sort((a, b) => (a.studyFavoriteCountMonth > b.studyFavoriteCountMonth ? -1 : 1)),
      );
    } else {
      setLikeUser(
        [...studyFavoriteSummaryListData.data.getStudyFavoriteSummaryList.studyFavoriteCount].sort(
          (a, b) => (a.studyFavoriteCount > b.studyFavoriteCount ? -1 : 1),
        ),
      );
    }
  };

  /**
   * 人気言語軸変更
   * @param range
   */
  const switchFamousLanguageRange = (range: string) => {
    if (!studyTagSummaryListData.data) return;

    if (range === 'week') {
      setFamousLanguage(
        [...studyTagSummaryListData.data.getStudyTagSummaryList.studyReportTimeWeek].sort((a, b) =>
          a.studyReportTimeWeek > b.studyReportTimeWeek ? -1 : 1,
        ),
      );
    } else if (range === 'month') {
      setFamousLanguage(
        [...studyTagSummaryListData.data.getStudyTagSummaryList.studyReportTimeMonth].sort(
          (a, b) => (a.studyReportTimeMonth > b.studyReportTimeMonth ? -1 : 1),
        ),
      );
    } else {
      setFamousLanguage(
        [...studyTagSummaryListData.data.getStudyTagSummaryList.studyReportTime].sort((a, b) =>
          a.studyReportTime > b.studyReportTime ? -1 : 1,
        ),
      );
    }
  };

  return (
    <React.Fragment>
      <H2>ランキング</H2>
      <RankingCard
        title="学習時間"
        type="time"
        studySummary={timeUser}
        studyFavoriteSummary={likeUser}
        studyTagSummaryList={famousLanguage}
        switch={switchTimeRange}
      />
      <RankingCard
        title="いいね数"
        type="like"
        studySummary={timeUser}
        studyFavoriteSummary={likeUser}
        studyTagSummaryList={famousLanguage}
        switch={switchLikeRange}
      />
      <RankingCard
        title="人気言語"
        type="language"
        studySummary={timeUser}
        studyFavoriteSummary={likeUser}
        studyTagSummaryList={famousLanguage}
        switch={switchFamousLanguageRange}
      />
    </React.Fragment>
  );
};

const H2 = styled.h2`
  margin-bottom: 1.125rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    padding: 0 1rem;
  `}
`;
