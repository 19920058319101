import React, { useMemo } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import DowngradeImage from '../../static/image/account_subscription_schedule_down.png';
import DowngradeImageSp from '../../static/image/account_subscription_schedule_down_sp.png';
import UpgradeImage from '../../static/image/account_subscription_schedule_up.png';
import UpgradeImageSp from '../../static/image/account_subscription_schedule_up_sp.png';

import { SubscriptionUpgradeType } from '../../gen/graphql';
import { format, parseISO } from 'date-fns';

interface Props {
  subscriptionUpgradeType?: SubscriptionUpgradeType;
  currentPeriodStart?: string;
  nextPeriodStart?: string;
  nextPeriodEnd?: string;
  nextPlanName?: string;
  confirm: boolean;
}

export const AccountSubscriptionSchedule: React.FC<Props> = ({
  subscriptionUpgradeType,
  currentPeriodStart,
  nextPeriodStart,
  nextPeriodEnd,
  nextPlanName,
  confirm,
}) => {
  const formatYear = (date: Date) => {
    return format(date, 'yyyy年');
  };

  const formatMonthDay = (date: Date) => {
    return format(date, 'MM年dd日');
  };

  const displayCurrentContractStartDate: Date = useMemo(() => {
    return currentPeriodStart ? parseISO(currentPeriodStart) : new Date(2022, 11, 1);
  }, [currentPeriodStart]);

  const displayNextContractEndDate: Date = useMemo(() => {
    return nextPeriodEnd
      ? parseISO(nextPeriodEnd)
      : subscriptionUpgradeType === SubscriptionUpgradeType.Immediate
        ? new Date(2023, 11, 21)
        : new Date(2023, 12, 1);
  }, [nextPeriodEnd, subscriptionUpgradeType]);

  const displayNextContractStartDate: Date = useMemo(() => {
    return nextPeriodStart ? parseISO(nextPeriodStart) : new Date(2022, 12, 1);
  }, [nextPeriodStart]);

  const data = useMemo(() => {
    switch (subscriptionUpgradeType) {
      case SubscriptionUpgradeType.Immediate:
        return {
          description: confirm ? (
            '新プランは本日から適用されます。'
          ) : (
            <>
              アップグレードの場合は、即時に変更後のプランが適用されます。
              <br />
              <span>現プランの残存期間に関しては秒割り計算し返金いたします。</span>
            </>
          ),
          image: UpgradeImage,
          spImage: UpgradeImageSp,
          date: (
            <Dates column={3}>
              <span>
                {formatYear(displayCurrentContractStartDate)}
                <br />
                {formatMonthDay(displayCurrentContractStartDate)}
              </span>
              <span>本日</span>
              <span>
                {formatYear(displayNextContractEndDate)}
                <br />
                {formatMonthDay(displayNextContractEndDate)}
              </span>
            </Dates>
          ),
        };
      case SubscriptionUpgradeType.DowngradeSchedule:
        return {
          description: confirm
            ? `変更後のプランは
            ${formatYear(displayNextContractStartDate)}${formatMonthDay(
              displayNextContractStartDate,
            )}
            から適用されます。`
            : 'ダウングレードの場合は、次回更新時に変更後のプランが適用されます。',
          image: DowngradeImage,
          spImage: DowngradeImageSp,
          date: (
            <Dates column={4}>
              <span>
                {formatYear(displayCurrentContractStartDate)}
                <br />
                {formatMonthDay(displayCurrentContractStartDate)}
              </span>
              <span>本日</span>
              <span>
                {formatYear(displayNextContractStartDate)}
                <br />
                {formatMonthDay(displayNextContractStartDate)}
              </span>
              <span>
                {formatYear(displayNextContractEndDate)}
                <br />
                {formatMonthDay(displayNextContractEndDate)}
              </span>
            </Dates>
          ),
        };
      case SubscriptionUpgradeType.Schedule:
        return {
          description: '6ヶ月・12ヶ月プランへの変更は、次回更新時に変更後のプランが適用されます。',
          image: DowngradeImage,
          spImage: DowngradeImageSp,
          date: (
            <Dates column={4}>
              <span>
                {formatYear(displayCurrentContractStartDate)}
                <br />
                {formatMonthDay(displayCurrentContractStartDate)}
              </span>
              <span>本日</span>
              <span>
                {formatYear(displayNextContractStartDate)}
                <br />
                {formatMonthDay(displayNextContractStartDate)}
              </span>
              <span>
                {formatYear(displayNextContractEndDate)}
                <br />
                {formatMonthDay(displayNextContractEndDate)}
              </span>
            </Dates>
          ),
        };
    }
  }, [
    confirm,
    displayCurrentContractStartDate,
    displayNextContractEndDate,
    displayNextContractStartDate,
    subscriptionUpgradeType,
  ]);

  return (
    <div>
      <Title>プラン変更のスケジュール</Title>
      <Description>{data?.description}</Description>
      <ImageWrapper>
        {data?.date}
        <Img src={data?.image} className="pc" />
        <Img src={data?.spImage} className="sp" />
      </ImageWrapper>
      {nextPlanName ? (
        <Info>※上記の図解は、あなたが本日時点で {nextPlanName} を選択した場合の例です。</Info>
      ) : (
        ''
      )}
    </div>
  );
};

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  text-align: center;

  ${media.lessThan('medium')`
    font-size: 1.375rem;
    line-height: 1.5;
  `}
`;

const Description = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;

  span {
    color: #eb0000;
    font-size: 1em;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.8;
  `}
`;

const Info = styled.p`
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;

  span {
    color: #eb0000;
    font-size: 1em;
    font-weight: 700;
  }

  ${media.lessThan('medium')`
    font-size: 0.75rem;
    line-height: 1.8;
  `}
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  max-width: 582px;
  margin: 2rem auto 0;

  ${media.lessThan('medium')`
    display: grid;
    grid-template-columns: auto 1fr;
    gap: .5rem;
    max-width: 300px;
  `}
`;

const Img = styled.img`
  display: block;
  width: 100%;

  &.sp {
    display: none;
  }

  ${media.lessThan('medium')`
    flex: 1;

    &.pc {
      display: none;
    }

    &.sp {
      display: block;
    }
  `}
`;

const Dates = styled.p<{ column: number }>`
  display: grid;
  margin-top: 0.5rem;

  span {
    display: block;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 1.125rem;
    text-align: center;
    transform: translateX(-50%);

    &:last-of-type {
      transform: translateX(50%);
    }

    br {
      display: none;
    }
  }

  ${media.lessThan('medium')`
    margin-top: 0;

    span {
      text-align: right;
      transform: none;

      &:last-of-type {
        transform: none;
      }

      br {
        display: block;
      }
    }
  `}

  ${(props) =>
    props.column === 3
      ? `
    grid-template-columns: 1fr 1fr 1fr;

    span:nth-of-type(2) {
      transform: none;
    }
  `
      : props.column === 4 &&
        `
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `}

  ${(props) =>
    props.column === 3
      ? media.lessThan('medium')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span {
      &:nth-of-type(1) {
        transform: translateY(calc(-50% + 7px));
      }

      &:nth-of-type(3) {
        transform: translateY(calc(50% - 7px));
      }
    }
  `
      : props.column === 4 &&
        media.lessThan('medium')`
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    align-items: flex-start;

    span {
      transform: translateY(calc(-50% + 7px));

      &:nth-of-type(3) {
        transform: translateY(-50%);
      }

      &:nth-of-type(4) {
        transform: translateY(calc(150% + 7px));
      }
    }
  `}
`;
