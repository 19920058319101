import React, { useCallback } from 'react';
import styled from 'styled-components';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import media from 'styled-media-query';

export interface Step2InputSata {
  troubleContent: string;
  researchContent: string;
}

export interface Step2Props {
  onChangeTroubleContent: (content: string) => void;
  onChangeResearchContent: (content: string) => void;
}

export const SpotLessonReserveStep2TextBox: React.FC<Step2Props> = ({
  onChangeTroubleContent,
  onChangeResearchContent,
}): JSX.Element => {
  const troubleContentRef = React.useRef<TextAreaHandler>(null);
  const researchContentRef = React.useRef<TextAreaHandler>(null);

  const handleChangeTroubleContent = useCallback((): void => {
    onChangeTroubleContent(troubleContentRef.current?.getValue() ?? '');
  }, [onChangeTroubleContent]);

  const handleChangeResearchContent = useCallback((): void => {
    onChangeResearchContent(researchContentRef.current?.getValue() ?? '');
  }, [onChangeResearchContent]);

  return (
    <React.Fragment>
      <InputArea>
        <Title>
          相談内容の詳細<RequiredLabel>必須</RequiredLabel>
        </Title>
        <TextArea
          name="content"
          placeholder="例：HTML/CSSで希望のデザインにできない。flexboxを用いて実装をしたいので、一緒に解決してほしい"
          ref={troubleContentRef}
          width="650px"
          height="139px"
          onKeyUp={handleChangeTroubleContent}
        />
        <SubNoticeArea>
          <SubNotice>
            相談内容はできるだけ詳細に記載することを推奨します。
            <br className="sp" />
            下記のケースに関しては、キャンセルとなる場合がございますので、ご注意ください。
          </SubNotice>
          <SubNotice>※教材で取り扱いのない言語／技術／ライブラリに関する内容が含まれる</SubNotice>
          <SubNotice>※記載の情報が不足しており、相談内容を把握できない</SubNotice>
        </SubNoticeArea>
      </InputArea>
      <InputArea>
        <Title>
          エラー内容や解決のために調べたこと・試したこと
          <RequiredLabel>必須</RequiredLabel>
        </Title>
        <TextArea
          name="content"
          placeholder="例：flexboxの教材を見直した　https://terakoya.sejuku.net/programs/4/chapters/37　"
          ref={researchContentRef}
          width="650px"
          height="139px"
          onKeyUp={handleChangeResearchContent}
        />
      </InputArea>
    </React.Fragment>
  );
};

const Title = styled.h4`
  font-weight: 700;
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.5;
`;

const InputArea = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const RequiredLabel = styled.span`
  margin-left: 0.5rem;
  padding: 0.0625rem 0.5rem;
  vertical-align: middle;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.5;
  background: #fd6258;
  color: #ffffff;
`;

const SubNoticeArea = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
  `}
`;

const SubNotice = styled.p`
  line-height: 1.5;
  font-size: 0.75rem;

  .sp {
    display: none;
  }

  ${media.lessThan('medium')`
    .sp {
      display: block;
    }
  `}
`;
