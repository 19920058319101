import React from 'react';
import defaultIcon from '../../static/image/icon_default.svg';
import styled from 'styled-components';

interface StudentProps {
  userName: string;
  icon: string;
  changeCourse: () => void;
  courseID: number;
  currentCourseID: number;
}

export const StudentData: React.FC<StudentProps> = (props) => {
  return (
    <Container
      // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
      // eslint-disable-next-line eqeqeq
      className={`${props.courseID == props.currentCourseID ? 'selected' : ''}`}
      onClick={() => props.changeCourse()}
    >
      <img src={props.icon ? props.icon : defaultIcon} alt="icon" />
      <div className="info">
        <p>
          {props.courseID}:{' '}
          {props.userName.length > 20 ? `${props.userName.substr(0, 20)}...` : props.userName}
        </p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem 1.4rem;
  cursor: pointer;

  &.selected {
    background-color: rgba(253, 60, 47, 0.05);
  }

  img {
    width: 24px;
    height: 24px;
    border-radius: 50%;
  }

  .info {
    width: calc(100% - calc(20px + 48px));
  }

  p {
    margin-left: 0.6rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
  }
`;
