import React, { useMemo, useState } from 'react';
import { CodeComponent, ReactMarkdownNames } from 'react-markdown/lib/ast-to-react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import YouTube from 'react-youtube';
import styled from 'styled-components';
import { useToastsContext } from '../context/ToastsProvider';

import { CopyBlock } from './components/codeBlock/CopyBlock';

import { defaultErrorMessage } from '../const/ErrorMessage';

export const CodeBlock: CodeComponent | ReactMarkdownNames = ({
  inline,
  className,
  children,
  ...props
}) => {
  const language = className ? className.replace(/language-/, '') : '';
  const rawCode = String(children);
  const code = rawCode.endsWith('\n') ? rawCode.slice(0, -1) : rawCode;

  const addtionalLines = useMemo((): number[] => {
    return getDifferenceCount(code, /^\+/);
  }, [code]);

  const deletionalLines = useMemo((): number[] => {
    return getDifferenceCount(code, /^-/);
  }, [code]);

  // 画像のパスだけを取得する
  const trimImagePath = (imagePath: string): string => {
    return imagePath.replace(/!\[.+\]|\(|\)/g, '');
  };

  const trimImageAlt = (imagePath: string): string => {
    return imagePath.replace(/!\[|\]\(.+\)/g, '');
  };

  // 差分部分だけマーカー表示になるようにstyleを変更
  const differenceStyle: lineTagPropsFunction = (lineNumber: number) => {
    const lineTagProps = {
      styles: {
        display: 'block',
        backgroundColor: '',
      },
      class: [] as string[],
    };
    switch (true) {
      case addtionalLines.includes(lineNumber):
        lineTagProps.styles.backgroundColor = 'rgba(46,160,67,0.24)';
        lineTagProps.class = ['add'];
        break;
      case deletionalLines.includes(lineNumber):
        lineTagProps.styles.backgroundColor = 'rgba(248,81,73,0.24)';
        lineTagProps.class = ['remove'];
        break;
    }

    // classではなくclassNameを指定しなければならないはずだが、それだと機能しない。
    // また、文字列を指定すると機能しない場合があるため配列で指定する。
    return { style: lineTagProps.styles, class: lineTagProps.class };
  };

  const { isHover, setIsHover, isCopied, setIsCopied, clickCopy } = useCopyBlock(
    language?.includes('diff') ? trimDifference(code) : code,
  );

  return inline ? (
    <code className={className} {...props}>
      {children}
    </code>
  ) : (
    <div>
      {language === 'youtube' ? (
        <VideoArea>
          <YouTube videoId={code} />
        </VideoArea>
      ) : language === 'vimeo' ? (
        <VideoArea>
          <iframe
            src={code}
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          />
        </VideoArea>
      ) : language === 'google_slide' ? (
        <GoogleSlideArea>
          <iframe src={code} allowFullScreen />
        </GoogleSlideArea>
      ) : language === 'border_image' ? (
        <ImageArea>
          <img src={trimImagePath(code)} alt={trimImageAlt(code)} />
        </ImageArea>
      ) : (
        <HighlightWrapper
          onMouseEnter={() => {
            setIsHover(true);
            setIsCopied(false);
          }}
          onMouseLeave={() => {
            setIsHover(false);
            setIsCopied(false);
          }}
        >
          <StyledSyntaxHighlighter
            PreTag="div"
            language={language?.includes('diff') ? getDifferenceLang(language) : language}
            style={okaidia}
            codeTagProps={{ style: { width: '100%' } }}
            wrapLines={true}
            showLineNumbers={true}
            lineNumberStyle={{ minWidth: `${code.split('\n').length.toString().length + 2}ch` }}
            lineProps={language?.includes('diff') ? differenceStyle : undefined}
          >
            {language?.includes('diff') ? trimDifference(code) : code}
          </StyledSyntaxHighlighter>
          <CopyBlock isCopied={isCopied} display={isHover} onClick={clickCopy} />
        </HighlightWrapper>
      )}
    </div>
  );
};

// 先頭の差分表記(+と-)をトリミング
export const trimDifference = (content: string): string => {
  const splitContents = content.split('\n');

  const isAllLineStartWithPlusOrMinus = splitContents.every(
    (value, i) =>
      value.startsWith('+') ||
      value.startsWith('-') ||
      (value === '' && i === splitContents.length - 1),
  );

  return splitContents
    .map((value) => value.replace(isAllLineStartWithPlusOrMinus ? /^[+-] ?/ : /^[+-]/, ''))
    .join('\n');
};

// 差分表記に言語が指定されている場合はその言語をシンタックスハイライトさせる
const getDifferenceLang = (lang: string) => {
  let result = '';
  if (lang.includes(':')) {
    result = lang.replace(/diff:/, '');
  }

  if (result !== '') {
    return result;
  }
  return 'diff';
};

// 差分表記(+と-)の数をカウント
const getDifferenceCount = (value: string, regexp: RegExp): number[] => {
  return value.split('\n').reduce<number[]>((acc, cur, i) => {
    return cur.match(regexp) ? [...acc, i + 1] : acc;
  }, []);
};

const useCopyBlock = (code: string) => {
  const [isHover, setIsHover] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { showToast } = useToastsContext();
  const clickCopy = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    navigator.clipboard
      .writeText(code)
      .then(() => {
        setIsCopied(true);
      })
      .catch(() => {
        showToast(1, defaultErrorMessage);
      });
  };
  return { isHover, setIsHover, isCopied, setIsCopied, clickCopy };
};

const HighlightWrapper = styled.div`
  position: relative;
`;
const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  .add .linenumber,
  .remove .linenumber {
    position: relative;

    &:after {
      height: 1rem;
      margin: auto;
      color: #fff;
      line-height: 1rem;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0.125rem;
    }
  }
  .add .linenumber:after {
    content: '+';
  }
  .remove .linenumber:after {
    content: '-';
  }
`;

const VideoArea = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const ImageArea = styled.div`
  display: inline-block;
  border: 2px solid lightgray;
  margin: 8px;
  img {
    vertical-align: bottom;
  }
`;

const GoogleSlideArea = styled.div`
  position: relative;
  overflow: hidden;
  margin: 15px 0 20px 0;
  padding-bottom: 50%;
  padding-top: 65px;
  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
