import React, { useEffect } from 'react';

interface CvMoshimoProps {
  userID: number;
}

const tagId = 'msmaf';

export const CvMoshimo: React.FC<CvMoshimoProps> = (props) => {
  // こちらのタスクで依頼 https://samurai-7s.backlog.com/view/LMS-1326
  // タグ一覧(https://docs.google.com/spreadsheets/d/1zE7MSsf4DhM8NpvbQBElisoON9-Sm5Ldw5CVkprGGUY/edit#gid=2139083905)

  useEffect(() => {
    const h = document.getElementsByTagName('head')[0];

    const script = document.createElement('script');
    script.id = tagId;
    script.src = `https://r.moshimo.com/af/r/result.js?p_id=3614&pc_id=8762&m_v=${props.userID}`;
    h.appendChild(script);

    return () => {
      document.getElementById(tagId)?.remove();
    };
  }, [props.userID]);

  return null;
};
