import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { defaultBreakpoints } from 'styled-media-query';
import media from 'styled-media-query';
import { Checkbox, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import 'react-datepicker/dist/react-datepicker.css';

import { DateInput } from '../atoms/DateInput';
import { Button } from '../atoms/Button';
import { TextArea, TextAreaHandler } from '../atoms/TextArea';
import { Modal } from '../molecules/Modal';
import { ScheduledStudyType } from '../pages/common/Registered';
import {
  NewRegisteredQuestionnaireInput,
  RechargeRegisteredQuestionnaireInput,
  RegisteredQuestionnaireAttractiveType,
  RegisteredQuestionnairePurpose,
  RegisteredQuestionnaireTrigger,
} from '../../gen/graphql';
import { ConvertLocalToUTCDate } from '../../utils/DateUTC';
import { SCHEDULED_STUDY_TYPE_OTHERS } from '../../const';
import { IsValidBirthday } from '../../utils/DateValidation';
import { ErrorText } from '../atoms/ErrorText';

const Purposes = {
  [RegisteredQuestionnairePurpose.JobChange]: 'エンジニア転職',
  [RegisteredQuestionnairePurpose.SideBusiness]: 'フリーランス・副業',
  [RegisteredQuestionnairePurpose.Development]: 'サービス開発',
  [RegisteredQuestionnairePurpose.Education]: '教養として',
  [RegisteredQuestionnairePurpose.Efficiency]: '仕事の業務効率化',
  [RegisteredQuestionnairePurpose.Others]: 'その他',
} as const;

const AttractiveTypes = {
  [RegisteredQuestionnaireAttractiveType.HtmlCssProgram]: 'HTML/CSS教材',
  [RegisteredQuestionnaireAttractiveType.JavaScriptProgram]: 'JavaScript教材',
  [RegisteredQuestionnaireAttractiveType.PhpRubyProgram]: 'PHP教材',
  [RegisteredQuestionnaireAttractiveType.PythonProgram]: 'Python教材',
  [RegisteredQuestionnaireAttractiveType.AwsProgram]: 'AWS教材',
  [RegisteredQuestionnaireAttractiveType.OthersProgram]: 'その他の教材',
  [RegisteredQuestionnaireAttractiveType.Qa]: 'いつでも質問できるQ&A掲示板',
  [RegisteredQuestionnaireAttractiveType.Lesson]: '現役エンジニアに直接相談できるレッスン',
  [RegisteredQuestionnaireAttractiveType.Environment]: '一緒に学べる仲間がいる環境',
  [RegisteredQuestionnaireAttractiveType.Others]: 'それ以外の機能',
} as const;

const Triggers = {
  [RegisteredQuestionnaireTrigger.AddNewFunction]: '新しい機能が追加されたから',
  [RegisteredQuestionnaireTrigger.AddNewProgram]: '新しい教材が追加されたから',
  [RegisteredQuestionnaireTrigger.GotMoreTime]:
    '時間がなくてやめたが勉強する時間が取れるようになったから',
  [RegisteredQuestionnaireTrigger.AskAQuestion]: '質問したいことが出来たから',
  [RegisteredQuestionnaireTrigger.BetterThanOther]:
    '他サービスを試したが侍テラコヤのほうが良かったから',
  [RegisteredQuestionnaireTrigger.Campaign]: 'キャンペーンで見かけたから',
  [RegisteredQuestionnaireTrigger.Others]: 'その他',
} as const;

// 改行位置やスペースに注意
const purposeOthersPlaceholder = `例 Webアプリ開発を行いたい / 案件で必要なスキルを取得したい / 資格取得のため 
`;

// 改行位置やスペースに注意
const attractiveOthersPlaceholder = `例 日々の学習を記録、見返す事ができるログ / Wordpressの教材 / 転職・案件獲得の相談
     初心者向けスキルの教材
`;

// 改行位置やスペースに注意
const scheduleStudyOthersPlaceholder = `例 新しいスキル・資格を取得する必要ができた。/ しばらく学習から離れて忘れかけたから。 
     初心者向けスキルからのステップアップとして。
`;

// 改行位置やスペースに注意
const triggerOthersPlaceholder = `例 新しいスキル・資格を取得する必要ができた。/ しばらく学習から離れて忘れかけたから。
     初心者向けスキルからのステップアップとして。
`;

interface CommonProps {
  isOpen: boolean;
  scheduledStudyTypes: ScheduledStudyType[];
  loading?: boolean;
  onClose: () => void;
}

interface NewProps {
  onNewSubmit: (input: NewRegisteredQuestionnaireInput) => void;
  onRechargeSubmit?: undefined;
}

interface RechargeProps {
  onNewSubmit?: undefined;
  onRechargeSubmit: (input: RechargeRegisteredQuestionnaireInput) => void;
}

type Props = CommonProps & (NewProps | RechargeProps);

export const RegisteredQuestionnaireModal: React.FC<Props> = (props): JSX.Element => {
  const [inputDate, setInputDate] = useState<string>('');
  const [isValidDate, setIsValidDate] = useState<boolean>(false);
  const [dateErrorText, setDateErrorText] = useState<string>('');
  const [purpose, setPurpose] = useState<RegisteredQuestionnairePurpose | null>(null);
  const [attractiveTypes, setAttractiveTypes] = useState<RegisteredQuestionnaireAttractiveType[]>(
    [],
  );
  const [selectedScheduledStudyTypes, setSelectedScheduledStudyTypes] = useState<number[]>([]);
  const [trigger, setTrigger] = useState<RegisteredQuestionnaireTrigger | null>(null);
  const purposeOthersRef = useRef<TextAreaHandler>(null);
  const attractiveTypeOthersRef = useRef<TextAreaHandler>(null);
  const scheduledStudyTypeOthersRef = useRef<TextAreaHandler>(null);
  const triggerOthersRef = useRef<TextAreaHandler>(null);

  const disabledSubmit = () => {
    if (props.onRechargeSubmit) {
      return (
        !isValidDate ||
        purpose === null ||
        attractiveTypes.length === 0 ||
        selectedScheduledStudyTypes.length === 0 ||
        trigger === null
      );
    } else {
      return (
        !isValidDate ||
        purpose === null ||
        attractiveTypes.length === 0 ||
        selectedScheduledStudyTypes.length === 0
      );
    }
  };

  const changeDate = (value: string): void => {
    setInputDate(value);
    const isValid = IsValidBirthday(value);
    setIsValidDate(isValid.result);
    setDateErrorText(isValid.error ?? '');
  };

  const changePurpose = useCallback(
    (value: string): void => {
      const beforeValue = purpose;
      const afterValue = Object.values(RegisteredQuestionnairePurpose).find(
        (purpose) => purpose === value,
      );
      setPurpose(afterValue ?? null);

      if (
        beforeValue === RegisteredQuestionnairePurpose.Others &&
        !!purposeOthersRef.current?.getValue()
      ) {
        purposeOthersRef.current?.setValue('');
      }
    },
    [purpose],
  );

  const changeAttractiveTypes = useCallback(
    (value: string, checked: boolean): void => {
      const inputValue = Object.values(RegisteredQuestionnaireAttractiveType).find(
        (attractiveType) => attractiveType === value,
      );
      if (!inputValue) {
        return;
      }

      if (checked) {
        setAttractiveTypes([...attractiveTypes, inputValue]);
      } else {
        setAttractiveTypes(attractiveTypes.filter((value) => value !== inputValue));
        if (
          inputValue === RegisteredQuestionnaireAttractiveType.Others &&
          !!attractiveTypeOthersRef.current?.getValue()
        ) {
          attractiveTypeOthersRef.current?.setValue('');
        }
      }
    },
    [attractiveTypes],
  );

  const changeSelectedScheduledStudyTypes = useCallback(
    (value: number, checked: boolean): void => {
      if (checked) {
        setSelectedScheduledStudyTypes([...selectedScheduledStudyTypes, value]);
      } else {
        setSelectedScheduledStudyTypes(
          selectedScheduledStudyTypes.filter(
            (selectedScheduledStudyType) => selectedScheduledStudyType !== value,
          ),
        );
        if (
          value === SCHEDULED_STUDY_TYPE_OTHERS.value &&
          !!scheduledStudyTypeOthersRef.current?.getValue()
        ) {
          scheduledStudyTypeOthersRef.current?.setValue('');
        }
      }
    },
    [selectedScheduledStudyTypes],
  );

  const changeTrigger = useCallback(
    (value: string): void => {
      const beforeValue = trigger;
      const afterValue = Object.values(RegisteredQuestionnaireTrigger).find(
        (trigger) => trigger === value,
      );
      setTrigger(afterValue ?? null);

      if (
        beforeValue === RegisteredQuestionnaireTrigger.Others &&
        !!triggerOthersRef.current?.getValue()
      ) {
        triggerOthersRef.current?.setValue('');
      }
    },
    [trigger],
  );

  const handleSubmit = useCallback((): void => {
    const typedInputDate = new Date(inputDate);
    const formatedDate = ConvertLocalToUTCDate(typedInputDate);

    if (props.onRechargeSubmit) {
      if (!isValidDate || purpose === null || attractiveTypes.length === 0 || trigger === null)
        return;

      props.onRechargeSubmit({
        birthday: formatedDate.toISOString(),
        purpose,
        purposeOthers: purposeOthersRef.current?.getValue().trim(),
        attractiveTypes,
        attractiveTypeOthers: attractiveTypeOthersRef.current?.getValue().trim(),
        scheduledStudyTypes: selectedScheduledStudyTypes.filter(
          (selectedScheduledStudyType) =>
            selectedScheduledStudyType !== SCHEDULED_STUDY_TYPE_OTHERS.value,
        ),
        scheduledStudyTypeOthers: scheduledStudyTypeOthersRef.current?.getValue().trim(),
        trigger,
        triggerOthers: triggerOthersRef.current?.getValue().trim(),
      });
    } else {
      if (!isValidDate || purpose === null || attractiveTypes.length === 0) return;

      props.onNewSubmit({
        birthday: formatedDate.toISOString(),
        purpose,
        purposeOthers: purposeOthersRef.current?.getValue().trim(),
        attractiveTypes,
        attractiveTypeOthers: attractiveTypeOthersRef.current?.getValue().trim(),
        scheduledStudyTypes: selectedScheduledStudyTypes.filter(
          (selectedScheduledStudyType) =>
            selectedScheduledStudyType !== SCHEDULED_STUDY_TYPE_OTHERS.value,
        ),
        scheduledStudyTypeOthers: scheduledStudyTypeOthersRef.current?.getValue().trim(),
      });
    }

    props.onClose();
  }, [
    props,
    isValidDate,
    inputDate,
    purpose,
    attractiveTypes,
    selectedScheduledStudyTypes,
    trigger,
  ]);

  useEffect(() => {
    if (!props.isOpen) {
      setInputDate('');
      setPurpose(null);
      setAttractiveTypes([]);
      setTrigger(null);
      purposeOthersRef.current?.setValue('');
      attractiveTypeOthersRef.current?.setValue('');
      triggerOthersRef.current?.setValue('');
    }
  }, [props.isOpen]);

  return (
    <Modal
      underlayer
      isOpen={props.isOpen}
      onClose={() => void 0}
      hideHeaderClose={true}
      loading={props.loading}
      width={'50rem'}
      header={<Title>アンケート</Title>}
      footer={
        <ButtonWrapper>
          <Button onClick={handleSubmit} disabled={disabledSubmit()}>
            アンケートに回答して利用する
          </Button>
        </ButtonWrapper>
      }
    >
      <Container>
        <QuestionUnit>
          <LabelUnit>
            <Required>必須</Required>
            <Label>1 生年月日を教えてください。</Label>
          </LabelUnit>
          <DateInput onChange={changeDate} placeholder="1992/01/16" />
          {dateErrorText && <StyledErrorText>{dateErrorText}</StyledErrorText>}
        </QuestionUnit>
        <QuestionUnit>
          <LabelUnit>
            <Required>必須</Required>
            <Label>2 侍テラコヤに登録いただいた一番の目的を教えて下さい。</Label>
          </LabelUnit>
          <StyledRadioGroup value={purpose} onChange={(_, value) => changePurpose(value)}>
            {Object.entries(Purposes).map(([value, label]) => (
              <StyledFormControlLabel key={value} control={<Radio />} label={label} value={value} />
            ))}
          </StyledRadioGroup>
          {purpose === RegisteredQuestionnairePurpose.Others && (
            <React.Fragment>
              <Note>その他を選択の際は詳細を下記にご記入ください。</Note>
              <StyledTextArea
                name="purposeOthers"
                height="8.75rem"
                placeholder={purposeOthersPlaceholder}
                ref={purposeOthersRef}
              />
            </React.Fragment>
          )}
        </QuestionUnit>

        <QuestionUnit>
          <LabelUnit>
            <Required>必須</Required>
            <Label>
              3 どんな機能に魅力を感じましたか？<Supplement>※複数選択可</Supplement>
            </Label>
          </LabelUnit>
          <CheckboxGroup>
            {Object.entries(AttractiveTypes).map(([value, label]) => (
              <StyledFormControlLabel
                key={value}
                control={<Checkbox />}
                label={label}
                value={value}
                checked={!!attractiveTypes.find((attractiveType) => attractiveType === value)}
                onChange={(_, checked) => changeAttractiveTypes(value, checked)}
              />
            ))}
          </CheckboxGroup>
          {!!attractiveTypes.find(
            (attractiveType) => attractiveType === RegisteredQuestionnaireAttractiveType.Others,
          ) && (
            <React.Fragment>
              <Note>その他を選択の際は詳細を下記にご記入ください。</Note>
              <StyledTextArea
                name="attractiveOthers"
                height="8.75rem"
                placeholder={attractiveOthersPlaceholder}
                ref={attractiveTypeOthersRef}
              />
            </React.Fragment>
          )}
        </QuestionUnit>

        <QuestionUnit>
          <LabelUnit>
            <Required>必須</Required>
            <Label>
              4 どの言語・技術を学習予定ですか？<Supplement>※複数選択可</Supplement>
            </Label>
          </LabelUnit>
          <CheckboxGroupWrapper>
            <CheckboxGroup>
              {props.scheduledStudyTypes.map(
                ({ value, label }, index) =>
                  index < props.scheduledStudyTypes.length / 2 && (
                    <StyledFormControlLabel
                      key={value}
                      control={<Checkbox />}
                      label={label}
                      value={value}
                      checked={
                        selectedScheduledStudyTypes.find(
                          (selectedScheduledStudyType) => selectedScheduledStudyType === value,
                          // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                          // eslint-disable-next-line eqeqeq
                        ) != null
                      }
                      onChange={(_, checked) => changeSelectedScheduledStudyTypes(value, checked)}
                    />
                  ),
              )}
            </CheckboxGroup>
            <CheckboxGroup>
              {props.scheduledStudyTypes.map(
                ({ value, label }, index) =>
                  index >= props.scheduledStudyTypes.length / 2 && (
                    <StyledFormControlLabel
                      key={value}
                      control={<Checkbox />}
                      label={label}
                      value={value}
                      checked={
                        selectedScheduledStudyTypes.find(
                          (selectedScheduledStudyType) => selectedScheduledStudyType === value,
                          // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                          // eslint-disable-next-line eqeqeq
                        ) != null
                      }
                      onChange={(_, checked) => changeSelectedScheduledStudyTypes(value, checked)}
                    />
                  ),
              )}
            </CheckboxGroup>
          </CheckboxGroupWrapper>
          {selectedScheduledStudyTypes.find(
            (selectedScheduledStudyType) =>
              selectedScheduledStudyType === SCHEDULED_STUDY_TYPE_OTHERS.value,
            // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
            // eslint-disable-next-line eqeqeq
          ) != null && (
            <React.Fragment>
              <Note>その他を選択の際は詳細を下記にご記入ください。</Note>
              <StyledTextArea
                name="scheduledStudyOthers"
                height="8.75rem"
                placeholder={scheduleStudyOthersPlaceholder}
                ref={scheduledStudyTypeOthersRef}
              />
            </React.Fragment>
          )}
        </QuestionUnit>

        {props.onRechargeSubmit && (
          <QuestionUnit>
            <LabelUnit>
              <Required>必須</Required>
              <Label>5 再度侍テラコヤに登録していただいたきっかけは何ですか？</Label>
            </LabelUnit>
            <StyledRadioGroup value={trigger} onChange={(_, value) => changeTrigger(value)}>
              {Object.entries(Triggers).map(([value, label]) => (
                <StyledFormControlLabel
                  key={value}
                  control={<Radio />}
                  label={label}
                  value={value}
                />
              ))}
            </StyledRadioGroup>
            {trigger === RegisteredQuestionnaireTrigger.Others && (
              <React.Fragment>
                <Note>その他を選択の際は詳細を下記にご記入ください。</Note>
                <StyledTextArea
                  name="triggerOthers"
                  height="8.75rem"
                  placeholder={triggerOthersPlaceholder}
                  ref={triggerOthersRef}
                />
              </React.Fragment>
            )}
          </QuestionUnit>
        )}
      </Container>
    </Modal>
  );
};

const Title = styled.h2`
  font-size: 1.125rem;
  font-weight: 700;
  margin: 0 auto;
`;

const ButtonWrapper = styled.div`
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.25rem 2rem 0.75rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1.4rem;
  `}
`;

const QuestionUnit = styled.div`
  width: 100%;
`;

const LabelUnit = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 0 auto 1rem;
`;

const Required = styled.span`
  display: block;
  margin-right: 0.5rem;
  padding: 0.125rem 0.5rem;
  background-color: #fd6258;
  color: #fff;
  font-size: 0.625rem;
  font-weight: 500;
  line-height: 1.2;
  min-width: 2.25rem;
  height: 1rem;
  box-sizing: border-box;
`;
const Label = styled.label`
  font-size: 1rem;
  font-weight: 700;
`;
const Supplement = styled.span`
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.36);
  font-size: 0.75rem;
  font-weight: 400;
`;

const Note = styled.div`
  display: flex;
  margin: 1rem auto;
  font-size: 0.875rem;
  font-weight: 500;
  }
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
  flex-direction: row !important;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  & > .MuiRadio-root,
  .MuiCheckbox-root {
    padding: 0.375rem;
  }
`;

const StyledTextArea = styled(TextArea)`
  padding: 0.625rem 1rem;
  line-height: 1.25;
  &::placeholder {
    color: rgba(0, 0, 0, 0.36);
  }
`;

const CheckboxGroupWrapper = styled.div`
  display: flex;
  align-items: start;
  > div {
    &:first-child {
      margin-right: 1rem;
    }
  }
  @media (max-width: ${defaultBreakpoints.medium}) {
    display: block;
    > div {
      &:first-child {
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-flow: column;
`;

const StyledErrorText = styled(ErrorText)`
  margin-top: 0.5rem;
`;
