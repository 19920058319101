import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { StylesProvider } from '@material-ui/styles';
import { addDays, format, isBefore, isToday, parseISO, setHours, subDays } from 'date-fns';
import ja from 'date-fns/locale/ja';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import styled from 'styled-components';
import media from 'styled-media-query';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { pipe } from 'fp-ts/function';
import {
  jstToUtc,
  setClearMilliseconds,
  setClearMinutes,
  setClearSeconds,
  utcToJst,
} from '../../utils/DateFnsSupport';

import { isEmpty, prepend, range } from 'fp-ts/Array';
import { MenuContext } from '../../context/MenuProvider';
import { TagFragment } from '../../gen/graphql';
import { NonEmptyArray } from 'fp-ts/NonEmptyArray';
import isWithinRangeDate from '../../utils/DateRange';
import { TIME_ROWS } from '../../const/SpotLesson';

import Circle from '../../static/image/circle.svg';
import Cross from '../../static/image/cross.svg';
import DoneImg from '../../static/image/done.svg';
import { useGetSpotLessonCalendarsQuery, TimeBlockFragment as TimeBlock } from '../../gen/graphql';

export interface Step1TableProps {
  selectedTags: TagFragment[];
  activeNoChooseTag: boolean;
  targetDate: Date | null;
  onChange: (targetDate: Date) => void;
  setLoading: (isLoading: boolean) => void;
}

export const SpotLessonReserveStep1Table: React.FC<Step1TableProps> = (props) => {
  interface SelectDatePoint {
    x: number;
    y: number;
  }

  interface TempSelectDatePoint {
    date: Date | null;
    x: number;
    y: number;
  }

  const ScheduleType = {
    enable: '○',
    disable: '×',
  } as const;

  const initDate = (date: Date) =>
    pipe(setClearMinutes(date), setClearSeconds, setClearMilliseconds);
  const [startDateStep1, setStartDateStep1] = useState(initDate(addDays(utcToJst(new Date()), 0)));
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const [selectedDateIndexStep1, setSelectedDateIndexStep1] = useState<SelectDatePoint>({
    x: -1,
    y: -1,
  });
  const [tempSelectedDateIndexStep1, setTempSelectedDateIndexStep1] = useState<TempSelectDatePoint>(
    { date: null, x: -1, y: -1 },
  );
  const { toggleClose, toggleHoverClose } = useContext(MenuContext);

  const disableCondition = (e: TimeBlock, now: Date) =>
    e.disable || e.slotCount <= e.reservationCount || isBefore(parseISO(e.startAt), now);

  const { data } = useGetSpotLessonCalendarsQuery({
    variables: {
      from: jstToUtc(startDateStep1).toISOString(),
      to: jstToUtc(addDays(startDateStep1, 6)).toISOString(),
      tagIDs:
        !isEmpty(props.selectedTags) || props.activeNoChooseTag
          ? props.selectedTags.map((c) => (c.id ? c.id : 0))
          : [0], // NOTE: 言語未選択でもカレンダーは作りたいので、存在しない言語IDを渡して選択不可のデータを取得する
    },
  });
  const spotLessonCalendars = data?.spotLessonCalendars ?? [];
  const calendarList: string[][] = pipe(
    spotLessonCalendars.map((c) =>
      c.timeBlocks.map((e) =>
        disableCondition(e, new Date()) ? ScheduleType.disable : ScheduleType.enable,
      ),
    ),
    prepend(TIME_ROWS),
    (a: NonEmptyArray<string[]>) => a[0].map((_, c) => a.map((r) => r[c])),
  );

  const resizeListener = () => {
    setInnerWidth(() => {
      return window.innerWidth;
    });
    if (innerWidth > 767) {
      return;
    }
    toggleClose();
    toggleHoverClose();
  };

  const displayDateStep1 = (date: Date) => {
    if (innerWidth <= 1078) {
      return (
        <Box>
          <DisplayDateDayOfTheWeek>{format(date, 'iii', { locale: ja })}</DisplayDateDayOfTheWeek>
          <DisplayDateDay>{format(date, 'd')}</DisplayDateDay>
        </Box>
      );
    } else {
      return <div>{format(date, 'MM/dd') + '(' + format(date, 'iii', { locale: ja }) + ')'}</div>;
    }
  };

  const getTableRowDisplay = (row: string) =>
    row === ScheduleType.enable ? (
      <div>
        <img src={Circle} />
      </div>
    ) : (
      <div>
        <img src={Cross} />
      </div>
    );

  const tableCellClick = (element: string, index: number, timeIndex: number) => {
    if (element !== ScheduleType.enable) return;
    //複数ある関数をpipeで関数合成。
    pipe(
      addDays(startDateStep1, index - 1),
      (targetDate) => setHours(targetDate, parseInt(TIME_ROWS[timeIndex].split(':')[0])),
      props.onChange,
      () => setSelectedDateIndexStep1({ x: timeIndex, y: index }), //前のpipeの値がvoidの場合は、引数を受け取れないのでポイントフリースタイルは使えない。
    );
  };

  const getTableContent = (rows: string[][]) =>
    rows.map((row, timeIndex) => (
      <StyledTableRow key={timeIndex}>
        {row.map((element, index) => {
          if (index === 0) {
            return <StyledTableCell key={index}>{element}</StyledTableCell>;
          } else if (selectedDateIndexStep1.x === timeIndex && selectedDateIndexStep1.y === index) {
            return (
              <ClickedTableCell key={index}>
                <img src={DoneImg} />
                {innerWidth <= 1078 ? '' : ' 選択中'}
              </ClickedTableCell>
            );
          } else {
            return (
              <StyledTableCell
                key={index}
                className={ScheduleType.enable === element ? 'clickable' : ''}
                onClick={() => {
                  tableCellClick(element, index, timeIndex);
                }}
              >
                {getTableRowDisplay(element)}
              </StyledTableCell>
            );
          }
        })}
      </StyledTableRow>
    ));

  const getTableHeaderDateRow = () =>
    range(0, 6).map((index) => (
      <StyledTableCell key={index}>
        {displayDateStep1(addDays(startDateStep1, index))}
      </StyledTableCell>
    ));

  const isWithinRangeDateCheck = () =>
    isWithinRangeDate(tempSelectedDateIndexStep1.date, startDateStep1, addDays(startDateStep1, 7));

  useEffect(() => {
    // fetchCalendars();
    if (isWithinRangeDateCheck()) {
      setSelectedDateIndexStep1({
        x: tempSelectedDateIndexStep1.x,
        y: tempSelectedDateIndexStep1.y,
      });
      setTempSelectedDateIndexStep1({ date: null, x: -1, y: -1 });
    }
    window.addEventListener('resize', resizeListener);
    return () => window.removeEventListener('resize', resizeListener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateStep1, selectedDateIndexStep1, props.selectedTags, props.targetDate]);

  const clearTargetDate = useCallback((): void => {
    if (selectedDateIndexStep1.x !== -1 && selectedDateIndexStep1.y !== -1) {
      setTempSelectedDateIndexStep1({
        date: props.targetDate,
        x: selectedDateIndexStep1.x,
        y: selectedDateIndexStep1.y,
      });
    }
    setSelectedDateIndexStep1({ x: -1, y: -1 });
  }, [props.targetDate, selectedDateIndexStep1.x, selectedDateIndexStep1.y]);

  React.useEffect(() => {
    if (props.targetDate === null) {
      // 言語を変更した場合は選択中の日付のクリアをしているので、連動してカレンダーの状態もクリア(複数状態を持っているっぽい)
      clearTargetDate();
    }
  }, [props.targetDate, clearTargetDate]);

  return (
    <StylesProvider injectFirst>
      {/*//https://material-ui.com/system/spacing/*/}
      <WeekSelectButtonGroupBox>
        <Box display="inline" mr={1}>
          <WeekSelectButton
            variant="outlined"
            color="secondary"
            disabled={isToday(subDays(startDateStep1, 1))}
            onClick={() => {
              setStartDateStep1(subDays(startDateStep1, 7));
              clearTargetDate();
            }}
          >
            {'< 前週'}
          </WeekSelectButton>
        </Box>
        <Box display={{ xs: 'none', sm: 'block' }} mx={0.5}>
          <WeekSelectButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              setStartDateStep1(addDays(startDateStep1, 7));
              clearTargetDate();
            }}
          >
            {'翌週 >'}
          </WeekSelectButton>
        </Box>
        <MonthLabel>{format(startDateStep1, 'yyyy年MM月', { locale: ja })}</MonthLabel>
        <Box display={{ xs: 'block', sm: 'none' }}>
          <WeekSelectButton
            variant="outlined"
            color="secondary"
            onClick={() => {
              setStartDateStep1(addDays(startDateStep1, 7));
              clearTargetDate();
            }}
          >
            {'翌週 >'}
          </WeekSelectButton>
        </Box>
      </WeekSelectButtonGroupBox>
      <StyledPaper>
        <Grid item xs={12}>
          <Table style={{ tableLayout: 'auto' }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell></StyledTableCell>
                {getTableHeaderDateRow()}
              </TableRow>
            </TableHead>
            <StyledTableBody>{getTableContent(calendarList)}</StyledTableBody>
          </Table>
        </Grid>
      </StyledPaper>
      <SubNoticeArea>
        <SubNotice>※受講のためのURLは予約完了後にメールでご案内します。</SubNotice>
      </SubNoticeArea>
    </StylesProvider>
  );
};

const MonthLabel = styled.div`
  text-align: center;
  font-weight: bold;
  left: 0;
  right: 0;
  margin: auto;
`;
const SubNoticeArea = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    margin-bottom: 1rem;
  `}
`;

const SubNotice = styled.p<{ color?: string }>`
  line-height: 1.8;
  font-size: 0.75rem;
  ${(props) => props.color && `color: ${props.color}`}
`;

const DisplayDateDayOfTheWeek = styled.div`
  color: rgba(0, 0, 0, 0.36);
`;

const DisplayDateDay = styled.div`
  color: rgba(0, 0, 0, 0.87);
`;
//MaterialUI

// https://material-ui.com/components/box/
// https://material-ui.com/system/basics/#all-inclusive
const WeekSelectButtonGroupBox = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    alignItems: 'baseline',
  },
})(Box);

// https://material-ui.com/api/button/
// https://material-ui.com/components/buttons/
const WeekSelectButton = withStyles({
  outlinedSecondary: {
    width: 80,
  },
})(Button);

const StyledPaper = styled(Paper)`
  overflow-x: auto;
`;

// https://material-ui.com/components/tables/
// https://material-ui.com/api/table-cell/
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F4F6F8',
    color: theme.palette.common.black,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderStyle: 'solid',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  body: {
    fontSize: 14,
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderStyle: 'solid',
    textAlign: 'center',

    '&.clickable': {
      cursor: 'pointer',
    },
  },
  clickable: {
    cursor: 'pointer',
  },
}))(TableCell);

// https://material-ui.com/components/tables/
// https://material-ui.com/api/table-cell/
const ClickedTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#e2001b',
    color: theme.palette.common.white,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    borderStyle: 'solid',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    borderRight: '1px solid rgba(0, 0, 0, 0.1)',
    borderLeft: '1px solid rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  body: {
    color: '#FFFFFF',
    backgroundColor: '#e2001b',
    fontSize: 14,
    textAlign: 'center',
    verticalAlign: 'top',
  },
}))(TableCell);

// https://material-ui.com/components/tables/
// https://material-ui.com/api/table-row/
const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableBody = styled(TableBody)`
  img {
    vertical-align: middle;
  }
`;
