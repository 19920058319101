import styled from 'styled-components';
import media from 'styled-media-query';

import { Box } from '../atoms/Box';
import { PracticeSubmitReviewStatusIcon } from '../atoms/PracticeSubmitReviewStatusIcon';

import TimeIcon from '../../static/image/icon_time.svg';
import DifficultyIcon from '../../static/image/icon_difficulty.svg';

import { PracticeSubmitDetailReviewStatus } from '../../gen/graphql';
import { PracticeDifficultyLables } from '../../const/Practice';

interface Props {
  title: string;
  description: string;
  requireTime?: number;
  difficulty: string;
  submitPassedCount?: number;
  submitReviewStatus?: PracticeSubmitDetailReviewStatus;
  isExplanationTitle?: boolean;
  className?: string;
}

export const PracticeTitle: React.FC<Props> = ({
  title,
  description,
  requireTime,
  difficulty,
  submitPassedCount,
  submitReviewStatus,
  isExplanationTitle,
  className,
}) => (
  <Box padding={[12, 8]} spPadding={[8, 4, 6]} className={className}>
    <Label>課題</Label>
    <Title>{title}</Title>
    <Description>{description}</Description>
    {!isExplanationTitle && (
      <Info>
        <InfoText>
          <img src={TimeIcon} alt="" />
          <p>
            <strong>{requireTime}</strong>
            <span>分</span>
          </p>
        </InfoText>
        <InfoText>
          <img src={DifficultyIcon} alt="" />
          <p>
            <strong>{PracticeDifficultyLables[difficulty]}</strong>
          </p>
        </InfoText>
        <InfoText>
          <p>
            <strong>{submitPassedCount}</strong>
            <span>人が合格</span>
          </p>
        </InfoText>
        <PracticeSubmitReviewStatusIcon reviewStatus={submitReviewStatus} />
      </Info>
    )}
  </Box>
);

const Label = styled.p`
  color: #eb0000;
  font-size: 0.875rem;
  font-weight: 700;
`;
const Title = styled.h1`
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.87);
  font-size: 2.25rem;
  font-weight: 700;

  ${media.lessThan('medium')`
    line-height: 2.125rem;
    font-size: 1.5rem;
  `}
`;
const Description = styled.p`
  line-height: 1.5em;
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
`;
const Info = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.75rem;
`;
const InfoInner = styled.div`
  display: flex;
  align-items: center;
  gap: 0.125rem;
`;
const InfoText = styled(InfoInner)`
  img {
    display: block;
    width: 1rem;
    height: auto;
  }

  p {
    line-height: 1em;
    font-size: 1.125rem;

    strong {
      font-weight: 700;
    }

    span {
      line-height: 1em;
      font-size: 1rem;
      font-weight: 700;
    }
  }

  ${media.lessThan('medium')`
    img {
      width: .875rem;
    }

    p {
      font-size: .875rem;
    }
  `}
`;
