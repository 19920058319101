import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'fp-ts/Array';

import { Button } from '../atoms/Button';
import { CircularIcon } from '../atoms/CircularIcon';
import { Tag } from '../atoms/Tag';
import { StudySummary } from './StudySummary';
import { StudyTagSummary } from './StudyTagSummary';
import { UserProfileSummary } from './UserProfileSummary';

import { getUserTypeFromUser } from '../../utils/UserType';
import { useUser } from '../../redux/user/useUser';
import { UserProfileFragment } from '../../gen/graphql';

import DefaultImage from '../../static/image/icon_default.svg';

interface Props {
  user: UserProfileFragment;
}

export const UserProfileCard: React.FC<Props> = ({ user }) => {
  const {
    user: { lmsUser },
  } = useUser();
  const navigate = useNavigate();

  const userType = getUserTypeFromUser(user);
  const isEmptyUserTags = isEmpty(user.userTags ?? []);
  const isEmptyTags = isEmpty(user.tags ?? []);

  return (
    <Container>
      <ProfileArea>
        <Profile>
          <CircularIcon
            src={user.image ?? DefaultImage}
            size={96}
            mediumSize={48}
            userType={userType}
          />
          <div>
            <NickName>{user.nickName}</NickName>
          </div>
        </Profile>
        {lmsUser?.id === user.id && (
          <EditButtonWrapper>
            <Button onClick={() => navigate('/account/profile')} border>
              アカウントを編集
            </Button>
          </EditButtonWrapper>
        )}
      </ProfileArea>
      {!user.isInstructor && (
        <>
          <StyledUserProfileSummary
            studyReportCount={user.studySummary?.studyReportCount ?? 0}
            whileStudyingProgramCount={user.whileStudyingPrograms?.length ?? 0}
            completedProgramCount={user.completedPrograms?.length ?? 0}
            totalLessonCount={user.totalLessonCount?.length ?? 0}
            questionCount={user.questions?.length ?? 0}
          />
          <Logs>
            <Column>
              <StudySummary
                label="サマリー"
                summary={user.studySummary ?? undefined}
                type="graph"
                data={user.monthlyStudyCalendars ?? []}
              />
            </Column>
            <Column>
              <StudyTagSummary data={user.studyTagSummaries ?? []} />
            </Column>
          </Logs>
        </>
      )}
      <StyledDl>
        <StyledDt>自己紹介</StyledDt>
        <StyledDd $empty={!user.profile}>{user.profile || '未登録'}</StyledDd>
        <StyledDt>プログラミング経験</StyledDt>
        <StyledDd $empty={isEmptyUserTags}>
          {!isEmptyUserTags
            ? user.userTags
                ?.map((userTag) => `${userTag.tag?.name} (${userTag.term}年)`)
                .join(' / ')
            : '未登録'}
        </StyledDd>
        {!user.isInstructor && (
          <>
            <StyledDt>学習目標</StyledDt>
            <StyledDd $empty={!user.objective}>{user.objective || '未登録'}</StyledDd>
            <StyledDt>
              学習予定の言語 /<br />
              フレームワーク
            </StyledDt>
            <StyledDd $empty={isEmptyTags}>
              {!isEmptyTags ? (
                <Tags>
                  {user.tags?.map((tag) => <Tag key={tag.id} name={tag.name} />) ?? '未登録'}
                </Tags>
              ) : (
                '未登録'
              )}
            </StyledDd>
          </>
        )}
      </StyledDl>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  padding: 2rem 2rem 3rem;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.125rem;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    padding: 2rem 1rem 1.75rem;
  `}
`;

const ProfileArea = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: 0.3125rem;
  width: 100%;
  box-sizing: border-box;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 0;
  `}
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;

  ${media.lessThan('medium')`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: .375rem;
    padding-left: 0;
  `}
`;

const NickName = styled.p`
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1em;
  color: rgba(0, 0, 0, 0.87);

  ${media.lessThan('medium')`
    font-size: .875rem;
    line-height: 1.25rem;
    text-align: center;
  `}
`;

const EditButtonWrapper = styled.div`
  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const Logs = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  background: #fff;

  h3 {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
  }

  ${media.lessThan('medium')`
    margin-top: 1rem;
  `}
`;

const Column = styled.div`
  flex: 1;

  &:last-of-type {
    margin-left: 1.5rem;
    padding-left: 1.5rem;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }

  ${media.lessThan('medium')`
    flex: auto;
    width: 100%;
    padding-top: 1.5rem;
    border-top: 1px solid rgba(0,0,0,0.1);

    &:last-of-type {
      margin: 1.875rem 0 0;
      padding-left: 0;
      border-left: none;
    }
  `}
`;

const StyledDl = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding-top: 0.1875rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  ${media.lessThan('medium')`
    margin-top: 1.875rem;
    padding-top: 1.25rem;
  `}
`;

const StyledDt = styled.dt`
  margin-top: 1.5rem;
  width: 12rem;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);

  ${media.lessThan('medium')`
    margin-top: 0.875rem;
    width: 100%;
    font-size: 0.75rem;
  `};
`;

const StyledDd = styled.dd<{ $empty: boolean }>`
  margin-top: 1.5rem;
  width: calc(100% - 12rem);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4;
  color: ${({ $empty }) => ($empty ? 'rgba(0, 0, 0, 0.36)' : 'rgba(0, 0, 0, 0.87)')};
  white-space: pre-wrap;
  word-break: break-all;

  ${media.lessThan('medium')`
    margin-top: 0.625rem;
    width: 100%;
    font-size: 0.75rem;
  `};
`;

const Tags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem 0 0 -0.625rem;

  & > * {
    margin: 0.5rem 0 0 0.625rem;
  }
`;

const StyledUserProfileSummary = styled(UserProfileSummary)`
  margin-top: 2rem;

  ${media.lessThan('medium')`
    padding-top: 0.75rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  `}
`;
