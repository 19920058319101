import React from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { PricePermissionFragment as PricePermission } from '../../gen/graphql';
import { format, parseISO } from 'date-fns';

interface Props {
  isNextPlan: boolean;
  productName: string;
  unitAmount: number;
  numberOfTickets: number;
  startDate?: string;
  endDate?: string;
  pricePermission?: PricePermission;
}

export const AccountSubscriptionUpdateCard: React.FC<Props> = ({
  isNextPlan,
  productName,
  unitAmount,
  numberOfTickets,
  startDate,
  endDate,
  pricePermission,
}) => {
  const formatDate = (date?: string) => {
    if (!date) return '';
    return format(parseISO(date), 'yyyy/MM/dd HH:mm:ss');
  };

  return (
    <React.Fragment>
      <Content>
        <Header>{isNextPlan ? '変更後のプラン' : '現在のプラン'} </Header>
        <Card red={isNextPlan}>
          <Title>{productName}</Title>
          <PriceField>
            <Price red={isNextPlan}>{unitAmount.toLocaleString()}</Price>
            <PriceInfo>円/月</PriceInfo>
            <PriceInfoSmall>税込</PriceInfoSmall>
          </PriceField>
          <Title>契約期間</Title>
          <ContractPeriod notice={isNextPlan}>
            {formatDate(startDate)} ~ {formatDate(endDate)}
          </ContractPeriod>
          <Service>ご利用可能なサービス</Service>
          <ServiceDetail>
            <dt>教材利用</dt>
            <dd>
              {
                // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                // eslint-disable-next-line eqeqeq
                pricePermission?.program == true ? '無制限' : '一部可能'
              }
            </dd>
            <dt>毎月レッスン</dt>
            <dd>{numberOfTickets}回※</dd>
            <dt>質問掲示板</dt>
            <dd>
              {
                // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                // eslint-disable-next-line eqeqeq
                pricePermission?.question == true ? '無制限' : '一部可能'
              }
            </dd>
            <dt>課題機能</dt>
            <dd>
              {
                // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                // eslint-disable-next-line eqeqeq
                pricePermission?.practice == true ? '無制限' : '一部可能'
              }
            </dd>
            <dt>課題提出機能</dt>
            <dd>
              {
                // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                // eslint-disable-next-line eqeqeq
                pricePermission?.submitPractice == true ? '無制限' : '一部可能'
              }
            </dd>
            <dt>学習ログ</dt>
            <dd>
              {
                // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
                // eslint-disable-next-line eqeqeq
                pricePermission?.studyLog == true ? '無制限' : '一部可能'
              }
            </dd>
          </ServiceDetail>
        </Card>
      </Content>
    </React.Fragment>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem;
`;

const Header = styled.p`
  margin: 0.75rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: bold;

  ${media.lessThan('medium')`
    font-size: 1rem;
    line-height: 1.8;
  `}
`;

const Card = styled.div<{ red?: boolean }>`
  background: ${(props) => (props.red ? '#FFF9F9;' : '#e5e5e5;')}
  padding: 1.5rem;
  text-align: center;
  ${(props) =>
    props.red &&
    `
    border: 1px solid #eb0000;
  `}
  border-radius: 5px;
`;

const Title = styled.div`
  background: #ffffff;
  padding: 0.75rem;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 3px;
`;

const PriceField = styled.div`
  margin-bottom: 1rem;
`;

const Price = styled.span<{ red?: boolean }>`
  font-size: 2.5rem;
  line-height: 1;
  font-weight: bold;
  color: ${(props) => (props.red ? '#EB0000;' : '#333333;')};
`;

const PriceInfo = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
`;

const PriceInfoSmall = styled.span`
  font-size: 0.75rem;
  line-height: 1;
`;

const Service = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ContractPeriod = styled.div<{ notice?: boolean }>`
  margin-bottom: 1rem;
  font-weight: bold;
  color: ${(props) => (props.notice ? '#EB0000;' : '#333333;')};
`;

const ServiceDetail = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  dt {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: calc(35% - 1rem);

    ${media.lessThan('medium')`
    width: 100%;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.875rem;
    line-height: 1.125;
    
    &:after {
      display: none;
    }
  `}
  }

  dd {
    margin-bottom: 0.5rem;
    width: calc(65%);

    &:before {
      content: ':';
      padding-right: 1rem;
    }

    ${media.lessThan('medium')`
    margin-top: 0.25rem;
    width: 100%;
  `}
  }
`;
