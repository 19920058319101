import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';
import {
  FormControl,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useUser } from '../../redux/user/useUser';
import { Step2InputSata } from './SpotLessonReserveStep2TextBox';
import { addHours, format } from 'date-fns';
import { fromNullable } from 'fp-ts/Option';
import { isNonEmpty } from 'fp-ts/Array';
import { useToastsContext } from '../../context/ToastsProvider';
import { useNavigate } from 'react-router-dom';
import { DefaultPaymentAddModal } from './DefaultPaymentAddModal';
import { isEmpty } from 'fp-ts/es6/Array';
import { isNone } from 'fp-ts/es6/Option';
import TagManager from 'react-gtm-module';
import { Button } from '../atoms/Button';
import { LinkifyText } from '../atoms/LinkifyText';
import {
  SPOT_LESSON_LOCALED_TAX,
  SPOT_LESSON_TAX_EXCLUDED_LOCALED_PRICE,
  SPOT_LESSON_TAX_EXCLUDED_PRICE,
  SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE,
} from '../../const';
import { calcTicketReturnDeadline } from '../../utils/spotLesson';
import { jstToUtc } from '../../utils/DateFnsSupport';
import {
  StripePaymentMethodFragment,
  CategoryFragment as Category,
  TagFragment as Tag,
  useReserveSpotLessonMutation,
  useGetTicketsQuery,
  useGetPaymentMethodsQuery,
} from '../../gen/graphql';
import { useSafeAsyncCallback } from '../../common/customHooks/SafeAsyncCallback';
import { Payment } from '../../const/Payment';
import { FunctionType, PermissionType } from '../../const/UserPermission';
import { getApiErrorMessage } from '../../utils/graphqlError';

export interface Step3Props {
  selectedTags: Tag[];
  selectedCategories: Category[];
  inputText: Step2InputSata | null;
  targetDate: Date | null;
  setLoading: (isLoading: boolean) => void;
}

export const SpotLessonReserveStep3ConfirmGrid: React.FC<Step3Props> = (props) => {
  const UseTicketType = {
    UseTicket: 'Yes',
    NoUseTicket: 'No',
  } as const;

  type UseTicketType = (typeof UseTicketType)[keyof typeof UseTicketType];

  const { user, permissionCheck } = useUser();
  const { data: getPaymentMethodsData, refetch } = useGetPaymentMethodsQuery({
    variables: {
      limit: Payment.MAX_ITEM_NUMBER,
    },
    onError: () => {
      showToast(1, '支払い情報の読み込みに失敗しました。');
    },
    notifyOnNetworkStatusChange: true,
  });
  const paymentMethods = useMemo(
    () => getPaymentMethodsData?.paymentMethods.items ?? [],
    [getPaymentMethodsData],
  );
  const defaultPayment = useMemo((): StripePaymentMethodFragment | null => {
    if (paymentMethods.length <= 0) return null;
    return paymentMethods.find((paymentMethod) => paymentMethod.isDefault) ?? null;
  }, [paymentMethods]);

  // 支払い方法取得
  const fetchPaymentMethods = useCallback(async (): Promise<void> => {
    refetch();
  }, [refetch]);

  const permissionSpotLessonBuyTicket = permissionCheck(
    FunctionType.SpotLessonBuyTicket,
    PermissionType.Create,
  );

  const [reserveSpotLesson] = useReserveSpotLessonMutation();

  const navigate = useNavigate();
  const [useTicket, setUseTicket] = React.useState<UseTicketType>(UseTicketType.UseTicket);
  const [stepButtonClicked, setStepButtonClicked] = React.useState(false);
  const { showToast } = useToastsContext();
  const [addIsOpen, setAddIsOpen] = useState(false);

  const { data: ticketData } = useGetTicketsQuery({
    onCompleted: (data) => {
      setUseTicket(isNonEmpty(data.tickets) ? UseTicketType.UseTicket : UseTicketType.NoUseTicket);
    },
  });
  const tickets = ticketData?.tickets ?? [];

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setUseTicket(event.target.value as UseTicketType);
  };

  const CreateStep3Confirm = (columnTitle: string, value: string, linkify = false) => {
    const [showFull, setShowFull] = useState(false);

    return (
      <>
        <dt>{columnTitle}</dt>
        <dd>
          <TextWithWrap showFull={showFull} onClick={() => setShowFull(true)}>
            {linkify ? <LinkifyText options={{ target: '_blank' }}>{value}</LinkifyText> : value}
          </TextWithWrap>
        </dd>
      </>
    );
  };

  const submit = useSafeAsyncCallback(async () => {
    const troubleContent = fromNullable(props.inputText?.troubleContent);
    const researchContent = fromNullable(props.inputText?.researchContent);

    if (props.targetDate === null) {
      showToast(1, '予約日時を選択してください');
      return;
    }
    if (useTicket !== UseTicketType.UseTicket && defaultPayment === null) {
      showToast(1, '支払い方法を指定してください');
      return;
    }
    if (isNone(troubleContent)) {
      showToast(1, '困ったことは必須項目です');
      return;
    }
    if (isNone(researchContent)) {
      showToast(1, '調べたことは必須項目です');
      return;
    }

    setStepButtonClicked(true);
    props.setLoading(true);

    try {
      try {
        await reserveSpotLesson({
          variables: {
            input: {
              startAt: jstToUtc(props.targetDate).toISOString(),
              endAt: jstToUtc(addHours(props.targetDate, 1)).toISOString(),
              troubleContent: troubleContent.value,
              researchContent: researchContent.value,
              tags: props.selectedTags.map((e) => e.id),
              categories: props.selectedCategories.map((e) => e.id),
              useTicket: useTicket === UseTicketType.UseTicket ? true : false,
            },
          },
        });
      } catch (e) {
        const errorMessage = getApiErrorMessage(e, undefined, {
          InvalidTag: '予約に失敗しました。画面を更新して再度お試しください',
        });
        showToast(1, errorMessage);
        return;
      }
      // 成功時のコールバック(refetch)
      TagManager.dataLayer({
        dataLayer: {
          userId: user.lmsUser?.id,
          user: user.lmsUser,
          event: 'gtm-spot_lesson-step_complete',
          eventData: {
            price: SPOT_LESSON_TAX_EXCLUDED_PRICE,
          },
        },
        dataLayerName: 'LMSDataLayer',
      });

      showToast(0, '処理が進行中です。3秒後に遷移します。');
      const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));
      (async () => {
        await sleep(3000);
        setStepButtonClicked(false);
        showToast(0, 'レッスンの予約が完了しました');
        navigate('/spot_lessons');
      })();
    } finally {
      props.setLoading(false);
    }
  });

  // チケットを持っているか、チケット購入Roleでチケット利用しない場合は予約可能
  const isReservable =
    !isEmpty(tickets) || (permissionSpotLessonBuyTicket && useTicket === UseTicketType.NoUseTicket);

  const disabledSubmit = !isReservable || stepButtonClicked;

  return (
    <React.Fragment>
      <DefaultPaymentAddModal
        isOpen={addIsOpen}
        toggle={setAddIsOpen}
        fetchPayments={fetchPaymentMethods}
        currentDefaultPaymentId={defaultPayment?.id ?? ''}
        cards={paymentMethods}
        setLoading={(loading) => props.setLoading(loading)}
      />
      {/* ここまできている時点でtargetDateがないことはありえないが、型的にnullableなのでチェック */}
      {props.targetDate && (
        <CautionArea>
          <CancellationCaution>
            <p className="title">ご注意ください！</p>
            <p className="description">
              <span className="deadline">
                {format(calcTicketReturnDeadline(props.targetDate), 'yyyy年MM月dd日 HH:mm')}
              </span>
              以降のキャンセルは<span className="caution">チケットが消化</span>されます。
              <br />
              予約希望日時を再度ご確認の上、ご予約ください。
            </p>
          </CancellationCaution>
        </CautionArea>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          <Step3Title>レッスン予約情報</Step3Title>
          <StyledDefinitionList>
            {CreateStep3Confirm(
              '言語・技術',
              props.selectedTags.map((t) => (t.name ? t.name : '')).join(', '),
            )}
            {CreateStep3Confirm(
              'カテゴリー',
              props.selectedCategories.map((t) => t.name).join(', '),
            )}
            {CreateStep3Confirm(
              '予約希望日時',
              // eqeqeqの導入時点で存在していたコードなので、そのままにしておく。
              // eslint-disable-next-line eqeqeq
              props.targetDate != null
                ? format(props.targetDate, 'yyyy/MM/dd HH:mm') +
                    '~' +
                    format(addHours(props.targetDate, 1), 'HH:mm') +
                    '（日本標準時）'
                : '',
            )}
            {CreateStep3Confirm(
              '困っていること',
              props.inputText?.troubleContent ? props.inputText?.troubleContent : '',
              true,
            )}
            {CreateStep3Confirm(
              '調べたこと',
              props.inputText?.researchContent ? props.inputText?.researchContent : '',
              true,
            )}
          </StyledDefinitionList>
          {permissionSpotLessonBuyTicket && (
            <div style={useTicket === UseTicketType.NoUseTicket ? {} : { display: 'none' }}>
              <Step3Title>ご請求先</Step3Title>
              <ListItem>
                <Grid container item spacing={2} xs={12}>
                  <Grid container item xs={12} lg={4} alignItems="center">
                    クレジットカード決済
                  </Grid>
                  <Grid container item xs={12} lg={8} alignItems="center">
                    <Grid container item xs={9} justify={'flex-start'} alignItems="center">
                      {defaultPayment?.cardBrand} 下4桁 {defaultPayment?.cardNumber} 一括払い
                    </Grid>
                    <Grid container item xs={3} justify={'flex-end'} alignItems="center">
                      <CreditEdit
                        border
                        onClick={() => {
                          setAddIsOpen(true);
                        }}
                      >
                        変更
                      </CreditEdit>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={5}>
          {permissionSpotLessonBuyTicket ? (
            <>
              <Step3Title>ご請求金額</Step3Title>
              <List>
                <ListItem>
                  <Grid container item xs={6} justify={'flex-start'}>
                    レッスン料:
                  </Grid>
                  <Grid container item xs={6} justify={'flex-end'}>
                    ￥{SPOT_LESSON_TAX_EXCLUDED_LOCALED_PRICE}
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container item xs={6} justify={'flex-start'}>
                    消費税:
                  </Grid>
                  <Grid container item xs={6} justify={'flex-end'}>
                    ￥{SPOT_LESSON_LOCALED_TAX}
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container item xs={6} justify={'flex-start'}>
                    小計:
                  </Grid>
                  <Grid container item xs={6} justify={'flex-end'}>
                    ￥{SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE}
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid
                    container
                    item
                    xs={12}
                    style={{ backgroundColor: 'rgba(255,247,228)', padding: '1rem' }}
                  >
                    <Grid container item xs={12} spacing={2}>
                      <Grid container item xs={12} justify={'flex-start'}>
                        レッスンチケットを利用する(残り{tickets.length}枚)
                      </Grid>
                      <Grid container item xs={12} justify={'flex-start'}>
                        <FormControl component="fieldset">
                          <RadioGroup
                            style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'row' }}
                            value={useTicket}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              disabled={isEmpty(tickets)}
                              value={UseTicketType.UseTicket}
                              control={<Radio />}
                              label="はい"
                            />
                            <FormControlLabel
                              value={UseTicketType.NoUseTicket}
                              control={<Radio />}
                              label="いいえ"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container item xs={6} justify={'flex-start'}>
                    チケット利用: 1枚
                  </Grid>
                  <Grid container item xs={6} justify={'flex-end'}>
                    -￥
                    {useTicket === UseTicketType.UseTicket
                      ? SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE
                      : 0}
                  </Grid>
                </ListItem>
                <SumAmount>
                  <ListItem>
                    <Grid container item xs={6} justify={'flex-start'}>
                      合計金額:
                    </Grid>
                    <Grid container item xs={6} justify={'flex-end'}>
                      <SumAmountColor>
                        ￥
                        {useTicket === UseTicketType.UseTicket
                          ? 0
                          : SPOT_LESSON_TAX_INCLUDED_LOCALED_PRICE}
                      </SumAmountColor>
                    </Grid>
                  </ListItem>
                </SumAmount>
              </List>
            </>
          ) : (
            <RemainingTicketsInfo>
              レッスンチケットを利用する(残り{tickets.length}枚)
            </RemainingTicketsInfo>
          )}
        </Grid>
      </Grid>
      <ButtonWrapper>
        <StepButton onClick={submit} disabled={disabledSubmit}>
          レッスンを予約する
        </StepButton>
        {permissionSpotLessonBuyTicket && (
          <Notion>
            ※「特定商取引法に基づく表記」は
            <a href="https://www.sejuku.net/corp/legal" target="_blank">
              こちら
            </a>
            をご覧ください。
          </Notion>
        )}
      </ButtonWrapper>
    </React.Fragment>
  );
};

const Step3Title = styled.div`
  font-weight: 700;
  padding: 1rem;
  background-color: rgba(245, 245, 245, 1);
`;

const SumAmount = styled.p`
  font-weight: bold;
  font-size: 16pt;
`;

const SumAmountColor = styled.p`
  color: #e2001b;
  font-size: 16pt;
`;

const TextWithWrap = styled.p<{ showFull: boolean }>`
  ${(props) =>
    !props.showFull
      ? `
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    cursor: pointer;
  `
      : ''}
  white-space: pre-line;
  word-break: break-all;
`;

const StyledDefinitionList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  padding-left: 1rem;

  dt {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    width: calc(35% - 1.5rem);

    &:after {
      content: ':';
      padding-right: 1.5rem;
    }

    ${media.lessThan('medium')`
      width: 100%;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.875rem;
      line-height: 1.125;
      
      &:after {
        display: none;
      }
    `}
  }

  dd {
    margin-top: 1rem;
    width: calc(65%);

    ${media.lessThan('medium')`
      margin-top: 0.25rem;
      width: 100%;
    `}
  }
`;

const RemainingTicketsInfo = styled.p`
  padding: 2.5rem 1.875rem;
  background-color: rgba(243, 191, 56, 0.12);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5;

  ${media.lessThan('medium')`
    padding: 1.1rem 1rem;
  `}
`;

const CreditEdit = styled(Button)`
  padding-left: 1rem;
  padding-right: 1rem;
`;

const CautionArea = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;

  ${media.lessThan('medium')`
    margin-bottom: 2rem;
  `}
`;
const CancellationCaution = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.75rem 1rem 1rem;
  background: #fef1ba;
  border: 3px solid #9d7b24;
  box-sizing: border-box;

  .title {
    font-size: 1.125rem;
    line-height: 1.4;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 0.5rem;
  }

  .description {
    font-size: 1rem;
    line-height: 1.5;
    text-align: center;

    .deadline {
      font-weight: 700;
    }

    .caution {
      font-weight: 700;
      color: rgb(226, 0, 27);
    }
  }

  ${media.lessThan('medium')`
    width: 100%;
    
    .description {
      br.large {
        display: none;
      }
    }
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding: 1rem 1rem 0;

  ${media.lessThan('medium')`
    flex-direction: column;
  `}
`;

const StepButton = styled(Button)`
  display: block;
  width: 17.5rem;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 360px;
    margin: 2rem auto 0;
  `}
`;

const Notion = styled.p`
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 1rem 0 0;

  ${media.lessThan('medium')`
    font-size: 0.625rem;
    margin: 1rem 0 0;
  `}

  a {
    font-size: 0.8rem;
    color: #e2001b;
  }
`;
