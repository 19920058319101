import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import media from 'styled-media-query';

import { isEmpty } from 'fp-ts/Array';
import { CategoryFragment as Category } from '../../gen/graphql';

import { Button } from '../atoms/Button';
import { SpotLessonReserveStep2CheckBox } from './SpotLessonReserveStep2CheckBox';
import { SpotLessonReserveStep2TextBox, Step2InputSata } from './SpotLessonReserveStep2TextBox';

interface Props {
  setLoading: (isLoading: boolean) => void;
  onNext: (categories: Category[], inputSata: Step2InputSata) => void;
}

export const SpotLessonReserveStep2Group: React.FC<Props> = ({
  setLoading,
  onNext,
}): JSX.Element => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [troubleContent, setTroubleContent] = useState('');
  const [researchContent, setResearchContent] = useState('');

  const disabledCondition = useMemo(
    (): boolean =>
      isEmpty(categories) ||
      troubleContent.trim().length === 0 ||
      researchContent.trim().length === 0,
    [categories, troubleContent, researchContent],
  );

  return (
    <>
      <StepSubTitle>
        相談内容のカテゴリー<RequiredLabel>必須 ※ 上限2つまで</RequiredLabel>
      </StepSubTitle>
      <SpotLessonReserveStep2CheckBox onChange={setCategories} setLoading={setLoading} />
      <SpotLessonReserveStep2TextBox
        onChangeTroubleContent={setTroubleContent}
        onChangeResearchContent={setResearchContent}
      />
      <StepButton
        onClick={() => onNext(categories, { troubleContent, researchContent })}
        disabled={disabledCondition}
      >
        次の入力へ進む
      </StepButton>
    </>
  );
};

const StepSubTitle = styled.h4`
  font-weight: 700;
  font-size: 1.125rem;
`;

const StepButton = styled(Button)`
  display: block;
  width: 17.5rem;
  margin-top: 1rem;

  ${media.lessThan('medium')`
    width: 100%;
    max-width: 360px;
    margin: 2rem auto 0;
  `}
`;

const RequiredLabel = styled.span`
  margin-left: 0.5rem;
  padding: 0.0625rem 0.5rem;
  vertical-align: top;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.5;
  background: #fd6258;
  color: #ffffff;
`;
